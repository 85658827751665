var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row tablet-settings-container" }, [
    _c("div", { staticClass: "col-xs-10 tablet-settings" }, [
      _c("div", { staticClass: "tablet-settings-head" }, [
        _c("div", { staticClass: "tablet-advanced-filters" }, [
          _c("img", {
            attrs: { src: _vm.icons.filter, alt: "Advanced Filters Icons" },
          }),
          _vm._v(" "),
          _c("a", { attrs: { href: "#" } }, [_vm._v("Advanced Filters")]),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "curve-btn blue",
            attrs: { href: "#", id: "advanced-submit-btn" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.search.apply(null, arguments)
              },
            },
          },
          [_c("span", [_vm._v("Done")])]
        ),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "row advanced-filters" }, [
        _c("form", { attrs: { id: "advanced-filter-form", action: "" } }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 advanced-filter dropdown",
              attrs: { "aria-expanded": "false" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "advanced-filter-title",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleDropdown.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v("Category\n\t\t\t\t\t\t"),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-filter-toggle",
                      attrs: { href: "#" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.filterPlus,
                          alt: "Expand Categories",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "advanced-filter-dropdown-options" }, [
                _c(
                  "div",
                  { staticClass: "control-group category" },
                  [
                    _c(
                      "fieldset",
                      {
                        staticClass: "checkbox-field category-everything",
                        attrs: {
                          "data-cat": "0",
                          "data-name": "all-tablet-events",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.buildTypes($event)
                          },
                        },
                      },
                      [
                        _c("input", {
                          attrs: {
                            type: "checkbox",
                            id: "all-tablet-cats",
                            "aria-labelledby": "All Categories",
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "all-tablet-cats" } }, [
                          _vm._v("All Categories"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.types, function ({ term_id, name, slug }) {
                      return _c(
                        "fieldset",
                        {
                          key: term_id,
                          class: `checkbox-field category-${slug}`,
                          attrs: { "data-cat": term_id, "data-name": name },
                          on: {
                            click: function ($event) {
                              return _vm.buildTypes($event)
                            },
                          },
                        },
                        [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              id: `category-${slug}`,
                              "aria-labelledby": slug,
                            },
                          }),
                          _vm._v(" "),
                          _c("label", {
                            attrs: { for: slug },
                            domProps: { innerHTML: _vm._s(name) },
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "calendar-filter-buttons" }, [
          _c(
            "a",
            {
              staticClass: "curve-btn white",
              attrs: { id: "advanced-reset-btn", role: "button", href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.reset.apply(null, arguments)
                },
              },
            },
            [_c("span", [_vm._v("Reset all filters")])]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-xs-2 tablet-settings-overlay" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }