<template>
	<div id="tablet-user-information-card">
		<div class="row">
			<div class="col-xs-12">
				<div class="user-information-card">
					<div class="user-information-card-content">
						<div class="loading member-card-loading"></div>
						<div v-if="data !== null && !loading"
						     class="row">
							<div class="col-xs-3">
								<div v-if="data.profileImage"
								     class="user-information-card-avatar"
								     v-html="data.profileImage">
								</div>
							</div>
							<div class="col-xs-9">
								<div class="row">
									<div class="col-xs-6">
										<div class="user-card-information-name-container">
											<p class="label">Name</p>
											<h3 v-text="data.name"></h3>
										</div>
									</div>
									<div class="col-xs-6">
										<div class="user-information-card-edit">
											<ul>
												<li v-if="icons.editPhoto && editLinks.editPhoto">
													<a :href="editLinks.editPhoto"><img :src="icons.editPhoto"
													     alt="Edit Photo" />Edit profile photo</a>
												</li>
												<li v-if="icons.editInfo && editLinks.editInfo">
													<a :href="editLinks.editInfo"><img :src="icons.editInfo"
													     alt="Edit Information" />Edit information</a>
												</li>
												<li>
													<button @click.prevent="$emit('refreshMemberData')"
													        :disabled="!allowUpdate"
													        class="refresh-button"><span class="icon"><img :src="icons.refreshData"
													                                                       alt="Refresh Data" /></span><span class="text">Refresh Data</span></button>
													<p v-if="timeUpdate"
													   class="description">Next time you can update: <span v-text="timeUpdate"></span></p>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="data !== null && !loading"
						     class="user-information-details">
							<div class="row">
								<div v-if="data.nysbaID"
								     class="col-xs-6">
									<p class="label">Member ID</p>
									<p v-text="data.nysbaID"></p>
								</div>
								<div v-if="data.memberType"
								     class="col-xs-6">
									<p class="label">Member Type</p>
									<p v-text="data.memberType"></p>
								</div>
								<div v-if="data.memberStatus"
								     class="col-xs-6">
									<p class="label">Member Status</p>
									<p v-text="data.memberStatus"></p>
								</div>
								<div v-if="data.memberSince"
								     class="col-xs-6">
									<p class="label">Member Since</p>
									<p v-text="data.memberSince"></p>
								</div>
								<div v-if="data.renewalDate"
								     class="col-xs-6">
									<p class="label">Renewal Date</p>
									<p v-text="data.renewalDate"></p>
									<a v-if="data.renewMembership"
									   :href="data.renewalLink"
									   class="line-btn blue"><span>Renew membership</span></a>
								</div>
								<div v-if="data.email"
								     class="col-xs-6">
									<p class="label">Email</p>
									<p v-text="data.email"></p>
								</div>
								<div v-if="data.company"
								     class="col-xs-6">
									<p class="label">Organization</p>
									<p v-text="data.company"></p>
								</div>
								<div v-if="data.title"
								     class="col-xs-6">
									<p class="label">Title</p>
									<p v-text="data.title"></p>
								</div>
								<div v-if="data.location"
								     class="col-xs-6">
									<p class="label">Location</p>
									<p v-text="data.location"></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        props: {
            data: [Array, Object],
            icons: [Array, Object],
            editLinks: [Array, Object],
            loading: Boolean,
            allowUpdate: Boolean,
            timeUpdate: [String]
        },
        mounted() {
            const loading = $(".member-card-loading");
            this.loading ? loading.show() : loading.hide();
        },
        watch: {
            loading(loadingChange) {
                const loading = $(".member-card-loading");
                if (loadingChange) loading.show();
                else loading.hide();
            }
        },
        name: "MemberCardTablet"
    };

</script>
