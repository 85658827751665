<template>
	<p>
		<a v-if="fastcaseUrl"
		   :href="fastcaseUrl"
		   class="curve-btn blue"
		   target="_blank">
			<span>Access Fastcase</span>
		</a>
		<span v-else
		      class="curve-btn blue disabled"
		      aria-disabled="true">
			<span>Access Fastcase</span>
		</span>
	</p>
</template>

<script type="application/javascript">
    export default {
        computed: {
            fastcaseUrl() {
                return this.$store.state.fastcaseUrl;
            }
        },
        name: "FastCaseButton"
    };
</script>
