var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.countdownDate
    ? _c(
        "div",
        {
          ref: "countDownBanner",
          staticClass: "top-alert-banner",
          attrs: { id: "top-alert-banner" },
        },
        [
          _c(
            "div",
            { staticClass: "top-alert-banner__content" },
            [
              _c("VueCountdown", {
                staticClass: "countdown",
                attrs: {
                  tag: "div",
                  time: _vm.countdownDate,
                  transform: _vm.transform,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass:
                                "countdown__inner countdown__inner--day",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "day countdown__time" },
                                [_vm._v(_vm._s(props.days))]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "countdown__label" }, [
                                _vm._v("days"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "countdown__inner countdown__inner--hrs",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "hrs countdown__time" },
                                [_vm._v(_vm._s(props.hours))]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "countdown__label" }, [
                                _vm._v("hrs"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "countdown__inner countdown__inner--min",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "min countdown__time" },
                                [_vm._v(_vm._s(props.minutes))]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "countdown__label" }, [
                                _vm._v("min"),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4051602106
                ),
              }),
              _vm._v(" "),
              _vm.countdownText
                ? _c("div", {
                    staticClass: "txt",
                    domProps: { innerHTML: _vm._s(_vm.countdownText) },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isObjEmpty(_vm.countdownCta)
                ? _c("div", { staticClass: "cta-container" }, [
                    _c(
                      "a",
                      {
                        staticClass: "curve-btn white",
                        attrs: {
                          href: _vm.countdownCta.url,
                          target: _vm.countdownCta.target || "",
                          rel: _vm.countdownCta.target ? "noopener" : "",
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.countdownCta.title),
                          },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.bannerDismissed.apply(null, arguments)
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                      return _vm.bannerDismissed.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "sr-only" }, [
                    _vm._v("Close alert"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }