<template>
	<div class="cart-checkout-buttons">
		<a v-if="$root.loginUrl"
		   :href="$root.loginUrl"
		   class="curve-btn blue"><span>{{text}}</span></a>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            text: String
        },
        name: "LoginBtn"
    };
</script>

<style scoped>

</style>
