var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$root.formats.length > 0
    ? _c("div", [
        _c("p", { staticClass: "label" }, [_vm._v(_vm._s(_vm.$root.title))]),
        _vm._v(" "),
        _vm.$root.formats
          ? _c(
              "ul",
              { staticClass: "format" },
              _vm._l(_vm.$root.formats, function (format) {
                return _c("ProductFormat", {
                  key: format.id,
                  attrs: { format: format },
                })
              }),
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }