import { render, staticRenderFns } from "./SingleEvent.vue?vue&type=template&id=03325960&scoped=true&"
import script from "./SingleEvent.vue?vue&type=script&lang=js&"
export * from "./SingleEvent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03325960",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.nysba.cliquedomains.com/web/app/themes/nysba/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03325960')) {
      api.createRecord('03325960', component.options)
    } else {
      api.reload('03325960', component.options)
    }
    module.hot.accept("./SingleEvent.vue?vue&type=template&id=03325960&scoped=true&", function () {
      api.rerender('03325960', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/scripts/Vue/Components/Calendar/Weekly/SingleEvent.vue"
export default component.exports