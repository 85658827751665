var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "favorite-filter-results" },
    [
      _c("Results", { attrs: { total: _vm.totalPosts } }),
      _vm._v(" "),
      _c("PostsFilter", { attrs: { cats: _vm.cats } }),
      _vm._v(" "),
      _c("PostsContainer", {
        attrs: {
          posts: _vm.posts,
          "max-pages": _vm.maxPages,
          "current-page": _vm.currentPage,
          loading: _vm.loading,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }