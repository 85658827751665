<template>
	<div class="modal-content">
		<div class="modal-close">
			<a @click.prevent="closeModal"
			   href="#" class="close">
				<span class="sr-only">Close event modal window</span>
			</a>
		</div>
		<div class="modal-event-date">{{eventDate}}</div>
		<div v-if="events.length"
		     class="row">
			<div v-for="(event, i) in events"
			     :key="i"
			     class="col-xs-6">
				<div :class="`modal-event modal-event-${event.eventRange.def.extendedProps.slug}`">
					<div v-if="event.eventRange.def.extendedProps.hasOwnProperty('categoryName')"
					     class="modal-event-category-container">
						<div v-for="(event) in event.eventRange.def.extendedProps.categoryName"
						     :key="event.id"
						     :class="`modal-event-category ${event.slug}`">
							<img v-if="event.img !== undefined"
							     :src="event.img"
							     alt="Registered Event" />
							<p :class="event.slug === 'registered' ? 'category-name sr-only' : 'category-name'"
							   v-text="event.name"></p>
						</div>
					</div>
					<div class="modal-event-title">
						<a :href="event.eventRange.def.url">{{event.eventRange.def.title}}</a>
					</div>
					<div class="region-title" v-if="event.eventRange.def.extendedProps.hasOwnProperty('region')">
						<p class="small-text">{{event.eventRange.def.extendedProps.region}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            eventDate: [String],
            events: [Object, Array],
            eventsRegistered: [Array, Object],
            closeIcon: [String],
        },
        methods: {
            closeModal() {
                // Need to remove the registered array item we pushed in out, out
                [...this.events].map(event => {
                    const eventsRegistered = this.eventsRegistered;
                    if (event.eventRange.def.extendedProps.hasOwnProperty("categoryName") && eventsRegistered) {
                        const categoryArray = event.eventRange.def.extendedProps.categoryName;
                        for (let i = 0; i < categoryArray.length; i++) {
                            for (let j = 0; j < eventsRegistered.length; j++) {
                                if (categoryArray[i].slug === "registered") categoryArray.splice(i, 1);
                            }
                        }
                    }
                });

                $("#modal").hide();
                $("#modal .modal-events").empty();
            }
        },
        name: "EventModal"
    };
</script>

<style lang="scss" scoped>
	.modal-event-category {
		display: block !important;
	}
</style>
