<template>
	<div class="checkered-section">
		<Section :sections="sections"
		         :loading="loading" />
		<Committees :committees="committees"
		            :loading="loading" />
	</div>
</template>

<script type="application/javascript">
	import Section from "./Section.vue";
	import Committees from "./Committees.vue";

    export default {
        props: {
            posts: [Object, Array],
	        loading: Boolean,
        },
	    data() {
            return {
                sections: [],
	            committees: [],
            }
	    },
	    watch: {
            posts(newData) {
                if (newData.section.length) this.sections = newData.section;
                if (newData.committee.length) this.committees = newData.committee;
            }
	    },
        components: {Section, Committees},
        name: "CommitteeSection"
    };
</script>

<style scoped>

</style>
