var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("sidebar", {
        attrs: {
          format: _vm.formatTerms,
          pa: _vm.paTerms,
          region: _vm.regionTerms,
          eventType: _vm.eventType,
          creditType: _vm.creditTerms,
          filters: _vm.filterItems,
          filterDate: _vm.filterDate,
          filterCreditRange: _vm.filterCreditRange,
          search: _vm.search,
        },
        on: {
          selectFormat: _vm.buildFormat,
          selectRegion: _vm.buildRegion,
          selectType: _vm.buildType,
          selectPA: _vm.buildPracticeArea,
          selectCreditType: _vm.buildCreditType,
          buildSearch: _vm.buildSearch,
          buildDate: _vm.buildDate,
          buildCredits: _vm.buildCredits,
          getAllFormats: _vm.getAllFormats,
          getAllRegions: _vm.getAllRegions,
          getAllTypes: _vm.getAllTypes,
          getAllPA: _vm.getAllPA,
          getAllCreditTypes: _vm.getAllCreditTypes,
          clearSearch: _vm.clearSearchText,
          resetItem: _vm.removeItem,
          resetFilters: _vm.resetFilters,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-8" }, [
        _c("a", { staticClass: "program-open-filters", attrs: { href: "#" } }, [
          _c("img", { attrs: { src: _vm.filterIcon, alt: "Open Filters" } }),
          _vm._v(" Open Advanced Filters"),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "listing-top" } }, [
          _vm.posts
            ? _c(
                "div",
                { staticClass: "results" },
                [
                  _vm.maxPage > 1
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-xs-12" },
                          [
                            _c("Paginate", {
                              attrs: {
                                "page-count": _vm.maxPage,
                                "page-range": 3,
                                "force-page": _vm.currentPage,
                                value: _vm.currentPage,
                                "margin-pages": 1,
                                "click-handler": _vm.gotoPage,
                                "prev-text": "",
                                "next-text": "",
                                "container-class": "top-pagination pagination",
                                "page-link-class": "page-numbers",
                                "next-link-class": "next",
                                "prev-link-class": "prev",
                                "active-class": "current",
                                "break-view-link-class": "dots",
                                "no-li-surround": true,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ListContainer", {
                    attrs: { posts: _vm.posts, loading: _vm.isLoading },
                  }),
                  _vm._v(" "),
                  _vm.maxPage > 1
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-xs-12" },
                          [
                            _c("Paginate", {
                              attrs: {
                                "page-count": _vm.maxPage,
                                "page-range": 3,
                                "force-page": _vm.currentPage,
                                value: _vm.currentPage,
                                "margin-pages": 1,
                                "click-handler": _vm.gotoPage,
                                "prev-text": "",
                                "next-text": "",
                                "container-class":
                                  "bottom-pagination pagination",
                                "page-link-class": "page-numbers",
                                "next-link-class": "next",
                                "prev-link-class": "prev",
                                "active-class": "current",
                                "break-view-link-class": "dots",
                                "no-li-surround": true,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _c("div", { staticClass: "no-results" }, [
                _c("p", [_vm._v("Sorry, no results found.")]),
              ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }