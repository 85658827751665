var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "the-leaders wrapper", attrs: { id: "departments" } },
    [
      _c("h2", [_vm._v("Leadership")]),
      _vm._v(" "),
      _c("a", { staticClass: "tablet-open-filters", attrs: { href: "#" } }, [
        _c("img", {
          attrs: { src: _vm.icons.filterIcon, alt: "Open Filters" },
        }),
        _vm._v(" Open Advanced Filters"),
      ]),
      _vm._v(" "),
      _c("LeadershipFilter", {
        attrs: {
          leaderTypes: _vm.leaderTypes,
          icons: _vm.icons,
          search: _vm.filters.search,
          loading: _vm.loading,
        },
        on: {
          buildTypes: _vm.buildTypes,
          buildAllTypes: _vm.buildAllTypes,
          buildSearch: _vm.buildSearch,
          resetSearch: _vm.resetAll,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "line-header-container" }, [
        _c("div", { staticClass: "line-header" }, [
          _c("h2", [
            _vm._v("Results ( Total " + _vm._s(_vm.results) + " Items )"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("LeaderPostContainer", {
        attrs: { icons: _vm.icons, leaders: _vm.leaders, loading: _vm.loading },
      }),
      _vm._v(" "),
      _vm.leaders.length && !_vm.loading && _vm.maxPages > 1
        ? _c("Pagination", {
            attrs: {
              "page-count": _vm.maxPages,
              "page-range": 3,
              "force-page": _vm.currentPage,
              value: _vm.currentPage,
              "margin-pages": 1,
              "click-handler": _vm.gotoPage,
              "prev-text": "",
              "next-text": "",
              "container-class": "bottom-pagination pagination",
              "page-link-class": "page-numbers",
              "next-link-class": "next",
              "prev-link-class": "prev",
              "active-class": "current",
              "break-view-link-class": "dots",
              "no-li-surround": true,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }