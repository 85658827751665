<template>
	<div id="my-sections">
		<div class="line-header-container">
			<div class="line-header">
				<h2 v-text="blockTitle"></h2>
			</div>
		</div>

		<div class="checkered-item-container">
			<div class="loading section-loading"></div>
			<div v-if="sections && !loading"
			     class="row">
				<div v-for="({title, link}) in sections"
				     class="col-xs-4">
					<div class="checkered-item">
						<a :href="link"
						   v-text="title"></a>
					</div>
				</div>
			</div>
			<NoResults v-else-if="!loading"
			            :no-results-text="notFoundText" />
		</div>
	</div>
</template>

<script type="application/javascript">
	import NoResults from "../../NoResults.vue";
    export default {
        props: {
            sections: [Array, Object],
            loading: Boolean
        },
        data() {
            return {
                blockTitle: '',
	            notFoundText: ''
            }
        },
	    created() {
		    (MYNYSBA.tabs.committeeTitles.section) ? this.blockTitle = MYNYSBA.tabs.committeeTitles.section : null;
		    (MYNYSBA.tabs.notFoundText.section) ? this.notFoundText = MYNYSBA.tabs.notFoundText.section : null;
	    },
        mounted() {
            const loading = $(".section-loading");
            this.loading ? loading.show() : loading.hide();
        },
        watch: {
            loading(loadingChange) {
                const loading = $(".section-loading");
                if (loadingChange) loading.show();
                else loading.hide();
            }
        },
	    components: {NoResults},
        name: "Section"
    };
</script>

<style lang="scss" scoped>
	.checkered-item-container { position: relative; }
</style>
