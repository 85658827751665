var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "eventsProducts",
      class: `events-list products-list${
        !_vm.posts.length && !_vm.loading ? " no-posts" : ""
      }`,
    },
    [
      _c("h2", { domProps: { innerHTML: _vm._s(_vm.title) } }),
      _vm._v(" "),
      _c("div", { class: `loading ${_vm.loading ? " is-loading" : ""}` }),
      _vm._v(" "),
      _vm._l(
        _vm.showPosts,
        function ({
          date,
          excerpt,
          id,
          image,
          region,
          registerLink,
          permalink,
          title,
          type,
        }) {
          return _vm.showPosts.length && !_vm.loading
            ? _c("Post", {
                key: id,
                attrs: {
                  date: date,
                  excerpt: excerpt,
                  image: image,
                  "link-text": _vm.getLinkText(type),
                  "register-link": registerLink,
                  region: region,
                  permalink: permalink,
                  title: title,
                },
              })
            : _vm._e()
        }
      ),
      _vm._v(" "),
      !_vm.loading && _vm.maxPages > 1
        ? _c("Paginate", {
            attrs: {
              "page-count": _vm.maxPages,
              "page-range": 3,
              "force-page": _vm.currentPage,
              value: _vm.currentPage,
              "margin-pages": 1,
              "click-handler": _vm.gotoPage,
              "prev-text": "",
              "next-text": "",
              "container-class": "bottom-pagination pagination",
              "page-link-class": "page-numbers",
              "next-link-class": "next",
              "prev-link-class": "prev",
              "active-class": "current",
              "break-view-link-class": "dots",
              "no-li-surround": true,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }