var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return typeof _vm.contents
    ? _c(
        "div",
        { staticClass: "tablet-cart-results-table" },
        _vm._l(_vm.contents, function (item, index) {
          return _c("CartItem", {
            key: index,
            attrs: { index: index, item: item, icons: _vm.icons },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }