var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-xs-4" }, [
    _c("div", { staticClass: "checkered-item" }, [
      _vm.category.length
        ? _c("p", {
            staticClass: "label",
            domProps: { textContent: _vm._s(_vm.category) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("p", [
        _c("a", {
          attrs: { href: _vm.link },
          domProps: { textContent: _vm._s(_vm.title) },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }