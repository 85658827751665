<template>
	<div class="col-xs-12">
		<cart-table v-if="$resize && $mq.above(1025)"
		            :contents="getContents"
		            :icons="icons" />
		<cart-tablet v-else
		             :contents="getContents"
		             :icons="icons" />
		<cart-totals :size="getSize"
		             :total="getTotal" />
	</div>
</template>

<script>
    import CartTable from "./Cart/CartTable.vue";
    import CartTablet from "./Cart/CartTablet.vue";
    import CartTotals from "./Cart/CartTotals.vue";

    export default {
        data() {
            return {
                icons: {},
            };
        },
        computed: {
            getContents() {
                return this.cartContents = this.$store.state.cartContents;
            },
            getSize() {
                return this.$store.state.totalSize;
            },
            getTotal() {
                return this.$store.state.total;
            }
        },
        components: {
            "cart-table": CartTable,
            "cart-tablet": CartTablet,
            "cart-totals": CartTotals,
        },
        beforeMount() {
            if (ICONS !== undefined) {
                const icons = ICONS.icons;
                (icons.cartPlus !== undefined) ? this.icons.titlePlus = icons.cartPlus : null;
                (icons.cartMinus !== undefined) ? this.icons.titleMinus = icons.cartMinus : null;
                (icons.qtyPlus !== undefined) ? this.icons.qtyPlus = icons.qtyPlus : null;
                (icons.qtyMinus !== undefined) ? this.icons.qtyMinus = icons.qtyMinus : null;
            }
        },
        name: "Cart"
    };
</script>

<style scoped>

</style>
