var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.socialMedia
    ? _c(
        "ul",
        _vm._l(_vm.socialMedia, function (sm) {
          return _c(
            "li",
            [
              sm.smType === "sm"
                ? _c("SocialMediaLinkIcon", {
                    attrs: { icon: sm.icon, link: sm.link, title: sm.title },
                  })
                : sm.smType === "print"
                ? _c("PrintLink", { attrs: { icon: sm.icon, title: sm.icon } })
                : _vm._e(),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }