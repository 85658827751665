var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: `post col-xs-12 ${_vm.postClass || ""}` }, [
    _vm.image && !_vm.isObjEmpty(_vm.image)
      ? _c(
          "div",
          { staticClass: "post-image" },
          [_c("PostImage", { attrs: { image: _vm.image } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "post-content" }, [
      _c("div", { staticClass: "post-meta" }, [
        _vm.date
          ? _c("p", {
              staticClass: "post-meta-item post-date",
              domProps: { textContent: _vm._s(_vm.date) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.date && _vm.startTime
          ? _c("p", {
              staticClass: "post-separator",
              domProps: { innerHTML: _vm._s(`&blacksquare;`) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.startTime
          ? _c("p", {
              staticClass: "post-meta-item post-start-time",
              domProps: { textContent: _vm._s(_vm.startTime) },
            })
          : _vm._e(),
        _vm._v(" "),
        (_vm.date || _vm.startTime) && _vm.format
          ? _c("p", {
              staticClass: "post-separator",
              domProps: { innerHTML: _vm._s(`&blacksquare;`) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.format
          ? _c("p", {
              class: `post-meta-item post-format`,
              domProps: { textContent: _vm._s(`${_vm.format}`) },
            })
          : _vm._e(),
        _vm._v(" "),
        (_vm.date || _vm.startTime || _vm.format) && _vm.credits
          ? _c("p", {
              class: _vm.checkIfLast(
                _vm.date,
                _vm.startTime,
                _vm.credits,
                _vm.format
              ),
              domProps: { innerHTML: _vm._s(`&blacksquare;`) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.credits
          ? _c("p", {
              class: `post-meta-item post-credits${
                _vm.date && _vm.startTime && _vm.credits ? " last-item" : ""
              }`,
              domProps: { textContent: _vm._s(_vm.credits) },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "post-title" }, [
        _c("h3", [
          _c("a", {
            staticClass: "post-link",
            attrs: { href: _vm.permalink },
            domProps: { textContent: _vm._s(_vm.title) },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }