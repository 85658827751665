var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "calendar-monthly-weekly wrapper" },
    [
      _c("Title", {
        attrs: { pageTitle: _vm.pageTitle, views: _vm.optionsViews },
        on: { dateSearch: _vm.dateSearch, calSwitch: _vm.calendarViewSwitch },
      }),
      _vm._v(" "),
      _vm.$resize && _vm.$mq.above(1024)
        ? _c(
            "div",
            {
              staticClass: "calendar-category-container",
              attrs: { id: "desktop-calendar-header" },
            },
            [
              _c("EventTypes", {
                attrs: { types: _vm.types, selectedTypes: _vm.selectedTypes },
                on: { searchFilter: _vm.searchTypes },
              }),
              _vm._v(" "),
              _c("Filters", {
                attrs: {
                  regions: _vm.regions,
                  views: _vm.optionsViews,
                  selectedRegions: _vm.selectedRegions,
                  queryDate: _vm.queriedDate,
                },
                on: {
                  dateSearch: _vm.dateSearch,
                  calSwitch: _vm.calendarViewSwitch,
                  searchRegions: _vm.searchRegions,
                  searchReset: _vm.resetAll,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "line-header-container" }, [
        _c("div", { staticClass: "line-header" }, [
          _c("h2", {
            domProps: {
              textContent: _vm._s(
                `Result (Total ${
                  _vm.numOfEvents + (_vm.view === "week" ? ` Events` : null)
                })`
              ),
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm.$resize && _vm.$mq.below(1025)
        ? _c("div", { staticClass: "tablet-advanced-settings-toggle" }, [
            _c("div", { staticClass: "tablet-advanced-filters" }, [
              _c("img", {
                attrs: { src: _vm.icons.filter, alt: "advanced filters icons" },
              }),
              _vm._v(" Open Advanced Filters\n        "),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tablet-calendar-settings-container",
          attrs: { id: "tablet-calendar-settings" },
        },
        [
          _c("TabletFilters", {
            attrs: { types: _vm.types, regions: _vm.regions, icons: _vm.icons },
            on: {
              searchTypes: _vm.searchTypes,
              dateSearch: _vm.dateSearch,
              searchRegions: _vm.searchRegions,
              searchReset: _vm.resetAll,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.isLoading ? "is-loading" : null,
          attrs: { id: "calendar" },
        },
        [
          _c("FullCalendar", {
            ref: "calendar",
            attrs: {
              header: _vm.config.header,
              plugins: _vm.config.plugins,
              views: _vm.config.views,
              "next-day-threshold": _vm.config.nextDayThreshold,
              editable: _vm.config.editable,
              "default-view": _vm.config.defaultView,
              "custom-buttons": _vm.config.customNavigationButtons,
              events: _vm.events,
              now: _vm.now,
              "event-render": _vm.eventRender,
              "event-limit-click": _vm.eventLimitCheck,
              "dates-render": _vm.handleChange,
              "nav-links": true,
              "nav-link-day-click": _vm.navWeekHeaderDayClick,
              "handle-window-resize": _vm.mediaQueryHandler,
              "unselect-auto": false,
            },
            on: {
              eventMouseEnter: _vm.monthlyMouseEnter,
              eventMouseLeave: _vm.monthlyMouseLeave,
              dateClick: _vm.tooltipHide,
              eventResizeStart: _vm.tooltipHide,
              eventDragStart: _vm.tooltipHide,
              viewDisplay: _vm.tooltipHide,
              windowResize: function ($event) {
                return _vm.mediaQueryHandler(true)
              },
            },
          }),
          _vm._v(" "),
          _vm.view === "week"
            ? _c(
                "div",
                { staticClass: "calendar-week-container" },
                [
                  _c("SingleEventWeek", {
                    attrs: {
                      activeDate: _vm.activeDate,
                      activeEvents: _vm.numOfEvents,
                      events: _vm.events,
                      isLoading: _vm.isLoading,
                      visibleChildren: _vm.visibleChildren,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "loading" }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal", attrs: { id: "modal" } }, [
      _c("div", { staticClass: "modal-events" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }