<template>
	<div class="col-xs-4 program-filters-sidebar">
		<a href="#" class="close">
			<span>Close Filters</span>
			<img
				:src="icons.close"
				alt="Close Filters">
		</a>
		<aside>
			<div v-if="filters.length || search.length || !isEmpty(filterCreditRange)"
			     class="program-filters">
				<!-- Filter Search Text -->
				<p v-if="search.length"
				   class="label">{{ filterForTitles.searchingFor }} <span class="search-text">{{search}}</span>
					<a @click="clearSearchInput(searchText, $event)"
					   class="remove-text">
						<img :src="icons.close"
						     alt="Remove Text" />
					</a>
				</p>
				<!-- Filter List -->
				<p v-if="filters.length"
				   class="label">{{filterForTitles.selectedTitle}}</p>
				<ul v-if="filters.length">
					<li v-for="(item, index) in filters"
					    :key="index">
						<a @click.prevent="$emit('resetItem', $event)"
						   href="#"
						   :data-name="item"><span v-html="item"></span></a>
					</li>
				</ul>
				<!-- Filter Credit Range -->
				<div v-if="!isEmpty(filterCreditRange) && (filterCreditRange.min !== undefined || filterCreditRange.max !== undefined)">
					<p class="label">{{ filterForTitles.searchCredits }}</p>
					<p v-if="filterCreditRange.min"
					   class="search-item search-range search-credit-range">Min {{ filterCreditRange.min}}</p>
					<p v-if="filterCreditRange.max"
					   class="search-item search-range search-credit-range">Max {{ filterCreditRange.max}}</p>
				</div>
				<!-- Reset Filters -->
				<div v-if="filters.length || !isEmpty(filterCreditRange)"
				     class="row end-xs">
					<div class="col-xs-12">
						<a @click.prevent="resetFilters"
						   href="#"
						   class="reset-filters"><span>Reset All Filters</span></a>
					</div>
				</div>
			</div>
			<ul class="sidebar-filters">
				<!-- Product Type -->
				<li id="product-format-dropdown">
					<a href="#">
						<span>{{titles.type}}</span>
						<img :src="icons.dropdown"
						     alt="Toggle Button" />
					</a>
					<ul class="sub-filters">
						<li>
							<fieldset class="checkbox-field">
								<input @click="$emit('getAllFormats', $event)"
								       type="checkbox"
								       id="all-formats"
								       data-name="All Formats"
								       aria-labelledby="All Formats"
								       name="All Formats" />
								<label for="all-formats">All Formats</label>
							</fieldset>
							<fieldset v-for="({term_id, name, slug}) in types"
							          :key="term_id"
							          class="checkbox-field">
								<input @click="$emit('buildFormats', $event)"
								       type="checkbox"
								       :id="term_id"
								       :data-name="name"
								       :data-format-slug="slug"
								       :aria-labelledby="name"
								       :name="name" />
								<label :for="term_id"
								       v-html="name"></label>
							</fieldset>
						</li>
					</ul>
				</li>
				<!-- Product Credits -->
				<li id="product-credits">
					<a href="#"><span>{{ titles.credit }}</span>
						<img :src="icons.dropdown"
						     alt="Toggle Button" />
					</a>
					<ul class="sub-filters">
						<li>
							<fieldset class="credit-range">
								<div class="row">
									<div class="col-xs-6">
										<label for="credit-min">Credit Min.</label>
										<input v-model="creditRange.min"
										       v-on:keypress.enter="$emit('buildCredits', creditRange)"
										       type="number"
										       step="0.5"
										       min="0"
										       placeholder="0"
										       id="credit-min"
										       class="credit-range-input"
										       aria-labelledby="Credit Minimum" />
									</div>
									<div class="col-xs-6">
										<label for="credit-max">Credit Max.</label>
										<input v-model="creditRange.max"
										       v-on:keypress.enter="$emit('buildCredits', creditRange)"
										       type="number"
										       step="0.5"
										       max="50"
										       min="0"
										       placeholder="50"
										       id="credit-max"
										       class="credit-range-input"
										       aria-labelledby="Credit Maximum" />
									</div>
								</div>
								<div @click="$emit('buildCredits', creditRange)"
								     class="search-btn">
									<input type="submit" value="Search Credits" />
								</div>
							</fieldset>
						</li>
					</ul>
				</li>
				<!-- Product Credit Type -->
				<li id="product-credit-types"
                    :class="isObjEmpty(practiceArea) ? 'last-item' : ''"
                >
					<a href="#"><span>{{ titles.creditType }}</span>
						<img :src="icons.dropdown"
						     alt="Toggle Button" />
					</a>
					<ul class="sub-filters">
						<li>
							<fieldset class="checkbox-field">
								<input @click="$emit('getAllCreditTypes', $event)"
								       type="checkbox"
								       id="all-credit-types"
								       data-name="All Credit Types"
								       aria-labelledby="All Credit Types"
								       name="All Credit Types" />
								<label for="all-credit-types">All Credit Types</label>
							</fieldset>
							<fieldset v-for="({term_id, name, slug}) in creditType"
							          :key="term_id"
							          class="checkbox-field">
								<input @click="$emit('selectCreditType', $event)"
								       type="checkbox"
								       :id="term_id"
								       :data-name="name"
								       :data-ctype-slug="slug"
								       :aria-labelledby="name"
								       :name="name" />
								<label :for="term_id"
								       v-html="name"></label>
							</fieldset>
						</li>
					</ul>
				</li>
				<!-- Practice Area -->
				<li v-show="!isObjEmpty(practiceArea)"
                    id="practice-area-dropdown">
					<a href="#"><span>{{titles.pa}}</span>
						<img class="no-action"
                             :src="icons.dropdown"
						     alt="Toggle Button" />
					</a>
					<ul class="sub-filters">
						<li>
							<fieldset class="checkbox-field">
								<input @click="$emit('getAllPA', $event)"
								       type="checkbox"
								       id="all-pa"
								       data-name="All Practice Areas"
								       aria-labelledby="All Practice Areas"
								       name="All Practice Areas" />
								<label for="all-pa">All Practice Areas</label>
							</fieldset>
							<fieldset v-for="({term_id, name, slug}) in practiceArea"
							          :key="term_id"
							          class="checkbox-field">
								<input @click="$emit('buildPracticeArea', $event)"
								       type="checkbox"
								       :id="term_id"
								       :data-name="name"
								       :data-pa-slug="slug"
								       :aria-labelledby="name"
								       :name="name" />
								<label :for="term_id"
								       v-html="name"></label>
							</fieldset>
						</li>
					</ul>
				</li>
			</ul>
			<div class="search-keyword">
				<label id="keyword" for="keyword">{{ titles.searchBox }}</label>
				<fieldset>
					<input
						aria-labelledby="keyword"
						type="text"
						name="keyword"
						autocomplete="off"
						v-model="searchText"
						v-on:keypress.enter="$emit('buildSearch', searchText)"
					/>
					<div
						@click.prevent="$emit('buildSearch', searchText)"
						class="search-btn">
						<input
							type="submit"
							value="Search"
						/>
					</div>
				</fieldset>
			</div>
			<a href="#listing-top" class="back-top scrollto">
				<span class="btn"></span>
				<span class="txt">Back to the Top</span>
			</a>
		</aside>
	</div>
</template>

<script>
    import mixins from "../../Util/mixins";

    export default {
        props: {
            types: [Array, Object],
            creditType: [Array, Object],
            practiceArea: [Array, Object],
            filters: [Array, Object],
            filterCreditRange: [Object, Array],
            search: String
        },
        data() {
            return {
                filterForTitles: {
                    searchingFor: null,
                    searchFilters: null,
                    searchCredits: null,
                },
                titles: {
                    type: null,
                    region: null,
                    credit: null,
                    creditType: null,
                    pa: null,
                    searchBox: null,
                },
                icons: {},
                creditRange: {},
                searchText: ""
            };
        },
        beforeMount() {
            if (typeof SIDEBAR !== "undefined") {
                /**
                 * Search Filter Titles
                 */
                (SIDEBAR.selectedText) ? this.filterForTitles.searchingFor = SIDEBAR.selectedText : null;
                (SIDEBAR.selectedTitle) ? this.filterForTitles.selectedTitle = SIDEBAR.selectedTitle : null;
                (SIDEBAR.searchCredits) ? this.filterForTitles.searchCredits = SIDEBAR.searchCredits : null;

                /**
                 * Filter Accordion Titles
                 */
                (SIDEBAR.typeTitle) ? this.titles.type = SIDEBAR.typeTitle : null;
                (SIDEBAR.creditTitle) ? this.titles.credit = SIDEBAR.creditTitle : null;
                (SIDEBAR.creditTypeTitle) ? this.titles.creditType = SIDEBAR.creditTypeTitle : null;
                (SIDEBAR.paTitle) ? this.titles.pa = SIDEBAR.paTitle : null;
                (SIDEBAR.searchTitle) ? this.titles.searchBox = SIDEBAR.searchTitle : null;
            }

            if (typeof ICONS !== "undefined") {
                (ICONS.close) ? this.icons.close = ICONS.close : null;
                (ICONS.dropdown) ? this.icons.dropdown = ICONS.dropdown : null;
            }

            // if (this.filterCreditRange.length) {
            //     this.filterCreditRange.min !== null ? this.creditRange.min = this.filterCreditRange.min : null;
            //     this.filterCreditRange.max !== null ? this.creditRange.max = this.filterCreditRange.max : null;
            // }
        },
        methods: {
            resetFilters() {
                this.$emit("resetFilters");
                this.creditRange = {};
            },
            clearSearchInput() {
                this.$emit("clearSearch", this.search);
                this.searchText = "";
            },
            isEmpty(obj) {
                return Object.keys(obj).length === 0;
            },
        },
        watch: {
            filterCreditRange(newRange) {
                this.creditRange.min = newRange.min || "";
                this.creditRange.max = newRange.max || "";
            },
            search(newSearchText) {
                this.searchText = newSearchText;
            }
        },
        mixins: [mixins],
        name: "ProductSidebar"
    };
</script>

<style lang="scss" scoped>
	.remove-text {
		position: relative;
		z-index:  1;
		img {
			height: 11px;
			width:  11px;
		}
	}
	.search-credit-range {
		margin-top: 0;
	}
    .no-action {
        pointer-events: none;
    }
    .last-item {
        border-bottom: 0;
        border-radius: 0 0 10px 10px;
    }
</style>
