var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-xs-6" }, [
    _vm.member.name
      ? _c("p", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.member.name)),
          _vm.member.designation
            ? _c("span", {
                domProps: { innerHTML: _vm._s(", " + _vm.member.designation) },
              })
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.member.roster_position_title
      ? _c("p", {
          staticClass: "title",
          domProps: { innerHTML: _vm._s(_vm.member.roster_position_title) },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.member.organization
      ? _c("p", {
          staticClass: "title",
          domProps: { innerHTML: _vm._s(_vm.member.organization) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }