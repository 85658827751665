<template>
	<div v-if="tabSections"
	     class="general-tabbed">
		<p class="label">Display</p>
		<TabsHeader v-if="titles"
		            :titles="titles"
		            :icon="icon.title" />
		<TabbedSection :data="tabSections" />
	</div>
</template>

<script type="application/javascript">
    import TabsHeader from "./MyNYSBA/TabHeader.vue";
    import TabbedSection from "./MyNYSBA/TabSections.vue";

    export default {
        data() {
            return {
                titles: [],
                icon: {
                    title: [],
                    section: []
                },
            };
        },
        created() {
            (MYNYSBA.tabs.tabTitle) ? this.titles = MYNYSBA.tabs.tabTitle : null;
            (MYNYSBA.tabs.titleIcon.tabLine) ? this.icon.title = MYNYSBA.tabs.titleIcon.tabLine : null;
        },
		computed: {
		    tabSections() {
                return {
                    committees: this.$store.state.memberData.committees,
                    events: this.$store.state.memberData.events,
                    eventCategories: this.$store.state.memberData.eventCategories,
                    posts: this.$store.state.memberData.posts
                };
		    },
		},
        components: {TabsHeader, TabbedSection},
        name: "MyNYSBATabs"
    };
</script>

<style scoped>

</style>
