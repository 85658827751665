var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: _vm.envId, staticClass: "single-event fc-div" }, [
    _c(
      "a",
      { staticClass: "fc-more single-more", on: { click: _vm.submitId } },
      [_vm._v(_vm._s(_vm.text))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }