<template>
    <a :href="permalink"
       class="programs-slider__slide__container">
        <div class="programs-slider__slide__top">
            <div v-if="image"
                 class="programs-slider__slide__top__image">
                <PostImage :image="image" />
            </div>
            <div class="programs-slider__slide__top__copy">
                <div class="featured-news-card-content">
                    <div class="post-meta">
                        <p v-if="date"
                           v-text="date" />
                        <p v-if="date && category"
                           class="post-separator"
                           v-html="`&blacksquare;`" />
                        <p v-if="category"
                           v-text="category" />
                    </div>
                    <h3 class="post-title"
                        v-text="title" />
                </div>
            </div>
        </div>
        <div class="programs-slider__slide__link">
            <p class="line-btn blue"><span v-text="`Read more`" /></p>
        </div>
    </a>
</template>

<script type="application/javascript">
    import PostImage from "./PostImage.vue";

    export default {
        props: {
            category: String,
            date: String,
            image: [Array, Object],
            permalink: String,
            title: String,
        },
        components: {PostImage},
        name: "NewsPost"
    };
</script>

<style scoped>

</style>
