<template>
    <div :class="!!isLoading ? loadingClass : ''">
        <MemberCard v-if="$resize && $mq.above(1025)"
                    :data="memberData"
                    :editLinks="editLinks"
                    :loading="isLoading"
                    :icons="icons"
                    :allow-update="allowUpdate"
                    :time-update="canUpdate"
                    @refreshMemberData="refreshMemberData" />
        <MemberCardTablet v-else
                          :data="memberData"
                          :editLinks="editLinks"
                          :loading="isLoading"
                          :icons="icons"
                          :time-update="canUpdate"
                          @refreshMemberData="refreshMemberData" />
    </div>
</template>

<script>
    import axios from "axios";
    import moment from "moment";
    
    import MemberCard from "./MyNYSBA/MemberCard.vue";
    import MemberCardTablet from "./MyNYSBA/MemberCardTablet.vue";
    
    export default {
        data() {
            return {
                allowUpdate: false,
                editLinks: {
                    editPhoto: null,
                    editInfo: null
                },
                icons: [],
                isLoading: true,
                loadingClass: "loading-data",
                timeUpdated: "",
                canUpdate: ""
            };
        },
        created() {
            this.isLoading = true;
            (ICONS) ? this.icons = ICONS : null;
            (MYNYSBA.editPhoto) ? this.editLinks.editPhoto = MYNYSBA.editPhoto : null;
            (MYNYSBA.editInfo) ? this.editLinks.editInfo = MYNYSBA.editInfo : null;
            
            if (MYNYSBA.allowUpdate.length) {
                let allow = false;
                if (MYNYSBA.allowUpdate === "1") allow = true;
                this.allowUpdate = allow;
            }
            
            (MYNYSBA.timeUpdate.length) ? this.timeUpdated = MYNYSBA.timeUpdate : null;
            
        },
        mounted() {
            setTimeout(() => this.isLoading = false, 2000);
        },
        methods: {
            refreshMemberData() {
                let config = {params: {}};
                const api = (MYNYSBA.updateUser !== undefined) ? MYNYSBA.updateUser : "";
                const memberId = this.memberData.id;
                
                (this.memberData.sfId.length) ? config.params.sfId = this.memberData.sfId : null;
                
                config.params.noCache = true;
                
                this.isLoading = true;
                this.allowUpdate = false;
                
                axios
                    .get(`${api + memberId}`, config)
                    .then(({data, status}) => {
                        if (status === 200 && data.status === "complete") {
                            this.$store.dispatch("getMemberData");
                        }
                    })
                    .catch(err => console.log(err))
                    .then(() => this.isLoading = false);
            },
            /**
             * This function works on the Refresh button to set a timeout of a difference of 5 minutes
             * @param msOffset  The different in MS before they can refresh data
             */
            debounceTime(msOffset) {
                /**
                 * Our set timeout
                 */
                if (msOffset > 0) setTimeout(() => this.allowUpdate = true, msOffset);
                // if (msOffset > 0 ) setTimeout(() => this.allowUpdate = true, 10000); // Debugging purposes
            }
        },
        computed: {
            memberData: {
                get() {
                    return this.$store.state.memberData;
                },
                set(memberData) {
                    return memberData;
                }
            },
        },
        watch: {
            memberData(newData) {
                // Want to add an extra minute, unless it's decided to show the seconds
                const format = "MM/DD/YYYY h:mm:ss A";
                const date = moment.utc(newData.canUpdate).local();
                this.canUpdate = date.format(format);
                this.debounceTime(Number(newData.diffInMs));
            }
        },
        components: {MemberCard, MemberCardTablet},
        name: "MyNYSBA"
    };
</script>

<style lang="scss" scoped>
    .loading-data {
        min-height: 75vh;
    }
</style>
