<template>
	<div class="col-xs-12">
		<div class="sections-list">
			<ul v-if="posts">
				<Result v-for="(post) in posts"
						:key="post.id"
						:post="post" />
			</ul>
		</div>
	</div>
</template>

<script>
    import Result from "./CommitteeResult.vue";

    export default {
        props: {
            posts: [Array, Object]
        },
        data() {
            return {
                typeTitle: null,
                nameTitle: null,
                paTitle: null,
                hgTitle: null,
            };
        },
        created() {
            if (typeof LISTING !== "undefined") {
                (LISTING.listTypeTitle) ? this.typeTitle = LISTING.listTypeTitle : null;
                (LISTING.listNameTitle) ? this.nameTitle = LISTING.listNameTitle : null;
                (LISTING.listPaTitle) ? this.paTitle = LISTING.listPaTitle : null;
                (LISTING.listHgTitle) ? this.hgTitle = LISTING.listHgTitle : null;
            }
        },
        components: {Result},
        name: "CommitteeResults"
    };
</script>
