var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Search", {
        attrs: {
          types: _vm.typeTerms,
          pas: _vm.paTerms,
          search: _vm.search,
          selectedTypes: _vm.filters.types,
          selectedPas: _vm.filters.sortingType,
        },
        on: {
          buildTypes: _vm.buildTypes,
          buildPas: _vm.buildPas,
          buildAllTypes: _vm.buildAllTypes,
          buildAllPas: _vm.buildAllPas,
          buildSearch: _vm.buildSearch,
          resetAll: _vm.resetAll,
        },
      }),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass: "advanced-search-results",
          attrs: { id: "listing-top" },
        },
        [
          _vm.$resize && _vm.$mq.above(1025)
            ? _c("Results", { attrs: { posts: _vm.posts } })
            : _vm.$resize && _vm.$mq.below(1025)
            ? _c("ResultsTablet", { attrs: { posts: _vm.posts } })
            : _vm._e(),
          _vm._v(" "),
          _vm.maxPage > 1
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12" },
                  [
                    _c("Pagination", {
                      attrs: {
                        "page-count": _vm.maxPage,
                        "page-range": 3,
                        "force-page": _vm.currentPage,
                        "margin-pages": 1,
                        "click-handler": _vm.gotoPage,
                        "prev-text": "",
                        "next-text": "",
                        "container-class": "pagination",
                        "page-link-class": "page-numbers",
                        "next-link-class": "next",
                        "prev-link-class": "prev",
                        "active-class": "current",
                        "break-view-link-class": "dots",
                        "no-li-surround": true,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }