<template>
	<div class="tab-wrapper">
		<a href="#" class="mobile-dd"><span class="text">Dashboard</span><span class="icon"></span></a>
		<ul v-if="titles" role="tablist">
			<li v-for="(title, index) in titles"
			    :key="index"
				role="presentation">
				<a :href="`#section${index}`"
				   :id="`tab-header${index}`"
				   class="tab-header"
				   tabindex="-1"
				   :aria-selected="`${index === 0}`"
				   @click.prevent="clickToggleTabVisible($event)"
				   @keydown.prevent="keyTabVisible($event)">
					<img :src="icon" alt="white line" />
					<span v-text="title"></span>
				</a>
			</li>
		</ul>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            titles: [Array, Object],
            icon: String
        },
	    mounted() {
            const tabList = document.querySelectorAll("[id^=\"tab-header\"]");
            // tabList.forEach((tab, i) => i !== 0 ? tab.setAttribute('tabindex', '-1') : null);
            Array.prototype.forEach.call(tabList, (tab, i) => i !== 0 ? tab.setAttribute('tabindex', '-1') : null);
	    },
        methods: {
            clickToggleTabVisible(elm) {
                // Need to setup the right currentTab var based on the target
                let currentTab;
                if (elm.target.nodeName === "SPAN") currentTab = elm.target.parentNode;
                else currentTab = elm.target;

                const tabbed = document.querySelector(".general-tabbed");
                const tablist = tabbed.querySelector("ul");
                const tabs = tablist.querySelectorAll("a");
                const oldTab = tablist.querySelector("[aria-selected]");
                const panels = document.querySelectorAll('[id^="section"]');

                if (currentTab.getAttribute('aria-selected') !== "true") this.switchTab(oldTab, currentTab, tabs, panels);

            },
            keyTabVisible(elm) {
                // Need to setup the right currentTab var based on the target
                let currentTab;
                if (elm.target.nodeName === "SPAN") currentTab = elm.target.parentNode;
                else currentTab = elm.target;

                const tabbed = document.querySelector(".general-tabbed");
                const tablist = tabbed.querySelector("ul");
                const tabs = tablist.querySelectorAll("a");
                const oldTab = tablist.querySelector("[aria-selected]");
                const panels = document.querySelectorAll('[id^="section"]');

                let index = Array.prototype.indexOf.call(tabs, currentTab);
                let dir = currentTab.which === 37 ? index - 1 : (currentTab.which === 39 ? index + 1 : (currentTab.which === 40 ? "down" : null));

                if (dir !== null) {
                    dir === "down" ? panels[i].focus() : (tabs[dir] ? this.switchTab(oldTab, tabs[dir], tabs, panels) : void 0);
                }

            },
            // Function to switch attributes on the oldTabs and newTab
	        switchTab(oldTab, newTab, tabs, panels) {
                newTab.focus();
                newTab.removeAttribute("tabindex");
                newTab.setAttribute("aria-selected", "true");
                oldTab.removeAttribute("aria-selected");
                oldTab.setAttribute("tabindex", "-1");

                let index = Array.prototype.indexOf.call(tabs, newTab);
                let oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
                panels[index].hidden = false;
                panels[oldIndex].hidden = true;
	        }
        },
        name: "TabHeader"
    };
</script>

<style scoped>

</style>
