var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-content" }, [
    _c("div", { staticClass: "modal-close" }, [
      _c(
        "a",
        {
          staticClass: "close",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.closeModal.apply(null, arguments)
            },
          },
        },
        [
          _c("span", { staticClass: "sr-only" }, [
            _vm._v("Close event modal window"),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-event-date" }, [
      _vm._v(_vm._s(_vm.eventDate)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-6" }, [
        _c(
          "div",
          { class: `modal-event modal-event-${_vm.event.extendedProps.slug}` },
          [
            _vm.event.extendedProps.hasOwnProperty("categoryName")
              ? _c(
                  "div",
                  { staticClass: "modal-event-category-container" },
                  _vm._l(
                    _vm.event.extendedProps.categoryName,
                    function ({ name, id, slug }) {
                      return _c(
                        "div",
                        { key: id, class: `modal-event-category ${slug}` },
                        [_vm._v(_vm._s(name) + "\n\t\t\t\t\t")]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "modal-event-title" }, [
              _c("a", { attrs: { href: _vm.event.url } }, [
                _vm._v(_vm._s(_vm.event.title)),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }