<template>
	<div>
		<div id="section0" class="dashboard-button-content">
			<h2 class="section-title sr-only">dashboard</h2>
			<CommitteeBlock :posts="committeePosts"
			                :loading="isLoading" />
		</div>

		<div id="section1" class="calendar-button-content">
			<h2 class="section-title sr-only">Calendar</h2>
			<CalendarBlock :event-categories="eventCategories"
			               :events-registered="eventsRegistered"
			               :loading="isLoading" />
		</div>

		<div id="section2" class="favorite-button-content">
			<h2 class="section-title sr-only">Favorite</h2>
			<PostsBlock :posts="newsPosts"
			            :cats="newsCategories"
			            :loading="isLoading"
			            :max-pages="numOfPostPages"
			            :current-page="currentPostPage"
			            @buildCats="buildCats"
			            @buildAllCats="buildAllCats"
						@buildSearch="buildSearch"
						@goToPostsPage="goToPostsPage"
						@resetPosts="resetPosts"/>
		</div>

	</div>
</template>

<script type="application/javascript">
    import axios from "axios";

    import CommitteeBlock from "./Sections/Committee/CommitteeBlock.vue";
    import CalendarBlock from "./Sections/Events/CalendarBlock.vue";
    import PostsBlock from "./Sections/FavoritePosts/PostsBlock.vue";

    export default {
        props: {
            data: [Array, Object]
        },
        data() {
            return {
                committeePosts: [],
                eventPosts: [],
                eventsRegistered: [],
                eventCategories: [],
                newsPosts: [],
	            newsCategories: [],
	            filters: {
                    cats: []
	            },
                numOfPostPages: 0,
                currentPostPage: 1,
	            eventResults: 0,
	            search: '',
                isLoading: true,
            };
        },
        mounted() {
            const tabbed = document.querySelector(".general-tabbed");
            const tablist = tabbed.querySelector("ul");
            const tabs = tablist.querySelectorAll("a");
            const panels = document.querySelectorAll("[id^=\"section\"]");

            // Migrated FE code to hide unshown panels
            Array.prototype.forEach.call(panels, (panel, i) => {
                panel.setAttribute("role", "tabpanel");
                panel.setAttribute("tabindex", "-1");
                let id = panel.getAttribute("id");
                panel.setAttribute("aria-labelledby", tabs[i].id);
                panel.hidden = true;
            });

            panels[0].hidden = false;

        },
        methods: {
            getSectionData(committees, posts) {
                let config = {params: {}};

                const api = (MYNYSBA.myNysbaSectionApi !== undefined) ? MYNYSBA.myNysbaSectionApi : "";
                committees !== undefined ? config.params.committees = committees : null;
                posts !== undefined ? config.params.posts = posts : null;

                axios
                    .get(`${api}`, config)
                    .then(({data, status}) => {
                        if (status === 200) {
                            data.committees !== undefined ? this.committeePosts = data.committees : null;
                            data.posts !== undefined ? this.newsPosts = data.posts : null;
                            data.postCategories !== undefined ? this.newsCategories = data.postCategories : null;
                            data.numOfPages !== undefined ? this.numOfPostPages = data.numOfPages : null;
                        }
                    })
                    .catch(err => console.log(err))
                    .then(() => this.isLoading = false);
            },
            buildAllCats(elm) {
                elm.checked === false ? this.goToPostsPage(1) : null;

                const grandParent = elm.parentNode.parentNode;
                const populatedCats = grandParent.querySelectorAll('[class^="populated-input"]');

                // Unchecks the other items
                if (elm.checked) Array.prototype.forEach.call(populatedCats, el => el.checked = false);
                else elm.checked = false;

                this.filters.cats = [];
            },
            buildCats(elm) {
                const grandParent = elm.parentNode.parentNode;
                const allElm = grandParent.querySelector('[id="all-categories"]');

                console.log(allElm);

                if (allElm.checked === true) allElm.checked = false;

                // Add item to filters array
                if (elm.checked) this.filters.cats.push(elm.value);
                // Remove item from filters array
                else if (this.filters.cats.indexOf(elm.value) > -1) this.filters.cats.splice(this.filters.cats.indexOf(elm.value), 1);

                this.goToPostsPage(1);
            },
	        buildSearch(searchText) {
                this.isLoading = true;
                let config = {params: {}};

                this.search = searchText;
                this.data.posts !== undefined ? config.params.posts = this.data.posts : null;
                config.params.search = searchText;

                this.apiCall(config, 'posts');
	        },
            goToPostsPage(pageNum) {
                this.isLoading = true;
                let config = {params: {}};
                this.data.posts !== undefined ? config.params.posts = this.data.posts : null;
                this.search.length ? config.params.search = this.search : null;
                this.filters.cats.length ? config.params.postCats = this.filters.cats : null;

                this.currentPostPage = pageNum;
                config.params.page = pageNum;

                this.apiCall(config, 'posts');
            },
            resetPosts() {
                this.isLoading = true;
                let config = {params: {}};
                this.data.posts !== undefined ? config.params.posts = this.data.posts : null;
                this.search = '';
                this.currentPostPage = 1;

                this.apiCall(config, 'posts');
            },
            apiCall(config = {}, returnType) {
                const api = (MYNYSBA.myNysbaSectionApi !== undefined) ? MYNYSBA.myNysbaSectionApi : "";
                axios
                    .get(`${api}`, config)
                    .then(({data, status}) => {
                        if (status === 200) {
                            if (returnType === 'posts' ) {
                                data.posts !== undefined ? this.newsPosts = data.posts : null;
                                data.numOfPages !== undefined ? this.numOfPostPages = data.numOfPages : null;
                            }
                        }
                    })
                    .catch(err => console.log(err))
                    .then(() => this.isLoading = false);
	        }
        },
        watch: {
            data(newData) {
                if (newData.events !== undefined) this.eventsRegistered = newData.events;
                if (newData.eventCategories !== undefined) this.eventCategories = newData.eventCategories;

                let committees, posts;
                if (newData.committees !== undefined) committees = newData.committees;
                if (newData.posts !== undefined) posts = newData.posts;
                this.getSectionData(committees, posts);
            }
        },
        components: {CalendarBlock, CommitteeBlock, PostsBlock},
        name: "TabSections"
    };
</script>

<style scoped>

</style>
