var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "programs-slider__container" },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "loading-container" },
            [_c("Loading", { attrs: { loading: _vm.loading } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.posts ? _c("NewsSlider", { attrs: { posts: _vm.posts } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }