var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row tablet-settings-container" }, [
    _c("div", { staticClass: "col-xs-10 tablet-settings" }, [
      _c("div", { staticClass: "tablet-settings-head" }, [
        _c("div", { staticClass: "tablet-advanced-filters" }, [
          _c("img", {
            attrs: { src: _vm.icons.filter, alt: "Advanced Filters Icons" },
          }),
          _vm._v(" "),
          _c("a", { attrs: { href: "#" } }, [_vm._v("Advanced Filters")]),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "curve-btn blue",
            attrs: { href: "#", id: "advanced-submit-btn" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.search.apply(null, arguments)
              },
            },
          },
          [_c("span", [_vm._v("Done")])]
        ),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "row advanced-filters" }, [
        _c("form", { attrs: { id: "advanced-filter-form", action: "" } }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 advanced-filter dropdown",
              attrs: { "aria-expanded": "false" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "advanced-filter-title",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleDropdown.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v("Category\n\t\t\t\t\t\t"),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-filter-toggle",
                      attrs: { href: "#" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.filterPlus,
                          alt: "Expand Categories",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "advanced-filter-dropdown-options" }, [
                _c(
                  "div",
                  {
                    staticClass: "control-group category cat-list",
                    attrs: { id: "tablet-types" },
                  },
                  [
                    _c("fieldset", { staticClass: "checkbox-field" }, [
                      _c("input", {
                        attrs: {
                          type: "checkbox",
                          id: "all-tablet-types",
                          "data-cat": "0",
                          "data-format-slug": "all",
                          "aria-labelledby": "All Categories",
                          name: "all-tablet-regions",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.buildTypes($event)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "all-tablet-regions" } }, [
                        _vm._v("All Categories"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.types, function ({ term_id, name, slug }) {
                      return _c(
                        "fieldset",
                        { key: term_id, staticClass: "checkbox-field" },
                        [
                          _c("input", {
                            staticClass: "filter-cat other-types",
                            attrs: {
                              type: "checkbox",
                              id: `cat-${slug}`,
                              "data-cat": term_id,
                              "data-format-slug": slug,
                              "aria-labelledby": slug,
                              name: name,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.buildTypes($event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", {
                            attrs: { for: slug },
                            domProps: { innerHTML: _vm._s(name) },
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-xs-12 advanced-filter dropdown",
              attrs: { "aria-expanded": "false" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "advanced-filter-title",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleDropdown.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v("Regions\n\t\t\t\t\t\t"),
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-filter-toggle",
                      attrs: { href: "#" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.icons.filterPlus,
                          alt: "Expand Regions",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "advanced-filter-dropdown-options" }, [
                _c(
                  "div",
                  {
                    staticClass: "control-group regions region-list",
                    attrs: { id: "tablet-regions" },
                  },
                  [
                    _c("fieldset", { staticClass: "checkbox-field" }, [
                      _c("input", {
                        attrs: {
                          type: "checkbox",
                          id: "all-tablet-regions",
                          "data-name": "All Regions",
                          "data-region-slug": "all-tablet-regions",
                          "aria-labelledby": "All Regions",
                          name: "all",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.buildRegion($event)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "all-tablet-regions" } }, [
                        _vm._v("All Regions"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.regions, function ({ term_id, name, slug }) {
                      return _c(
                        "fieldset",
                        { key: term_id, staticClass: "checkbox-field" },
                        [
                          _c("input", {
                            staticClass: "other-regions",
                            attrs: {
                              type: "checkbox",
                              id: `region-${slug}`,
                              "data-region-slug": slug,
                              "data-term": term_id,
                              "aria-labelledby": slug,
                              name: name,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.buildRegion($event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", {
                            attrs: { for: slug },
                            domProps: { innerHTML: _vm._s(name) },
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 advanced-filter" }, [
            _c("div", { staticClass: "advanced-filter-title" }, [
              _vm._v("Date\n\t\t\t\t\t\t"),
              _c(
                "div",
                { staticClass: "advanced-input-container" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "hidden-label",
                      attrs: { for: "tablet-date" },
                    },
                    [_vm._v("Date")]
                  ),
                  _vm._v(" "),
                  _c("DatePicker", {
                    attrs: {
                      "input-id": "tablet-date",
                      type: "date",
                      "value-zone": "local",
                      value: _vm.filter.date,
                      "full-month-name": true,
                      "week-start": 7,
                    },
                    on: { close: _vm.buildDate },
                    model: {
                      value: _vm.filter.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "date", $$v)
                      },
                      expression: "filter.date",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "calendar-filter-buttons" }, [
          _c(
            "a",
            {
              staticClass: "curve-btn white",
              attrs: { id: "advanced-reset-btn", role: "button", href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.resetHandler.apply(null, arguments)
                },
              },
            },
            [_c("span", [_vm._v("Reset all filters")])]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-xs-2 tablet-settings-overlay" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }