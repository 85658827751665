var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "calendar-filters" }, [
    _c("div", { staticClass: "row" }, [
      _c("form", { attrs: { id: "calendar-filters-form", action: "" } }, [
        _c("div", { staticClass: "col-xs-3" }, [
          _c(
            "fieldset",
            [
              _c(
                "label",
                {
                  staticClass: "calendar-filter-label",
                  attrs: { for: "date" },
                },
                [_vm._v("Date MM/DD/YYYY")]
              ),
              _vm._v(" "),
              _c("DatePicker", {
                attrs: {
                  "input-id": "date",
                  type: "date",
                  "value-zone": "local",
                  value: _vm.filter.date,
                  "full-month-name": true,
                  "week-start": 7,
                },
                on: { close: _vm.dateHandler },
                model: {
                  value: _vm.filter.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "date", $$v)
                  },
                  expression: "filter.date",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-3" }, [
          _c("fieldset", [
            _c(
              "label",
              {
                staticClass: "calendar-filter-label",
                attrs: { for: "calendar-date-display" },
              },
              [_vm._v("Display")]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                staticClass: "advanced-search-dropdown",
                attrs: {
                  name: "calendar-date-display",
                  id: "calendar-date-display",
                  "aria-invalid": "false",
                  "data-selectric": "",
                },
              },
              [
                _c(
                  "option",
                  {
                    attrs: {
                      disabled: "",
                      "aria-disabled": "true",
                      value: "disabled",
                    },
                  },
                  [_vm._v(_vm._s(_vm.selected))]
                ),
                _vm._v(" "),
                _vm._l(_vm.views, function ({ title, value }, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: value } },
                    [_vm._v(_vm._s(title) + "\n\t\t\t\t\t\t")]
                  )
                }),
              ],
              2
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-3" }, [
          _c("fieldset", [
            _c(
              "label",
              {
                staticClass: "advanced-search-label",
                attrs: { for: "calendar-date-region" },
              },
              [_vm._v("Region")]
            ),
            _vm._v(" "),
            _c("ul", { staticClass: "sidebar-filters" }, [
              _c("li", [
                _c("a", { attrs: { href: "#" } }, [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(_vm.firstRegion) +
                        " " +
                        _vm._s(
                          _vm.regionCount > 1 ? `+ ${_vm.regionCount}` : null
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "arrow" }),
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "sub-filters" }, [
                  _c(
                    "li",
                    {
                      staticClass: "region-list",
                      attrs: { id: "regions-dropdown" },
                    },
                    [
                      _c("fieldset", { staticClass: "checkbox-field" }, [
                        _c("input", {
                          attrs: {
                            type: "checkbox",
                            id: "all-regions",
                            "data-name": "All Regions",
                            "data-region-slug": "all-regions",
                            "aria-labelledby": "All Regions",
                            name: "All Regions",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.filterRegions($event)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "all-regions" } }, [
                          _vm._v("All Regions"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.regions, function ({ term_id, name, slug }) {
                        return _c(
                          "fieldset",
                          { key: term_id, staticClass: "checkbox-field" },
                          [
                            _c("input", {
                              staticClass: "other-regions",
                              attrs: {
                                type: "checkbox",
                                id: slug,
                                "data-name": name,
                                "data-region-slug": slug,
                                "data-region-term": term_id,
                                "aria-labelledby": slug,
                                name: name,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.filterRegions($event)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("label", {
                              attrs: { for: slug },
                              domProps: { innerHTML: _vm._s(name) },
                            }),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-xs-3 align-items-flex-end calendar-filter-buttons",
          },
          [
            _c(
              "a",
              {
                staticClass: "curve-btn blue",
                attrs: { id: "submit-btn", role: "button", href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.searchHandler.apply(null, arguments)
                  },
                },
              },
              [_c("span", [_vm._v("Search")])]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "curve-btn white",
                attrs: { id: "reset-btn", role: "button", href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.resetHandler.apply(null, arguments)
                  },
                },
              },
              [_c("span", [_vm._v("Reset all filters")])]
            ),
          ]
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-xs-12 hide" }, [
      _c("span", { staticClass: "search-filters-update-text" }, [
        _vm._v("Search filters updated!"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }