<template>
	<div class="col-xs-6">
		<p v-if="member.name"
		   class="name">{{ member.name }}<span v-if="member.designation" v-html="', ' + member.designation"></span></p>
		<p v-if="member.roster_position_title"
			class="title" v-html="member.roster_position_title"></p>
		<p v-if="member.organization"
		   class="title" v-html="member.organization"></p>
<!--		<p v-if="member.roster_position_title"-->
<!--		   class="title">{{ member.roster_position_title }}</p>-->
<!--		<a v-if="member.email"-->
<!--		   :href="member.email"-->
<!--		   target="_blank">{{ member.email }}</a>-->
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            member: [Object, Array]
        },
        name: "RosterMember"
    };
</script>

<style scoped>

</style>
