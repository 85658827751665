<template>
	<div class="calendar-keys">
		<ul>
			<li class="no-events">Not available</li>
			<li class="searched-event">Searched event</li>
			<li class="today-event">Today</li>
		</ul>
		<p>Tap each date to see full list of programs</p>
	</div>
</template>

<script type="application/javascript">
    export default {
        name: "CalendarLegend"
    };
</script>

<style scoped>

</style>
