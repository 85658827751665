var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    {
      staticClass: "zebra-table desktop-cart-results-table",
      attrs: { cellpadding: "0", cellspacing: "0", width: "100%" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      typeof _vm.contents
        ? _c(
            "tbody",
            _vm._l(_vm.contents, function (item, index) {
              return _c("CartItem", {
                key: index,
                attrs: { index: index, item: item, icons: _vm.icons },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { width: "50%" } }, [_vm._v("Title")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "25%" } }, [_vm._v("Product Type")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "12.5%" } }, [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "12.5%" } }, [_vm._v("Price")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }