var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "programs-slider__slide__container",
      attrs: { href: _vm.permalink },
    },
    [
      _c("div", { staticClass: "programs-slider__slide__top" }, [
        _vm.image
          ? _c(
              "div",
              { staticClass: "programs-slider__slide__top__image" },
              [_c("PostImage", { attrs: { image: _vm.image } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "programs-slider__slide__top__copy" }, [
          _c("div", { staticClass: "featured-news-card-content" }, [
            _c("div", { staticClass: "post-meta" }, [
              _vm.date
                ? _c("p", { domProps: { textContent: _vm._s(_vm.date) } })
                : _vm._e(),
              _vm._v(" "),
              _vm.date && _vm.category
                ? _c("p", {
                    staticClass: "post-separator",
                    domProps: { innerHTML: _vm._s(`&blacksquare;`) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.category
                ? _c("p", { domProps: { textContent: _vm._s(_vm.category) } })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("h3", {
              staticClass: "post-title",
              domProps: { textContent: _vm._s(_vm.title) },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "programs-slider__slide__link" }, [
        _c("p", { staticClass: "line-btn blue" }, [
          _c("span", { domProps: { textContent: _vm._s(`Read more`) } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }