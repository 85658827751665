var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-xs-12" }, [
    _c("div", { staticClass: "sections-list" }, [
      _vm.posts
        ? _c(
            "ul",
            _vm._l(_vm.posts, function (post) {
              return _c("Result", { key: post.id, attrs: { post: post } })
            }),
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }