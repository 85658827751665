<template>
    <div>
        
        <FutureEvents v-if="futureEvents.length"
                      :events="futureEvents" />
        
        <PastEvents v-if="pastEvents.length"
                    :events="pastEvents" />
    
    </div>
</template>

<script type="application/javascript">
    import axios from "axios";
    
    import FutureEvents from "./Committee-Detail/Events/FutureEvents.vue";
    import PastEvents from "./Committee-Detail/Events/PastEvents.vue";
    
    export default {
        data() {
            return {
                events: [],
                futureEvents: [],
                isLoading: false,
                pastEvents: [],
            };
        },
        created() {
            this.getEvents();
        },
        methods: {
            getEvents() {
                const api = NYSBA.allEventsApi + NYSBA.postId;
                this.isLoading = true;

                axios
                    .get(api)
                    .then(({data, status}) => {
                        if (status === 200) {
                            this.futureEvents = data.futureEvents;
                            this.pastEvents = data.pastEvents;
                        }
                    })
                    .then(() => this.isLoading = false)
                    .catch(err => console.log(err));
            }
        },
        components: {FutureEvents, PastEvents},
        name: "CommitteeEvents"
    };
</script>

<style scoped>

</style>
