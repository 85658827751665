var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("Sidebar", {
        attrs: {
          types: _vm.typeTerms,
          creditType: _vm.creditTerms,
          practiceArea: _vm.paTerms,
          filterCreditRange: _vm.filterCreditRange,
          filters: _vm.filterItems,
          search: _vm.search,
        },
        on: {
          buildFormats: _vm.buildFormats,
          selectCreditType: _vm.buildCreditType,
          buildCredits: _vm.buildCredits,
          buildPracticeArea: _vm.buildPracticeArea,
          buildSearch: _vm.buildSearch,
          getAllFormats: _vm.getAllFormats,
          getAllCreditTypes: _vm.getAllCreditTypes,
          getAllPA: _vm.getAllPA,
          clearSearch: _vm.clearSearchText,
          resetItem: _vm.removeItem,
          resetFilters: _vm.resetFilters,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-8" }, [
        _c("a", { staticClass: "program-open-filters", attrs: { href: "#" } }, [
          _c("img", { attrs: { src: _vm.filterIcon, alt: "Open Filters" } }),
          _vm._v(" Open Advanced Filters"),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "listing-top" } }, [
          _c(
            "div",
            { staticClass: "results" },
            [
              _vm.maxPage > 1
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-12" },
                      [
                        _c("Paginate", {
                          attrs: {
                            "page-count": _vm.maxPage,
                            "page-range": 3,
                            "force-page": _vm.currentPage,
                            value: _vm.currentPage,
                            "margin-pages": 1,
                            "click-handler": _vm.gotoPage,
                            "prev-text": "",
                            "next-text": "",
                            "container-class": "top-pagination pagination",
                            "page-link-class": "page-numbers",
                            "next-link-class": "next",
                            "prev-link-class": "prev",
                            "active-class": "current",
                            "break-view-link-class": "dots",
                            "no-li-surround": true,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("ListContainer", {
                attrs: { posts: _vm.posts, loading: _vm.isLoading },
              }),
              _vm._v(" "),
              _vm.maxPage > 1
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-12" },
                      [
                        _c("Paginate", {
                          attrs: {
                            "page-count": _vm.maxPage,
                            "page-range": 3,
                            "force-page": _vm.currentPage,
                            value: _vm.currentPage,
                            "margin-pages": 1,
                            "click-handler": _vm.gotoPage,
                            "prev-text": "",
                            "next-text": "",
                            "container-class": "bottom-pagination pagination",
                            "page-link-class": "page-numbers",
                            "next-link-class": "next",
                            "prev-link-class": "prev",
                            "active-class": "current",
                            "break-view-link-class": "dots",
                            "no-li-surround": true,
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function ($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }