<template>
	<div class="line-header-container">
		<div class="line-header">
			<h2>{{`Result (Total ${results} Events)`}}</h2>
		</div>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            results: Number
        },
        name: "EventResultsTitle"
    };
</script>

<style scoped>

</style>
