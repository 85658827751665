var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-xs-12 calendar-category-title" }, [
      _vm._v("Show"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-xs-12 category-list" }, [
      _c(
        "ul",
        { staticClass: "cat-list", attrs: { id: "category-container" } },
        [
          _c(
            "li",
            {
              staticClass: "filter-all filter category-everything active",
              attrs: {
                "data-cat": "0",
                id: "category-everything",
                "data-format-slug": "all",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.filterType($event)
                },
              },
            },
            [_c("a", { attrs: { href: "#" } }, [_vm._v("Everything")])]
          ),
          _vm._v(" "),
          _vm._l(_vm.types, function ({ term_id, slug, name }) {
            return _c(
              "li",
              {
                key: term_id,
                class: `filter-cat filter category-${slug}`,
                attrs: {
                  id: `category-${slug}`,
                  "data-cat": term_id,
                  "data-format-slug": slug,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.filterType($event)
                  },
                },
              },
              [
                _c("a", {
                  attrs: { href: "#" },
                  domProps: { innerHTML: _vm._s(name) },
                }),
              ]
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }