var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "calendar-category-container",
      attrs: { id: "desktop-calendar-header" },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xs-12" }, [
          _c("p", { staticClass: "label" }, [_vm._v("Show")]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "category-list" },
            [
              _c(
                "li",
                {
                  staticClass: "filter-all filter category-everything active",
                  attrs: {
                    id: "category-everything",
                    "data-cat": "0",
                    "data-name": "all-events",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.filterType($event)
                    },
                  },
                },
                [_c("a", { attrs: { href: "#" } }, [_vm._v("Everything")])]
              ),
              _vm._v(" "),
              _vm._l(_vm.types, function ({ term_id, slug, name }) {
                return _c(
                  "li",
                  {
                    key: term_id,
                    class: `filter-cat filter category-${slug}`,
                    attrs: {
                      id: `category-${slug}`,
                      "data-cat": term_id,
                      "data-name": slug,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.filterType($event)
                      },
                    },
                  },
                  [
                    _c("a", {
                      attrs: { href: "#" },
                      domProps: { innerHTML: _vm._s(name) },
                    }),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }