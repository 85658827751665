import { render, staticRenderFns } from "./CommitteeListing.vue?vue&type=template&id=873955c8&"
import script from "./CommitteeListing.vue?vue&type=script&lang=js&"
export * from "./CommitteeListing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.nysba.cliquedomains.com/web/app/themes/nysba/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('873955c8')) {
      api.createRecord('873955c8', component.options)
    } else {
      api.reload('873955c8', component.options)
    }
    module.hot.accept("./CommitteeListing.vue?vue&type=template&id=873955c8&", function () {
      api.rerender('873955c8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/scripts/Vue/Components/CommitteeListing.vue"
export default component.exports