<template>
	<div class="calendar-title">
		<h2>{{pageTitle}}</h2>
		<div class="onoffswitch">
			<input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" checked>
			<label class="onoffswitch-label" for="myonoffswitch">
				<span class="onoffswitch-inner"></span>
				<span class="onoffswitch-switch"></span>
			</label>
			<p v-for="(view, index) in views"
			   :key="index"
			   :class="view.title.toLowerCase()"
			   v-text="view.title"></p>
		</div>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            pageTitle: String,
            views: [Object, Array],
        },
        data() {
            return {
                selected: "Monthly",
                switchView: 'monthly'
            };
        },
	    mounted() {
            $('.onoffswitch input').on('click', (input) => {
                const elm = input.currentTarget;
                let selectVal;

                if (elm.checked) selectVal = 'month';
                else selectVal = 'week';

                this.$emit("calSwitch", selectVal);
            })
	    },
        name: "Title"
    };
</script>

<style scoped>

</style>
