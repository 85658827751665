<template>
    <div class="row">
        <Post v-for="(post, index) in posts"
              :key="index"
              :credits="post.credits"
              :date="post.date"
              :format="post.format"
              :image="post.image"
              :permalink="post.permalink"
              :post-class="postClass"
              :start-time="post.startTime"
              :title="post.title" />
    </div>
</template>

<script type="application/javascript">
    import Post from "../Blocks/Post.vue";
    
    export default {
        props: {
            err: Boolean,
            postClass: String,
            posts: [Array, Object]
        },
        components: {Post},
        name: "PostsBlock"
    };
</script>

<style scoped>

</style>
