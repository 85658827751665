var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "calendar-monthly-weekly" },
    [
      _vm.eventCategories
        ? _c("EventHeaderCategories", {
            attrs: { types: _vm.eventCategories },
            on: { eventSearchFilter: _vm.eventSearchFilter },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("EventResultsTitle", { attrs: { results: _vm.results } }),
      _vm._v(" "),
      _c("p", { staticClass: "registered-event" }, [
        _c("img", {
          attrs: { src: _vm.icons.registered, alt: "Registered Event" },
        }),
        _vm._v(" Registered Event"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tablet-advanced-settings-toggle" }, [
        _c("div", { staticClass: "tablet-advanced-filters" }, [
          _c("img", {
            attrs: { src: _vm.icons.filter, alt: "advanced filters icons" },
          }),
          _vm._v(" Open Advanced Filters\n\t\t"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tablet-calendar-settings-container",
          attrs: { id: "tablet-calendar-settings" },
        },
        [
          _vm.eventCategories && _vm.$mq.below(1025)
            ? _c("EventTabletFilters", {
                attrs: { types: _vm.eventCategories, icons: _vm.icons },
                on: {
                  eventSearchFilter: _vm.eventSearchFilter,
                  searchReset: _vm.eventsReset,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "calendar-mynysba-container" } },
        [
          _c("div", { staticClass: "loading calendar-loading" }),
          _vm._v(" "),
          _vm.events.length === 0 && !_vm.loading
            ? _c(
                "div",
                { staticClass: "no-events" },
                [
                  _c("NoResults", {
                    attrs: { "no-results-text": _vm.noResultsText },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.events !== null && !_vm.loading
            ? _c("FullCalendar", {
                ref: "calendar",
                attrs: {
                  "custom-buttons": _vm.config.customNavigationButtons,
                  "dates-render": _vm.handleChange,
                  "default-view": _vm.config.defaultView,
                  editable: _vm.config.editable,
                  "event-limit-click": _vm.eventLimitCheck,
                  "event-render": _vm.eventRender,
                  events: _vm.events,
                  "handle-window-resize": _vm.mediaQueryHandler,
                  header: _vm.config.header,
                  "nav-links": true,
                  "next-day-threshold": _vm.config.nextDayThreshold,
                  now: _vm.now,
                  plugins: _vm.config.plugins,
                  "unselect-auto": false,
                  views: _vm.config.views,
                },
                on: {
                  eventMouseEnter: _vm.monthlyMouseEnter,
                  eventMouseLeave: _vm.monthlyMouseLeave,
                  dateClick: _vm.tooltipHide,
                  eventResizeStart: _vm.tooltipHide,
                  eventDragStart: _vm.tooltipHide,
                  viewDisplay: _vm.tooltipHide,
                  windowResize: function ($event) {
                    return _vm.mediaQueryHandler(true)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "calendar-modal-overlay" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal", attrs: { id: "modal" } }, [
      _c("div", { staticClass: "modal-events" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }