<template>
	<li class="cart">
		<a :href="checkoutUrl">
			<span v-if="getSize > 0"
			      class="cart-qty">({{ getSize }})</span>
			<span class="sr-only">Cart</span>
			<img :src="icon"
			     alt="cart"
			/>
		</a>
	</li>
</template>

<script>
    import Cookies from "js-cookie";

    export default {
        data() {
            return {
                icon: null,
                checkoutUrl: null
            };
        },
		created() {
		    // this.$store.dispatch("setupProduct");

		    (NYSBA.checkoutApi !== undefined) ? this.checkoutUrl = NYSBA.checkoutApi : null;
		    const c = document.cookie.match("(^|;) ?cart-id=([^;]*)(;|$)");
		    const cookie = c ? c[2] : null;
		    if (cookie !== null) {
		        this.checkoutUrl += cookie;
		    }
		},
	    beforeMount() {
            (ICONS.icons.cartIcon !== undefined) ? this.icon = ICONS.icons.cartIcon : null;
        },
        computed: {
            getSize() {
                return this.$store.state.cartData.totalSize;
            }
        },
        name: "nav-cart"
    };
</script>
<style scoped>
</style>
