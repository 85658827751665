var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checkered-section" },
    [
      _c("Section", {
        attrs: { sections: _vm.sections, loading: _vm.loading },
      }),
      _vm._v(" "),
      _c("Committees", {
        attrs: { committees: _vm.committees, loading: _vm.loading },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }