var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c(
      "a",
      { class: _vm.format.class, attrs: { href: _vm.format.permalink } },
      [_c("span", [_vm._v(_vm._s(_vm.format.formatName))])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }