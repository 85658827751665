<template>
	<div class="product-cnt">
		<h3 class="post-title"><a :href="post.permalink"><span class="strikethrough">{{ post.title }}</span></a></h3>
		<div class="excerpt">
			<div v-if="post.image"
			     class="thumb">
				<picture>
					<source v-if="post.image.webpSrcset"
					        :srcset="post.image.webpSrcset"
					        :sizes="post.image.sizes"
					        type="image/webp" />
					<source :srcset="post.image.srcset"
					        :sizes="post.image.sizes"
					        :type="post.image.type" />
					<img :src="post.image.src"
					     :content="post.image.src"
					     :alt="post.image.alt"
					     role="img"
					     property="v:image" />
				</picture>
			</div>
			<div class="content">
				<p v-if="post.excerpt">{{post.excerpt}}</p>
			</div>
		</div>
		<div class="info-rows">
      <!-- Date -->
			<div class="row info">
				<div v-if="post.date"
				     class="col-xs-3">
					<p class="label">Date</p>
					<p>{{ post.date }}</p>
				</div>
        <!-- Event Format -->
				<div v-if="post.eventFormat && Object.keys(post.eventFormat).length"
				     class="col-xs-3">
					<p class="label">Format</p>
					<p>
						<span v-for="(format) in post.eventFormat"
						      :key="format.termId">{{ format.termName }}</span>
					</p>
				</div>
        <!-- Region -->
				<div v-if="post.eventRegion && Object.keys(post.eventRegion).length"
				     class="col-xs-3">
					<p class="label">Location</p>
					<p>
						<span v-for="(region) in post.eventRegion"
						      :key="region.termId">{{ region.termName }}</span>
					</p>
				</div>
        <!-- Member Price -->
				<div v-if="post.memberPrice"
				     class="col-xs-3">
					<p class="label">NYSBA Member Price</p>
					<p>{{ post.memberPrice }}</p>
				</div>
                <div v-if="post.sectionMemberPrice && $resize && $mq.below(1025)"
                     class="col-xs-3">
                    <p class="label">Section Member Price</p>
                    <p>{{post.sectionMemberPrice}}</p>
                </div>
			</div>
			<div class="row info">
        <!-- Total Credits -->
				<div v-if="post.credits && post.credits.hasOwnProperty('totalCredits')"
				     class="col-xs-3">
					<p class="label">Total Credit{{post.credits.totalCredits.val > 1 ? "s" : null}}</p>
					<p>{{ post.credits.totalCredits.val }}</p>
				</div>
				<div v-if="post.practiceArea && Object.keys(post.practiceArea).length"
				     class="col-xs-3">
					<p class="label">Practice Area</p>
					<p class="practice-area">
						<span v-for="(pa) in post.practiceArea"
						      :key="pa.termId">{{ pa.termName }}</span>
					</p>
				</div>
				<div v-if="post.eventTypes && Object.keys(post.eventTypes).length"
					 class="col-xs-3">
					<p class="label">Type</p>
					<p class="practice-area">
						<span v-for="(pa) in post.eventTypes"
							  :key="pa.termId">{{ pa.termName }}</span>
					</p>
				</div>
                <div v-if="post.sectionMemberPrice && $resize && $mq.above(768)"
                     class="col-xs-3 section-member-price">
                    <p class="label">Section Member Price</p>
                    <p>{{post.sectionMemberPrice}}</p>
                </div>
			</div>
		</div>
		<div v-if="Object.keys(post.credits).length && post.credits.constructor === Object"
		     class="row center-xs credit-types">
			<div class="col-xs-12">
				<ul>
					<li v-for="({name, val}, index) in post.credits"
					    :key="index">{{name}}: <strong>{{val}}</strong></li>
				</ul>
				<a @click.prevent="expandCredits"
				   href="#"
				   class="toggle-btn blue"><span>Credit Types</span></a>
			</div>
		</div>
		<div class="row center-xs more-row">
			<div class="col-xs-12">
				<a :href="post.permalink"
				   class="line-btn blue"><span>More Details</span></a>
			</div>
		</div>
	</div>
</template>

<script>
    import "waypoints/lib/jquery.waypoints.min.js";

    export default {
        props: {
            post: [Object, Array]
        },
        methods: {
            expandCredits() {
                const elm = event.currentTarget;
                if ($(elm).hasClass("open")) $(elm).removeClass("open").prev().slideUp(400);
                else $(elm).addClass("open").prev().slideDown(400);
                Waypoint.refreshAll();
            }
        },
        name: "CLEPost"
    };
</script>

<style scoped lang="scss">
    .section-member-price {
        margin-left: auto;
    }
</style>
