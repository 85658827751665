var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "committee-roster col-xs-12" }, [
    _c("div", { staticClass: "loading committee-roster-loading" }),
    _vm._v(" "),
    _vm.posts
      ? _c(
          "div",
          [
            _c("RosterListing", { attrs: { roster: _vm.posts } }),
            _vm._v(" "),
            _vm.maxPage > 1
              ? _c("Pagination", {
                  attrs: {
                    "page-count": _vm.maxPage,
                    "page-range": 3,
                    "force-page": _vm.paged,
                    "margin-pages": 1,
                    "click-handler": _vm.gotoPage,
                    "prev-text": "",
                    "next-text": "",
                    "container-class": "pagination",
                    "page-link-class": "page-numbers",
                    "next-link-class": "next",
                    "prev-link-class": "prev",
                    "active-class": "current",
                    "break-view-link-class": "dots",
                    "no-li-surround": true,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }