<template>
	<section id="departments" class="the-leaders wrapper">

		<h2>Leadership</h2>

		<a href="#" class="tablet-open-filters"><img :src="icons.filterIcon" alt="Open Filters" /> Open Advanced Filters</a>

		<LeadershipFilter :leaderTypes="leaderTypes"
		                  :icons="icons"
		                  :search="filters.search"
		                  :loading="loading"
		                  @buildTypes="buildTypes"
		                  @buildAllTypes="buildAllTypes"
		                  @buildSearch="buildSearch"
		                  @resetSearch="resetAll" />

		<div class="line-header-container">
			<div class="line-header">
				<h2>Results ( Total {{results}} Items )</h2>
			</div>
		</div>

		<LeaderPostContainer :icons="icons"
		                     :leaders="leaders"
		                     :loading="loading" />

		<Pagination v-if="leaders.length && !loading && maxPages > 1"
		            :page-count="maxPages"
		            :page-range="3"
		            :force-page="currentPage"
		            :value="currentPage"
		            :margin-pages="1"
		            :click-handler="gotoPage"
		            :prev-text="''"
		            :next-text="''"
		            :container-class="'bottom-pagination pagination'"
		            :page-link-class="'page-numbers'"
		            :next-link-class="'next'"
		            :prev-link-class="'prev'"
		            :active-class="'current'"
		            :break-view-link-class="'dots'"
		            :no-li-surround="true"
		/>

	</section>

</template>

<script type="application/javascript">
    import {EventBus} from "../Util/event-bus.js";

    import LeadershipFilter from "./Leadership/LeadershipFilter.vue";
    import LeaderPostContainer from "./Leadership/LeaderPostContainer.vue";
    import LeaderModal from "./Leadership/LeaderModal.vue";
    import Pagination from "vuejs-paginate";
    import axios from "axios";


    export default {
        data() {
            return {
                currentPage: 1,
                filters: {
                    types: [],
                    search: ""
                },
                icons: {
                    close: "",
                    filterIcon: "",
                },
                leaders: [],
                leaderTypes: [],
                loading: true,
                maxPages: 0,
                results: 0,
            };
        },
        created() {
            (ICONS.filterIcon) ? this.icons.filterIcon = ICONS.filterIcon : null;
            (ICONS.close) ? this.icons.close = ICONS.close : null;
            this.getTaxonomies();

            EventBus.$on("leader-data", data => this.mountModal(data));

        },
        methods: {
            getTaxonomies() {
                const api = (LEADER.taxApi) ? LEADER.taxApi : "wp-json/committees/v1/leader-taxonomy";
                axios
                    .get(`${api}`)
                    .then(({data, status}) => {
                        if (status === 200) {
                            (data) ? this.leaderTypes = data : null;
                            return true;
                        }
                    })
                    .then(() => this.getLeaders())
                    .catch(err => console.log(err));

            },
            getLeaders(page, filters = false) {
                let config = {params: {}};
                const api = (LEADER.api) ? LEADER.api : "wp-json/committees/v1/leadership/";

                this.loading = true;
                this.currentPage = page || 1;
                filters ? config.params = this.filters : null;

                axios
                    .get(`${api + (page || 1)}`, config)
                    .then(({data, status}) => {
                        if (status === 200) {
                            (data.posts !== undefined) ? this.leaders = data.posts : null;
                            (data.foundPosts !== undefined) ? this.results = Number(data.foundPosts) : null;
                            (data.maxPages !== undefined) ? this.maxPages = Number(data.maxPages) : null;
                            return true;
                        }
                    })
                    .catch(err => console.log(err))
                    .then(() => this.loading = false);
            },
            gotoPage(pageNum) {
                if ((event !== undefined) && (event.currentTarget.classList.contains("page-numbers"))
                    || (event.currentTarget.classList.contains("next") || event.currentTarget.classList.contains("prev"))
                ) {
                    $("html, body").animate({scrollTop: $(".leaders-list").offset().top - 100}, 1000);
                }

                this.getLeaders(pageNum, true);
            },
            buildTypes(elm) {
                const typeId = elm.dataset.id;
                const typeIndex = this.filters.types.indexOf(typeId);

                if (typeIndex > -1) this.filters.types.splice(typeIndex, 1);
                else this.filters.types.push(typeId);

                this.getLeaders(1, true);

            },
            buildAllTypes() {
                this.filters.types = [];
                this.getLeaders(1, true);
            },
            buildSearch(elm) {
                if (elm.length) {
                    this.filters.search = elm;
                    this.getLeaders(1, true);
                }
            },
            mountModal(leader) {
                const leaderModal = Vue.extend(LeaderModal);

                const instance = new leaderModal({
                    propsData: {
                        id: leader.ID,
                        bio: leader.bio,
                        image: leader.image,
                        location: leader.location,
                        name: leader.title,
                        organization: leader.organization,
                        types: leader.types
                    }
                });

                instance.$mount();

                setTimeout(() => {
                    const modalContainer = document.getElementById("js-modal");
                    const modalInner = modalContainer.querySelector("[class=\"bio-modal__content\"]");
                    modalInner.appendChild(instance.$el);
                }, 200);
            },
            resetAll() {
                this.filters = {types: [], search: ""};

                // Remove the active class of all the category list items
                const catList = document.querySelector(".category-list");
                Array.prototype.forEach.call(catList.querySelectorAll("li"), (elm) => elm.classList.remove("active"));

                this.getLeaders(1);
            }
        },
        components: {LeadershipFilter, LeaderModal, LeaderPostContainer, Pagination},
        name: "LeadershipContainer",
    };
</script>

<style scoped>

</style>
