<template>
	<div class="advanced-search">
		<a href="#" class="tablet-open-filters">
			<img :src="icons.filterIcon"
				 alt="Open Filters" />
			Open Advanced Filters</a>

		<div class="advanced-search-form-container">

			<a href="#" class="close"><span>Close Filters</span>
				<img :src="icons.close"
					 alt="Close Filters" />
			</a>

			<form id="advanced-search-form" action="">

				<div class="row bottom-xs visible-advanced-search-filters">

					<div class="col-xs-3">
						<fieldset>
							<label class="advanced-search-label" for="advanced-search-format">{{typeTitle}}</label>
							<ul class="sidebar-filters">
								<li>
									<a href="#"><span class="text">{{firstType}} {{typeCount > 1 ? "+ " + typeCount : null }}</span>
										<span class="arrow"></span></a>
									<ul class="sub-filters">
										<li id="type-dropdown">
											<fieldset class="checkbox-field">
												<input @click="buildFilter('buildAllTypes', 'all-types')"
													   id="all-types"
													   type="checkbox"
													   name="All Types"
													   value="0"
												/>
												<label for="all-types">All Types</label>
											</fieldset>
											<fieldset v-for="({term_id, slug, name}) in types"
													  :key="term_id"
													  class="checkbox-field">
												<input @click="buildFilter('buildTypes', 'type')"
													   :id="slug"
													   type="checkbox"
													   :name="name"
													   :value="term_id"
													   :data-slug="slug"
													   :aria-labelledby="slug" />
												<label :for="slug">{{name}}</label>
											</fieldset>
										</li>
									</ul>
								</li>
							</ul>
						</fieldset>
					</div>

					<div class="col-xs-5 space">
						<fieldset>
							<label class="advanced-search-label" for="advanced-search-practice-area">Sort By</label>
							<ul class="sidebar-filters">
								<li>
									<a href="#"><span class="text">{{firstPa}}</span>
										<span class="arrow"></span></a>
									<ul class="sub-filters">
										<li id="pa-dropdown">
											<fieldset class="checkbox-field">
												<input @click="buildFilter('buildAllPas', 'all-pas')"
													   id="all-practice-areas"
													   type="checkbox"
													   name="Default Sorting"
													   value="0" />
												<label for="all-practice-areas">Default Sorting</label>
											</fieldset>
											<fieldset v-for="(term) in pas"
													  :key="term.sortC"
													  class="checkbox-field">
												<input @click="buildFilter('buildPas', 'pa')"
													   :id="term.sortC"
													   type="checkbox"
													   :name="term.sortT"
													   :value="term.sortC" />
												<label :for="term.sortC">{{term.sortT}}</label>
											</fieldset>
										</li>
									</ul>
								</li>
							</ul>
						</fieldset>
					</div>

					<div class="col-xs-3">
						<a @click.prevent="resetContent"
						   id="reset-btn"
						   role="button"
						   href="#"
						   class="curve-btn white"><span>Reset all filters</span></a>
					</div>

				</div>

			</form>
		</div>
	</div>
</template>

<script>
    export default {
        props: {
            types: [Object, Array],
            pas: [Object, Array],
            search: String,
            selectedTypes: [Array],
            selectedPas: String,
        },
        data() {
            return {
                typeTitle: null,
                paTitle: null,
                searchTitle: null,
                icons: {},
                firstType: "All Types",
                firstPa: "Sorting by",
                typeCount: null,
                paCount: null,
                searchText: "",
                filterStatusText: ""
            };
        },
        created() {
            if (typeof LISTING !== "undefined") {
                (LISTING.searchTypeTitle) ? this.typeTitle = LISTING.searchTypeTitle : null;
                (LISTING.searchPaTitle) ? this.paTitle = LISTING.searchPaTitle : null;
                (LISTING.searchBoxTitle) ? this.searchTitle = LISTING.searchBoxTitle : null;
            }

            if (typeof ICONS !== "undefined") {
                (ICONS.close) ? this.icons.close = ICONS.close : null;
                (ICONS.filterIcon) ? this.icons.filterIcon = ICONS.filterIcon : null;
            }
        },
        watch: {
            selectedTypes() {
                setTimeout(() => {
                    const elm = (event !== undefined) ? event.currentTarget : $("#type-dropdown input:checkbox:checked");

                    this.typeCount = elm.length;

                    if (elm.length > 1) {
                        for (let i = 0, len = elm.length; i < len; i++) {
                            this.mapFilter(elm[i]);
                        }
                    }
                    else this.mapFilter(elm[0]);
                }, 250)
            }
        },
        methods: {
            buildFilter(emitFunction, first = null) {
                const elm = event.currentTarget;
                this.$emit(emitFunction, event.currentTarget);

                /**
                 * This is now handled via a watch due to needing a param from the site
                 */
                if (first === "type") {
                }

                if (first === "pa") {
                    if (elm.checked === true) this.paCount++;
                    else (this.typeCount > 0) ? this.paCount-- : null;

                    if (this.paCount === 1 && elm.checked === true) this.firstPa = elm.name;

                    else if (this.paCount >= 1 && elm.checked === false)
                        this.firstPa = $("#pa-dropdown").find("input[value=\"" + Number(this.selectedPas) + "\"]")
                            .prop("name");
                    else (this.paCount === 0) ? this.firstPa = "Default Sorting" : null;
                    if (this.paCount > 1) {
                        if (elm.id == 'DESC') {
                            $("#ASC").prop('checked', false);
                            console.log(1);
                        } else if (elm.id == 'ASC') {
                            $("#DESC").prop('checked', false);
                            console.log(2);
                        }

                    } else {
                        this.firstPa = "Default Sorting"
                    }
                }

                if (first === "all-types") {
                    this.typeCount = 0;
                    this.firstType = "All Types";
                }

                if (first === "all-pas") {
                    this.paCount = 0;
                    this.firstPa = "Default Sorting";
                }

                this.statusText("Search filters updated!");
            },
            mapFilter(elm) {
                if (this.typeCount === 1 && elm.checked === true) this.firstType = elm.name;
                else if (this.typeCount >= 1 && elm.checked === false)
                    this.firstType = $("#type-dropdown")
                        .find("input[value=\"" + Number(this.selectedTypes[0]) + "\"]").prop("name");
                else if (this.typeCount === 0) this.firstType = "All Types";
            },
            resetContent() {
                this.firstType = "All Types";
                this.firstPa = "Default Sorting";
                this.typeCount = 0;
                this.paCount = 0;
                this.searchText = "";
                this.$emit("resetAll");
                this.statusText("Filters Reset");
            },
            statusText(text) {
                this.filterStatusText = text;
            }
        },
        name: "CommitteeSearch"
    };
</script>
