<template>
	<div class="row tablet-settings-container">
		<div class="col-xs-10 tablet-settings">
			<div class="tablet-settings-head">
				<div class="tablet-advanced-filters">
					<img :src="icons.filter"
					     alt="Advanced Filters Icons">
					<a href="#">Advanced Filters</a>
				</div>
				<a @click.prevent="search"
				   href="#"
				   id="advanced-submit-btn"
				   class="curve-btn blue"><span>Done</span></a>
			</div>
			<hr>

			<div class="row advanced-filters">
				<form id="advanced-filter-form" action="">
					<div aria-expanded="false" class="col-xs-12 advanced-filter dropdown">
						<div @click.prevent="toggleDropdown"
						     class="advanced-filter-title">Category
							<a class="dropdown-filter-toggle" href="#">
								<img :src="icons.filterPlus"
								     alt="Expand Categories">
							</a>
						</div>
						<div class="advanced-filter-dropdown-options">
							<div id="tablet-types"
							     class="control-group category cat-list">
								<fieldset class="checkbox-field">
									<input @click="buildTypes($event)"
									       type="checkbox"
									       id="all-tablet-types"
									       data-cat="0"
									       data-format-slug="all"
									       aria-labelledby="All Categories"
									       name="all-tablet-regions" />
									<label for="all-tablet-regions">All Categories</label>
								</fieldset>
								<fieldset v-for="({term_id, name, slug}) in types"
								          :key="term_id"
								          class="checkbox-field">
									<input @click="buildTypes($event)"
									       type="checkbox"
									       :id="`cat-${slug}`"
									       class="filter-cat other-types"
									       :data-cat="term_id"
									       :data-format-slug="slug"
									       :aria-labelledby="slug"
									       :name="name" />
									<label :for="slug"
									       v-html="name"></label>
								</fieldset>
							</div>
						</div>
					</div>

					<div aria-expanded="false" class="col-xs-12 advanced-filter dropdown">
						<div @click.prevent="toggleDropdown"
						     class="advanced-filter-title">Regions
							<a class="dropdown-filter-toggle" href="#">
								<img :src="icons.filterPlus"
								     alt="Expand Regions">
							</a>
						</div>
						<div class="advanced-filter-dropdown-options">
							<div id="tablet-regions" class="control-group regions region-list">
								<fieldset class="checkbox-field">
									<input @click="buildRegion($event)"
									       type="checkbox"
									       id="all-tablet-regions"
									       data-name="All Regions"
									       data-region-slug="all-tablet-regions"
									       aria-labelledby="All Regions"
									       name="all" />
									<label for="all-tablet-regions">All Regions</label>
								</fieldset>
								<fieldset v-for="({term_id, name, slug}) in regions"
								          :key="term_id"
								          class="checkbox-field">
									<input @click="buildRegion($event)"
									       type="checkbox"
									       :id="`region-${slug}`"
									       class="other-regions"
									       :data-region-slug="slug"
									       :data-term="term_id"
									       :aria-labelledby="slug"
									       :name="name" />
									<label :for="slug"
									       v-html="name"></label>
								</fieldset>
							</div>
						</div>
					</div>

					<div class="col-xs-12 advanced-filter">
						<div class="advanced-filter-title">Date
							<div class="advanced-input-container">
								<label class="hidden-label" for="tablet-date">Date</label>
								<DatePicker v-model="filter.date"
								            input-id="tablet-date"
								            type="date"
								            value-zone="local"
								            :value="filter.date"
								            :full-month-name="true"
								            :week-start="7"
								            @close="buildDate" />
							</div>
						</div>
					</div>

				</form>
				<div class="calendar-filter-buttons">
					<a @click.prevent="resetHandler"
					   id="advanced-reset-btn"
					   role="button"
					   href="#"
					   class="curve-btn white"><span>Reset all filters</span></a>
				</div>
			</div>

		</div>

		<div class="col-xs-2 tablet-settings-overlay"></div>
	</div>
</template>

<script type="application/javascript">
    import {Datetime} from "vue-datetime";
    import moment from "moment";
    import "vue-datetime/dist/vue-datetime.min.css";

    export default {
        props: {
            types: [Object, Array],
            regions: [Object, Array],
            icons: [Object, Array]
        },
        data() {
            return {
                filter: {
                    types: [],
                    regions: [],
                    date: ""
                }
            };
        },
        mounted() {
            $(".tablet-advanced-settings-toggle").on("click", () => {
                $(".tablet-calendar-settings-container, .tablet-settings-overlay").addClass("active");
                $(".main, body").addClass("no-scroll");
            });
        },
        methods: {
            toggleDropdown() {
                const el = $(event.currentTarget);
                const parent = el.parents(".dropdown");
                const sibling = el.next();

                if ($(sibling.is(":visible")))
                    parent.attr("aria-expanded", false);
                else
                    parent.attr("aria-expanded", true);

                sibling.slideToggle();
                el.find("img").toggleClass("active");
            },
            buildTypes(elm) {
                const el = elm.currentTarget;
                const typesContainer = document.querySelector("[id=\"tablet-types\"]");
                const allTypes = typesContainer.querySelector("[id=\"all-tablet-types\"]");
                const otherTypes = typesContainer.querySelectorAll("[class=\"other-types\"]");


                if (el.id === "all-tablet-types") {
                    this.filter.types = [];
                    [...otherTypes].map(type => type.checked = false);
                }
                else {
                    allTypes.checked = false;
                    const termId = el.dataset.cat;
                    if (el.checked === true) this.filter.types.push(termId);
                    else {
                        if (this.filter.types.indexOf(termId > -1))
                            this.filter.types.splice(this.filter.regions.indexOf(termId), 1);
                    }
                }

                this.$emit('searchTypes', elm)

            },
            buildRegion(elm) {
                const el = elm.currentTarget;
                const regionContainer = document.querySelector("[id=\"tablet-regions\"]");
                const allRegions = regionContainer.querySelector("[id=\"all-tablet-regions\"]");
                const otherRegions = regionContainer.querySelectorAll("[class=\"other-regions\"]");


                if (el.id === "all-tablet-regions") {
                    this.filter.regions = [];
                    [...otherRegions].map(region => region.checked = false);
                }
                else {
                    allRegions.checked = false;
                    const termId = el.dataset.term;
                    if (el.checked === true) {
                        this.filter.regions.push(termId);
                    }
                    else {
                        if (this.filter.regions.indexOf(termId > -1))
                            this.filter.regions.splice(this.filter.regions.indexOf(termId), 1);
                    }
                }

                this.$emit("searchRegions", el);
            },
            buildDate() {
                if (this.filter.date.length > 0) {
                    this.filter.date = moment(this.filter.date).format('YYYY-MM-DD');
                    this.$emit("dateSearch", this.filter);
                }
            },
            search() {
                $(".tablet-calendar-settings-container, .tablet-settings-overlay").removeClass("active");
                $(".main, body").removeClass("no-scroll");

                /**
                 * Send our data to the parent for page filtering
                 */
                // this.$emit('search', this.filter);
            },
            resetHandler() {
                this.firstRegion = "All Regions";
                this.regionCount = 0;
                this.filter.date = "";

                /**
                 * Uncheck checkboxes and toggle up any dropdowns
                 */
                document.querySelectorAll(".checkbox-field input").forEach(elm => elm.checked = false);
                // document.querySelectorAll(".sub-filters").forEach(elm => $(elm).slideUp(400));
                document.querySelectorAll(".advanced-filter").forEach(elm => $(elm).removeClass("active"));

                this.$emit("searchReset");
            },
        },
	    watch: {
            selectedRegions(newRegions) {
                this.regionCount = newRegions.length;
                const regionContainer = document.querySelector("[id=\"tablet-regions\"]");
                const otherRegions = regionContainer.querySelectorAll("input:checked");
                if (otherRegions.length) this.firstRegion = otherRegions[0].name;
            },
	    },
        components: {
            DatePicker: Datetime
        },
        name: "TabletFilters"
    };
</script>

<style scoped>

</style>
