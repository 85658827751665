var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "result-item" }, [
    _c(
      "a",
      { staticClass: "mobile-only", attrs: { href: _vm.post.permalink } },
      [_vm._v(_vm._s(_vm.post.title))]
    ),
    _vm._v(" "),
    _c("ul", { staticClass: "top" }, [
      _vm.post.type ? _c("li", [_vm._v(_vm._s(_vm.post.type))]) : _vm._e(),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: _vm.post.permalink } }, [
          _vm._v(_vm._s(_vm.post.title)),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "bottom" }, [
      _vm.post.pa ? _c("li", [_vm._v(_vm._s(_vm.post.pa))]) : _vm._e(),
      _vm._v(" "),
      _vm.post.hostGroup
        ? _c("li", [_vm._v(_vm._s(_vm.post.hostGroup))])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }