<template>
	<li class="reveal">
		<a :href="post.permalink">{{post.title}}<img
			:src="post.image" alt="Right Arrow"/></a>
	</li>
</template>

<script>
    export default {
        props: {
            post: [Object]
        },
        name: "Result"
    };
</script>
