<template>
	<div :key="envId"
	     class="single-event fc-div">
		<a @click="submitId"
		   class="fc-more single-more">{{text}}</a>
	</div>
</template>

<script type="application/javascript">
    import {EventBus} from "../../../Util/event-bus.js";
    export default {
        props: {
            text: String,
            envId: [String, Number]
        },
	    methods: {
          submitId() {
              EventBus.$emit("modal-event-id", this.envId);
		    }
	    },
        name: "SingleEventLink"
    };
</script>

<style scoped>

</style>
