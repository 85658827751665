<template>
    <a :href="link !== undefined ? link : null"
       :aria-label="`Share to ${title}`"
       target="_blank">
        <img :src="icon"
             :alt="title + ' Icon'"
             class="svg" />
    </a>
</template>

<script type="application/javascript">
    export default {
        props: {
            link: String,
            icon: String,
            title: String,
        },
        name: "SocialMediaLinkIcon"
    };
</script>

<style scoped>

</style>
