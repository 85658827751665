import { render, staticRenderFns } from "./SingleEventModal.vue?vue&type=template&id=e6c07c6e&scoped=true&"
import script from "./SingleEventModal.vue?vue&type=script&lang=js&"
export * from "./SingleEventModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6c07c6e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.nysba.cliquedomains.com/web/app/themes/nysba/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e6c07c6e')) {
      api.createRecord('e6c07c6e', component.options)
    } else {
      api.reload('e6c07c6e', component.options)
    }
    module.hot.accept("./SingleEventModal.vue?vue&type=template&id=e6c07c6e&scoped=true&", function () {
      api.rerender('e6c07c6e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/scripts/Vue/Components/Calendar/Monthly/SingleEventModal.vue"
export default component.exports