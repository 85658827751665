<template>
	<table class="zebra-table desktop-cart-results-table" cellpadding="0" cellspacing="0" width="100%">
		<thead>
		<tr>
			<th width="50%">Title</th>
			<th width="25%">Product Type</th>
			<th width="12.5%">Quantity</th>
			<th width="12.5%">Price</th>
		</tr>
		</thead>
		<tbody v-if="(typeof contents)">
		<CartItem v-for="(item, index) in contents"
		          :key="index"
		          :index="index"
		          :item="item"
		          :icons="icons"
		/>
		</tbody>
	</table>
</template>

<script>
    import CartItem from "./CartTableItem.vue";

    export default {
        props: {
            contents: Object,
            icons: Object
        },
        components: {CartItem},
        name: "CartTable"
    };
</script>
