var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "calendar-keys" }, [
      _c("ul", [
        _c("li", { staticClass: "no-events" }, [_vm._v("Not available")]),
        _vm._v(" "),
        _c("li", { staticClass: "searched-event" }, [_vm._v("Searched event")]),
        _vm._v(" "),
        _c("li", { staticClass: "today-event" }, [_vm._v("Today")]),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v("Tap each date to see full list of programs")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }