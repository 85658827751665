<template>
    <div :class="`loading ${loading ? 'is-loading' : ''}`"></div>
</template>

<script type="application/javascript">
    export default {
        props: {
            loading: Boolean,
        },
        name: "Loading"
    };
</script>

<style lang="scss" scoped>

    .is-loading {
        display: block !important;
    }
    
</style>
