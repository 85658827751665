<template>
	<div class="row">
		<div class="col-xs-12">
			<h3 v-html="text"></h3>
		</div>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            text: String
        },
        name: "NoEvents"
    };
</script>

<style scoped>

</style>
