var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tabSections
    ? _c(
        "div",
        { staticClass: "general-tabbed" },
        [
          _c("p", { staticClass: "label" }, [_vm._v("Display")]),
          _vm._v(" "),
          _vm.titles
            ? _c("TabsHeader", {
                attrs: { titles: _vm.titles, icon: _vm.icon.title },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("TabbedSection", { attrs: { data: _vm.tabSections } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }