var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("p", [
    _vm.fastcaseUrl
      ? _c(
          "a",
          {
            staticClass: "curve-btn blue",
            attrs: { href: _vm.fastcaseUrl, target: "_blank" },
          },
          [_c("span", [_vm._v("Access Fastcase")])]
        )
      : _c(
          "span",
          {
            staticClass: "curve-btn blue disabled",
            attrs: { "aria-disabled": "true" },
          },
          [_c("span", [_vm._v("Access Fastcase")])]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }