<template>
	<form id="sticky-cart-form" action="">
		<fieldset v-if="$root.isLoggedIn === 'true'">
			<label>{{ text.title }}</label>
		</fieldset>
		<div v-if="$root.isLoggedIn === 'true'"
		     class="cart-checkout-buttons">
			<a :href="api"
			   target="_blank"
			   class="curve-btn blue">
				<span>{{ text.btnText }}</span>
			</a>
		</div>

		<div v-if="$root.isLoggedIn === 'false'">
			<LoginBtn :text="loginBtnText"/>
		</div>
        
        <div v-if="footnoteText"
             class="footnote-content"
             v-html="footnoteText"></div>
        
	</form>
</template>

<script>
    import LoginBtn from "./LoginBtn.vue";
    export default {
        data() {
            return {
                // img: null,
                text: null,
                api: null,
	            loginBtnText: 'Register Here',
                footnoteText: null,
            };
        },
        beforeMount() {
            this.text = {};
            (EVENT.title) ? this.text.title = EVENT.title : null;
            (EVENT.btnText) ? this.text.btnText = EVENT.btnText : null;
	        (EVENT.loginBtnText) ? this.loginBtnText = EVENT.loginBtnText : null;
	        (EVENT.footnoteText) ? this.footnoteText = EVENT.footnoteText : null;

            this.api = EVENT.checkout;
        },
        components: {
            LoginBtn
        },
        name: "event-qty"
    };
</script>

<style scoped>

</style>
