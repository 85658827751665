<template>
	<div :id="`modal-bio-${id}`">
		<div id="js-modal-content">
			<div class="overflow">
				<div class="row">
					<div class="col-xs-5">
						<div class="photo">
							<picture v-if="image">
								<source v-if="image.webpSrcset"
								        :srcset="image.webpSrcset"
								        :sizes="image.sizes"
								        type="image/webp" />
								<source :srcset="image.srcset"
								        :sizes="image.sizes"
								        :type="image.type" />
								<img :src="image.src"
								     :content="image.src"
								     :alt="image.alt"
								     role="img"
								     property="v:image" />
							</picture>
						</div>
					</div>
					<div class="col-xs-7">
						<!-- Loop through all the taxonomy terms-->
						<p v-for="({name, slug, termId}) in types"
						   :key="termId"
						   :class="`title-tag ${slug}`"
						   v-text="name"></p>
						<p class="officer-name"
						   v-text="name"></p >
						<p class="title"
						   v-text="organization"></p>
						<div v-if="bio"
						     class="bio"
						     v-html="bio"></div>
						<p v-if="location"
						   class="location"
						   v-text="location"></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            id: Number,
            bio: String,
            image: [Array, Object],
            location: String,
            name: String,
            organization: String,
            types: [Array, Object]
        },
        name: "LeaderModal"
    };
</script>
