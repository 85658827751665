<template>
	<div :class="`post-listing ${!!loading ? postListingLoadingClass : ''}`">
		<div :class="`loading ${!!loading ? loadingElmClass : ''}`"></div>
		<div v-if="noPosts && loading === false"
		     class="no-results">
			<p>Sorry, no results found.</p>
		</div>
		<Product v-if="posts.length > 0 && loading === false"
		         v-for="(post) in posts"
		         :post="post"
		         :key="post.id" />
	</div>
</template>

<script type="application/javascript">
    import Product from "./ProductListing.vue";

    export default {
        props: {
            posts: [Object, Array],
            loading: Boolean,
        },
        data() {
            return {
                postListingLoadingClass: "is-loading",
                loadingElmClass: "show-loading",
	            noPosts: false,
            };
        },
        watch: {
            posts(newPosts) {
                this.noPosts = newPosts.length === 0;
            },
            loading(loadingChange) {
                const loading = $(".loading");
                if (loadingChange) loading.show();
                else loading.hide();
            }
        },
        components: {Product},
        name: "ListContainer"
    };
</script>

<style lang="scss" scoped>
	.show-loading {
		display: block !important;
	}
</style>
