<template>
	<div class="checkered-section">
		<div class="checkered-item-container">
			<div class="loading posts-loading"></div>
			<div v-if="posts"
			     class="row">
				<Posts v-for="({title, link, category, id}) in posts"
				       :key="id"
				       :title="title"
				       :link="link"
				       :category="category" />
			</div>
			<NoResults v-else-if="!loading && maxPages === 0"
			           :no-results-text="noResultsText" />
			<div v-if="posts && maxPages > 1">
				<Pagination :page-count="maxPages"
				          :page-range="3"
				          :force-page="currentPage"
				          :value="currentPage"
				          :margin-pages="1"
				          :click-handler="gotoPage"
				          :prev-text="''"
				          :next-text="''"
				          :container-class="'bottom-pagination pagination'"
				          :page-link-class="'page-numbers'"
				          :next-link-class="'next'"
				          :prev-link-class="'prev'"
				          :active-class="'current'"
				          :break-view-link-class="'dots'"
				          :no-li-surround="true"
				/>

			</div>
		</div>
	</div>
</template>

<script type="application/javascript">
	import Posts from "./Posts.vue"
	import Pagination from "vuejs-paginate";
	import NoResults from "../../NoResults.vue";

    export default {
	    props: {
	        posts: [Array, Object],
		    maxPages: Number,
		    currentPage: Number,
            loading: Boolean
	    },
	    data() {
	        return {
	            noResultsText: ''
	        }
	    },
        mounted() {
	        (MYNYSBA.tabs.notFoundText.posts.length) ? this.noResultsText = MYNYSBA.tabs.notFoundText.posts : null;
            const loading = $(".posts-loading");
            this.loading ? loading.show() : loading.hide();
        },
	    methods: {
            gotoPage(pageNum) {
                if ((event !== undefined) && (event.currentTarget.classList.contains("page-numbers") || event.currentTarget.classList.contains("next") || event.currentTarget.classList.contains("prev"))) {
                    $("html, body").animate({scrollTop: $("#section2").offset().top}, 1000);
                }

                this.$parent.$emit('goToPostsPage', pageNum);
            }
	    },
        watch: {
            loading(loadingChange) {
                const loading = $(".posts-loading");
                if (loadingChange) loading.show();
                else loading.hide();
            }
        },
	    components: {NoResults, Pagination, Posts},
        name: "PostsContainer"
    };
</script>

<style lang="scss" scoped>
	.checkered-item-container {
		position: relative;
	}
</style>
