var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tooltip" }, [
    _vm.cats
      ? _c(
          "div",
          { staticClass: "category-container" },
          _vm._l(_vm.cats, function ({ name, id, slug }) {
            return _c("div", { key: id, class: `category-${slug}` }, [
              _vm._v(_vm._s(name)),
            ])
          }),
          0
        )
      : _vm._e(),
    _vm._v("\n\t" + _vm._s(_vm.title) + "\n\t"),
    _c("p", { staticClass: "small-text" }, [_vm._v(_vm._s(_vm.region))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }