var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-cnt" }, [
    _c("h3", { staticClass: "post-title" }, [
      _c("a", { attrs: { href: _vm.post.permalink } }, [
        _c("span", { staticClass: "strikethrough" }, [
          _vm._v(_vm._s(_vm.post.title)),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "excerpt" }, [
      _vm.post.image
        ? _c("div", { staticClass: "thumb" }, [
            _c("picture", [
              _vm.post.image.webpSrcset
                ? _c("source", {
                    attrs: {
                      srcset: _vm.post.image.webpSrcset,
                      sizes: _vm.post.image.sizes,
                      type: "image/webp",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("source", {
                attrs: {
                  srcset: _vm.post.image.srcset,
                  sizes: _vm.post.image.sizes,
                  type: _vm.post.image.type,
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  src: _vm.post.image.src,
                  content: _vm.post.image.src,
                  alt: _vm.post.image.alt,
                  role: "img",
                  property: "v:image",
                },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("p", [_vm._v(_vm._s(_vm.post.excerpt))]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "info-rows" }, [
      _c("div", { staticClass: "row" }, [
        _vm.post.publicationDate && Object.keys(_vm.post.publicationDate.length)
          ? _c("div", { staticClass: "col-xs-6 col-xl-4 info" }, [
              _c("p", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.post.publicationTitle)),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.post.publicationDate))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.post.practiceArea && Object.keys(_vm.post.practiceArea).length
          ? _c("div", { staticClass: "col-xs-6 col-xl-4 info" }, [
              _c("p", { staticClass: "label" }, [
                _vm._v(
                  "Practice Area" +
                    _vm._s(_vm.post.practiceArea.length > 1 ? "s" : null)
                ),
              ]),
              _vm._v(" "),
              _c(
                "p",
                _vm._l(_vm.post.practiceArea, function ({ termId, termName }) {
                  return _c("span", {
                    key: termId,
                    domProps: { innerHTML: _vm._s(termName) },
                  })
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.post.memberPrice
          ? _c("div", { staticClass: "col-xs-6 col-xl-4 info" }, [
              _c("p", { staticClass: "label" }, [_vm._v("Member Price")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.post.memberPrice))]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm.post.productType && Object.keys(_vm.post.productType).length
          ? _c("div", { staticClass: "col-xs-6 col-xl-4 info" }, [
              _c("p", { staticClass: "label" }, [
                _vm._v(
                  "Available Format" +
                    _vm._s(_vm.post.productType.length > 1 ? "s" : null)
                ),
              ]),
              _vm._v(" "),
              _c(
                "p",
                _vm._l(_vm.post.productType, function ({ termId, termName }) {
                  return _c("span", {
                    key: termId,
                    domProps: { innerHTML: _vm._s(termName) },
                  })
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.post.credits && Object.keys(_vm.post.credits).length
          ? _c("div", { staticClass: "col-xs-6 col-xl-4 info" }, [
              _c("p", { staticClass: "label" }, [
                _vm._v(
                  "Credit Type" +
                    _vm._s(_vm.post.credits.length > 1 ? "s" : null)
                ),
              ]),
              _vm._v(" "),
              _c(
                "p",
                _vm._l(_vm.post.credits, function ({ name }, index) {
                  return _c("span", {
                    key: index,
                    domProps: { innerHTML: _vm._s(name) },
                  })
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.post.sectMemberPrice && Object.keys(_vm.post.sectMemberPrice).length
          ? _c("div", { staticClass: "col-xs-6 col-xl-4 info" }, [
              _c("p", { staticClass: "label" }, [
                _vm._v("Section Member Price"),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.post.sectMemberPrice))]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xs-6 col-xl-4" }),
        _vm._v(" "),
        _vm.post.totalCredits
          ? _c("div", { staticClass: "col-xs-6 col-xl-4 info" }, [
              _c("p", { staticClass: "label" }, [
                _vm._v(
                  "Total Credit" +
                    _vm._s(_vm.post.totalCredits.val > 1 ? "s" : null)
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.post.totalCredits.val))]),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _vm.post.children && Object.keys(_vm.post.children).length > 0
      ? _c("div", { staticClass: "row center-xs product-types" }, [
          _c("div", { staticClass: "col-xs-12" }, [
            _c(
              "div",
              { staticClass: "accordion-items" },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm._l(
                  _vm.post.children,
                  function (
                    { id, title, permalink, price, productType },
                    index
                  ) {
                    return _c(
                      "div",
                      {
                        key: id,
                        staticClass: "row col-xs-12 text-left product-child",
                      },
                      [
                        _c("div", { staticClass: "col-md-7 child" }, [
                          _c("a", { attrs: { href: permalink } }, [
                            _vm._v(_vm._s(title)),
                          ]),
                          _vm._v(":"),
                        ]),
                        _vm._v(" "),
                        _vm._l(productType, function (type) {
                          return _c("div", { staticClass: "col-md-3 child" }, [
                            _vm._v(_vm._s(type) + "\n            "),
                          ])
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-2 child" }, [
                          _vm._v(_vm._s(price)),
                        ]),
                      ],
                      2
                    )
                  }
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "toggle-btn blue",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.expandAccordion($event)
                  },
                },
              },
              [_c("span", [_vm._v("Products")])]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row center-xs more-row" }, [
      _c("div", { staticClass: "col-xs-12" }, [
        _c(
          "a",
          { staticClass: "line-btn blue", attrs: { href: _vm.post.permalink } },
          [_c("span", [_vm._v("More Details")])]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row col-xs-12 text-left product-title" }, [
      _c("div", { staticClass: "col-md-7 title" }, [
        _vm._v("Related Products"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-3 title" }, [_vm._v("Format")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-2 title" }, [_vm._v("Member Price")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }