<template>
    <button :aria-label="`Print`"
            @click="printElm">
        <img :src="icon"
             :alt="title + ' Icon'"
             class="svg" />
    </button>
</template>

<script type="application/javascript">
    
    import mixins from "../../Util/mixins.js";

    export default {
        props: {
            icon: String,
            title: String,
        },
        data () {
            return {
                output: null
            }
        },
        mixins: [mixins],
        name: "PrintLink"
    };
</script>

<style scoped>
</style>
