<template>
	<div class="row leaders-list">
		<div :class="`loading ${loading ? 'is-loading' : ''}`"></div>
		<LeaderPost v-if="leaders.length && !loading"
		            v-for="(leader) in leaders"
		            :key="leader.ID"
		            :leader="leader"
		            :icons="icons" />
		<div v-else-if="!leaders.length && !loading">
			No Posts
		</div>
	</div>
</template>

<script type="application/javascript">
	import LeaderPost from "./LeaderPost.vue";

	export default {
	    props: {
		    icons: [Array, Object],
	        leaders: [Array, Object],
		    loading: Boolean,
	    },
        // watch: {
        //     loading(loadingChange) {
        //         const loading = $(".leaders-loading");
        //         if (loadingChange) loading.show();
        //         else loading.hide();
        //     }
        // },
        components: {LeaderPost},
        name: "LeaderPostContainer"
    };
</script>

<style lang="scss" scoped>
	.leaders-list {
		position: relative;
		min-height: 25vh;
		.is-loading {
			display: block!important;
		}
	}
</style>
