var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkered-section" }, [
    _c(
      "div",
      { staticClass: "checkered-item-container" },
      [
        _c("div", { staticClass: "loading posts-loading" }),
        _vm._v(" "),
        _vm.posts
          ? _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.posts, function ({ title, link, category, id }) {
                return _c("Posts", {
                  key: id,
                  attrs: { title: title, link: link, category: category },
                })
              }),
              1
            )
          : !_vm.loading && _vm.maxPages === 0
          ? _c("NoResults", { attrs: { "no-results-text": _vm.noResultsText } })
          : _vm._e(),
        _vm._v(" "),
        _vm.posts && _vm.maxPages > 1
          ? _c(
              "div",
              [
                _c("Pagination", {
                  attrs: {
                    "page-count": _vm.maxPages,
                    "page-range": 3,
                    "force-page": _vm.currentPage,
                    value: _vm.currentPage,
                    "margin-pages": 1,
                    "click-handler": _vm.gotoPage,
                    "prev-text": "",
                    "next-text": "",
                    "container-class": "bottom-pagination pagination",
                    "page-link-class": "page-numbers",
                    "next-link-class": "next",
                    "prev-link-class": "prev",
                    "active-class": "current",
                    "break-view-link-class": "dots",
                    "no-li-surround": true,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }