<template>
    <picture v-if="!isObjEmpty(image)">
        <source v-if="image.webp"
                :srcset="image.webp"
                :sizes="image.sizes"
                type="image/webp" />
        <source :srcset="image.srcset"
                :sizes="image.sizes"
                :type="image.type" />
        <img :src="image.src"
             class="img-inner"
             :content="image.src"
             :alt="image.alt"
             role="img"
             property="v:image" />
    </picture>
</template>

<script type="application/javascript">
    import mixins from "../../Util/mixins.js";

    export default {
        props: {
            image: [Array, Object]
        },
        mixins: [mixins],
        name: "PostImage.vue"
    };
</script>

<style scoped>

</style>
