var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.maxPages > 1
    ? _c("Pagination", {
        attrs: {
          "page-count": _vm.maxPages,
          "page-range": 3,
          "force-page": _vm.currentPage,
          value: _vm.currentPage,
          "margin-pages": 1,
          "click-handler": _vm.triggerPageChange,
          "prev-text": "",
          "next-text": "",
          "container-class": "bottom-pagination pagination",
          "page-link-class": "page-numbers",
          "next-link-class": "next",
          "prev-link-class": "prev",
          "active-class": "current",
          "break-view-link-class": "dots",
          "no-li-surround": true,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }