<template>
	<!-- jdroese - removed pagination / revised to drop downs for month view change -->
	<div class="row calendar-month-view-nav">
		<div class="col-xs-4">
			<fieldset>
				<label class="calendar-filter-label" for="calendar-month-display">Choose Month</label>
				<select name="practice-area-dropdown" id="calendar-month-display" class="calendar-filter-search-dropdown" aria-invalid="false">
					<option value="disabled">Select</option>
					<option value="0">January</option>
					<option value="1">February</option>
					<option value="2">March</option>
					<option value="3">April</option>
					<option value="4">May</option>
					<option value="5">June</option>
					<option value="6">July</option>
					<option value="7">August</option>
					<option value="8">September</option>
					<option value="9">October</option>
					<option value="10">November</option>
					<option value="11">December</option>
				</select>
			</fieldset>
		</div>
		<div class="col-xs-4">
			<fieldset>
				<label class="calendar-filter-label" for="calendar-year-display">Choose Year</label>
				<select name="practice-area-dropdown" id="calendar-year-display" class="calendar-filter-search-dropdown" aria-invalid="false">
					<option value="disabled">Select</option>
					<option value="2020">2020</option>
					<option value="2021">2021</option>
					<option value="2022">2022</option>
					<option value="2023">2023</option>
					<option value="2024">2024</option>
				</select>
			</fieldset>
		</div>
		<div class="col-xs-4 calendar-filter-buttons">
			<a id="submit-btn" role="button" href="#" class="curve-btn blue"><span>View</span></a>
		</div>
	</div>
</template>

<script type="application/javascript">
    export default {
        name: "Pagniation"
    };
</script>

<style scoped>

</style>
