var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `events-list ${
        !_vm.showEvents.length && !_vm.isLoading ? "no-events" : ""
      }`,
      attrs: { id: "future-events-list" },
    },
    [
      _c("h2", { domProps: { textContent: _vm._s(_vm.title) } }),
      _vm._v(" "),
      _c("div", { class: `loading ${_vm.isLoading ? "is-loading" : ""}` }),
      _vm._v(" "),
      _vm._l(
        _vm.showEvents,
        function ({
          date,
          excerpt,
          id,
          image,
          region,
          registerLink,
          permalink,
          title,
        }) {
          return _vm.showEvents
            ? _c("Event", {
                key: id,
                attrs: {
                  date: date,
                  excerpt: excerpt,
                  image: image,
                  "link-text": "Register Now",
                  "register-link": registerLink,
                  region: region,
                  permalink: permalink,
                  title: title,
                },
              })
            : _vm._e()
        }
      ),
      _vm._v(" "),
      !_vm.events.length
        ? _c("NoResults", {
            attrs: { "no-results-text": `Sorry there are no past events` },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && _vm.maxPages > 1
        ? _c("Paginate", {
            attrs: {
              "page-count": _vm.maxPages,
              "page-range": 3,
              "force-page": _vm.currentPage,
              value: _vm.currentPage,
              "margin-pages": 1,
              "click-handler": _vm.gotoPage,
              "prev-text": "",
              "next-text": "",
              "container-class": "bottom-pagination pagination",
              "page-link-class": "page-numbers",
              "next-link-class": "next",
              "prev-link-class": "prev",
              "active-class": "current",
              "break-view-link-class": "dots",
              "no-li-surround": true,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }