var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$resize && _vm.$mq.above(1025)
    ? _c("form", { attrs: { id: "sticky-cart-form", action: "" } }, [
        _vm.$root.isLoggedIn === "true"
          ? _c("fieldset", [
              _c("label", { attrs: { for: "cart-quantity" } }, [
                _vm._v(_vm._s(_vm.qtyText)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "plus-minus-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "minus",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.decrement.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.minusIcon,
                        alt: "Subtract One Quantity",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "number",
                    id: "cart-quantity",
                    name: "cart-quantity",
                    "aria-labelledby": "cart-quantity",
                  },
                  domProps: { value: _vm.$root.qty },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "plus",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.increment.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.plusIcon, alt: "Add One Quantity" },
                    }),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$root.isLoggedIn === "true"
          ? _c("div", { staticClass: "cart-checkout-buttons" }, [
              _vm.$root.checkoutUrl
                ? _c(
                    "a",
                    {
                      staticClass: "curve-btn blue",
                      attrs: { href: _vm.$root.checkoutUrl },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.checkoutText))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "curve-btn white",
                  attrs: { href: _vm.$root.qty > 0 ? _vm.$root.api : null },
                },
                [_c("span", [_vm._v(_vm._s(_vm.addToCartText))])]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$root.isLoggedIn === "true"
          ? _c("div", [
              _vm.$root.checkoutUrl
                ? _c(
                    "a",
                    {
                      staticClass: "line-btn blue",
                      attrs: { href: _vm.$root.checkoutUrl },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.viewCartText))])]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.$root.isLoggedIn === "false"
          ? _c(
              "div",
              [_c("LoginBtn", { attrs: { text: _vm.loginBtnText } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.footnoteText
          ? _c("div", {
              staticClass: "footnote-content",
              domProps: { innerHTML: _vm._s(_vm.footnoteText) },
            })
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }