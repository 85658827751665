var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.futureEvents.length
        ? _c("FutureEvents", { attrs: { events: _vm.futureEvents } })
        : _vm._e(),
      _vm._v(" "),
      _vm.pastEvents.length
        ? _c("PastEvents", { attrs: { events: _vm.pastEvents } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }