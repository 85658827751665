var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cart-checkout-buttons" }, [
    _vm.$root.loginUrl
      ? _c(
          "a",
          {
            staticClass: "curve-btn blue",
            attrs: { href: _vm.$root.loginUrl },
          },
          [_c("span", [_vm._v(_vm._s(_vm.text))])]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }