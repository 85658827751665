<template>
	<div class="cart-footer">
		<div class="cart-total-items-container">
			<!-- Leaving uncalculated until dev. BE may be hooking this.  -->
			Total (<span class="cart-total-items">{{ size }}</span> item{{ size > 1 ? "s" : null}}): $
			<span class="cart-total-items-price">{{ total }}</span>
		</div>

		<a href="#" class="curve-btn blue"><span>Purchase</span></a>
	</div>
</template>

<script>
    export default {
        props: {
            size: Number,
            total: Number,
        },
        name: "CartTotals"
    };
</script>
