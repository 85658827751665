var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product-cnt" }, [
    _c("h3", { staticClass: "post-title" }, [
      _c("a", { attrs: { href: _vm.post.permalink } }, [
        _c("span", { staticClass: "strikethrough" }, [
          _vm._v(_vm._s(_vm.post.title)),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "excerpt" }, [
      _vm.post.image
        ? _c("div", { staticClass: "thumb" }, [
            _c("picture", [
              _vm.post.image.webpSrcset
                ? _c("source", {
                    attrs: {
                      srcset: _vm.post.image.webpSrcset,
                      sizes: _vm.post.image.sizes,
                      type: "image/webp",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("source", {
                attrs: {
                  srcset: _vm.post.image.srcset,
                  sizes: _vm.post.image.sizes,
                  type: _vm.post.image.type,
                },
              }),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  src: _vm.post.image.src,
                  content: _vm.post.image.src,
                  alt: _vm.post.image.alt,
                  role: "img",
                  property: "v:image",
                },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _vm.post.excerpt
          ? _c("p", [_vm._v(_vm._s(_vm.post.excerpt))])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "info-rows" }, [
      _c("div", { staticClass: "row info" }, [
        _vm.post.date
          ? _c("div", { staticClass: "col-xs-3" }, [
              _c("p", { staticClass: "label" }, [_vm._v("Date")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.post.date))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.post.eventFormat && Object.keys(_vm.post.eventFormat).length
          ? _c("div", { staticClass: "col-xs-3" }, [
              _c("p", { staticClass: "label" }, [_vm._v("Format")]),
              _vm._v(" "),
              _c(
                "p",
                _vm._l(_vm.post.eventFormat, function (format) {
                  return _c("span", { key: format.termId }, [
                    _vm._v(_vm._s(format.termName)),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.post.eventRegion && Object.keys(_vm.post.eventRegion).length
          ? _c("div", { staticClass: "col-xs-3" }, [
              _c("p", { staticClass: "label" }, [_vm._v("Location")]),
              _vm._v(" "),
              _c(
                "p",
                _vm._l(_vm.post.eventRegion, function (region) {
                  return _c("span", { key: region.termId }, [
                    _vm._v(_vm._s(region.termName)),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.post.memberPrice
          ? _c("div", { staticClass: "col-xs-3" }, [
              _c("p", { staticClass: "label" }, [_vm._v("NYSBA Member Price")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.post.memberPrice))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.post.sectionMemberPrice && _vm.$resize && _vm.$mq.below(1025)
          ? _c("div", { staticClass: "col-xs-3" }, [
              _c("p", { staticClass: "label" }, [
                _vm._v("Section Member Price"),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.post.sectionMemberPrice))]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row info" }, [
        _vm.post.credits && _vm.post.credits.hasOwnProperty("totalCredits")
          ? _c("div", { staticClass: "col-xs-3" }, [
              _c("p", { staticClass: "label" }, [
                _vm._v(
                  "Total Credit" +
                    _vm._s(_vm.post.credits.totalCredits.val > 1 ? "s" : null)
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.post.credits.totalCredits.val))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.post.practiceArea && Object.keys(_vm.post.practiceArea).length
          ? _c("div", { staticClass: "col-xs-3" }, [
              _c("p", { staticClass: "label" }, [_vm._v("Practice Area")]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "practice-area" },
                _vm._l(_vm.post.practiceArea, function (pa) {
                  return _c("span", { key: pa.termId }, [
                    _vm._v(_vm._s(pa.termName)),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.post.eventTypes && Object.keys(_vm.post.eventTypes).length
          ? _c("div", { staticClass: "col-xs-3" }, [
              _c("p", { staticClass: "label" }, [_vm._v("Type")]),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "practice-area" },
                _vm._l(_vm.post.eventTypes, function (pa) {
                  return _c("span", { key: pa.termId }, [
                    _vm._v(_vm._s(pa.termName)),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.post.sectionMemberPrice && _vm.$resize && _vm.$mq.above(768)
          ? _c("div", { staticClass: "col-xs-3 section-member-price" }, [
              _c("p", { staticClass: "label" }, [
                _vm._v("Section Member Price"),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.post.sectionMemberPrice))]),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    Object.keys(_vm.post.credits).length &&
    _vm.post.credits.constructor === Object
      ? _c("div", { staticClass: "row center-xs credit-types" }, [
          _c("div", { staticClass: "col-xs-12" }, [
            _c(
              "ul",
              _vm._l(_vm.post.credits, function ({ name, val }, index) {
                return _c("li", { key: index }, [
                  _vm._v(_vm._s(name) + ": "),
                  _c("strong", [_vm._v(_vm._s(val))]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "toggle-btn blue",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.expandCredits.apply(null, arguments)
                  },
                },
              },
              [_c("span", [_vm._v("Credit Types")])]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row center-xs more-row" }, [
      _c("div", { staticClass: "col-xs-12" }, [
        _c(
          "a",
          { staticClass: "line-btn blue", attrs: { href: _vm.post.permalink } },
          [_c("span", [_vm._v("More Details")])]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }