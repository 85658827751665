<template>
	<div id="my-committees">
		<div class="line-header-container">
			<div class="line-header">
				<h2 v-text="blockTitle"></h2>
			</div>
		</div>

		<div class="checkered-item-container">
			<div class="loading committees-loading"></div>
			<div v-if="committees && !loading"
			     class="row">
				<div v-for="({title, link, practiceArea}) in committees"
				     class="col-xs-4">
					<div class="checkered-item">
						<p class="label"
						   v-text="practiceArea"></p>
						<a :href="link"
						   v-text="title"></a>
					</div>
				</div>
			</div>
			<NoResults v-else-if="!loading"
			           :no-results-text="notFoundText" />
		</div>
	</div>
</template>

<script type="application/javascript">
	import NoResults from "../../NoResults.vue";
    export default {
        props: {
            committees: [Array, Object],
            loading: Boolean
        },
        data() {
            return {
                blockTitle: "",
                notFoundText: ""
            };
        },
        created() {
            (MYNYSBA.tabs.committeeTitles.committee) ? this.blockTitle = MYNYSBA.tabs.committeeTitles.committee : null;
            (MYNYSBA.tabs.notFoundText.committee) ? this.notFoundText = MYNYSBA.tabs.notFoundText.committee : null;
        },
        mounted() {
            const loading = $(".committees-loading");
            this.loading ? loading.show() : loading.hide();
        },
        watch: {
            loading(loadingChange) {
                const loading = $(".committees-loading");
                if (loadingChange) loading.show();
                else loading.hide();
            }
        },
	    components: {NoResults},
        name: "Committees"
    };
</script>

<style lang="scss" scoped>
	.checkered-item-container {
		position: relative;
	}
</style>
