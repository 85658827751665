<template>
	<li>
		<a :href="format.permalink" :class="format.class"><span>{{format.formatName}}</span></a>
	</li>
</template>

<script type="application/javascript">
    export default {
        props : {format: [Object, Array]},
        name: "ProductFormat"
    };
</script>
