import { render, staticRenderFns } from "./Event.vue?vue&type=template&id=854bf8a6&scoped=true&"
import script from "./Event.vue?vue&type=script&lang=js&"
export * from "./Event.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "854bf8a6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.nysba.cliquedomains.com/web/app/themes/nysba/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('854bf8a6')) {
      api.createRecord('854bf8a6', component.options)
    } else {
      api.reload('854bf8a6', component.options)
    }
    module.hot.accept("./Event.vue?vue&type=template&id=854bf8a6&scoped=true&", function () {
      api.rerender('854bf8a6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/scripts/Vue/Components/Committee-Detail/Events/Event.vue"
export default component.exports