<template>
    <div class="swiper-container" id="news-swiper">
        <div class="swiper-wrapper">
            <!-- Slide -->
            <div v-for="({category, date, image, permalink, title }, index) in posts"
                 class="programs-slider__slide swiper-slide"
                 :key="index">
                <NewsPost :category="category"
                          :date="date"
                          :image="image"
                          :permalink="permalink"
                          :title="title" />
            </div>
        </div>
        <div class="swiper-button-prev" id="news-button-prev"><span class="prev-text">Previous</span></div>
        <div class="swiper-pagination" id="news-swiper-pagination"></div>
        <div class="swiper-button-next" id="news-button-next"><span class="next-text">Next</span></div>
    </div>
</template>

<script type="application/javascript">
    import Swiper from "swiper";

    import NewsPost from "../Blocks/NewsPost.vue";

    export default {
        props: {
            posts: [Array, Object],
        },
        mounted() {
            const newsSwiper = new Swiper("#news-swiper", {
                spaceBetween: 0,
                slidesPerView: 3,
                loop: true,
                pagination: {
                    el: "#news-swiper-pagination",
                },
                navigation: {
                    nextEl: "#news-button-next",
                    prevEl: "#news-button-prev",
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 1
                    },
                }
            });
        },
        components: {NewsPost},
        name: "NewsSlider"
    };
</script>

<style scoped>

</style>
