<template>
	<div class="row tablet-search-results">
		<div v-if="posts"
		     class="col-xs-12">
			<Result v-for="(post) in posts"
			        :key="post.id"
			        :post="post" />
		</div>
	</div>
</template>

<script>
    import Result from "./CommitteeResultTablet.vue";

    export default {
        props: {
            posts: [Object, Array]
        },
        components: {Result},
        name: "CommitteeResultsTablet"
    };
</script>
