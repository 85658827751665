export default {
    methods: {
        isObjEmpty(obj) {
            return Object.keys(obj).length === 0;
        },
        objHasKey(obj, key) {
            return obj.hasOwnProperty(key);
        },
        printElm() {
            let elmId;
            if (document.getElementById('content')) {
                elmId = 'content';
            } else if (document.getElementById('detail-content')) {
                elmId = 'detail-content';
            }
            if (elmId) {
                this.print(elmId);
            }
        },
        async print (elmId) {
            // Pass the element id here
            await this.$htmlToPaper(elmId);
        }
    }
};
