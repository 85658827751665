var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-xs-12" }, [
      _c("h3", { domProps: { innerHTML: _vm._s(_vm.text) } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }