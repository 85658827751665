var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-xs-3" }, [
    _c("div", { staticClass: "leader-cnt" }, [
      _c("div", { staticClass: "photo-cnt" }, [
        _c("div", { staticClass: "overlay" }, [
          _c(
            "a",
            {
              staticClass: "js-modal line-btn white",
              attrs: {
                href: "#",
                "data-modal-prefix-class": "bio",
                "data-modal-text": "",
                "data-modal-title": "",
                "data-modal-close-img": _vm.icons.searchClose,
                "data-modal-content-id": `modal-bio-${_vm.leader.ID}`,
                "data-modal-close-text": "Close",
                "data-modal-close-title": "Close this window",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.openModal($event)
                },
              },
            },
            [
              _c("span", {
                domProps: { textContent: _vm._s(_vm.readMoreText) },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "photo" }, [
          _c(
            "div",
            { staticClass: "tags" },
            _vm._l(_vm.leader.types, function ({ name, termId, slug }) {
              return _vm.leader.types
                ? _c("div", {
                    key: termId,
                    class: `title-tag ${slug}`,
                    domProps: { textContent: _vm._s(name) },
                  })
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _vm.leader.image
            ? _c("picture", [
                _vm.leader.image.webpSrcset
                  ? _c("source", {
                      attrs: {
                        srcset: _vm.leader.image.webpSrcset,
                        sizes: _vm.leader.image.sizes,
                        type: "image/webp",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("source", {
                  attrs: {
                    srcset: _vm.leader.image.srcset,
                    sizes: _vm.leader.image.sizes,
                    type: _vm.leader.image.type,
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: _vm.leader.image.src,
                    content: _vm.leader.image.src,
                    alt: _vm.leader.image.alt,
                    role: "img",
                    property: "v:image",
                  },
                }),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("p", {
        staticClass: "name",
        domProps: { textContent: _vm._s(_vm.leader.title) },
      }),
      _vm._v(" "),
      _c("p", {
        staticClass: "title",
        domProps: { textContent: _vm._s(_vm.leader.organization) },
      }),
      _vm._v(" "),
      _vm.leader.typeText
        ? _c("div", { staticClass: "title-container" }, [
            _c(
              "p",
              { staticClass: "title" },
              _vm._l(_vm.leader.typeText, function (type, index) {
                return _c("span", {
                  key: index,
                  domProps: { textContent: _vm._s(type) },
                })
              }),
              0
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }