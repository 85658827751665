var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "dashboard-button-content", attrs: { id: "section0" } },
      [
        _c("h2", { staticClass: "section-title sr-only" }, [
          _vm._v("dashboard"),
        ]),
        _vm._v(" "),
        _c("CommitteeBlock", {
          attrs: { posts: _vm.committeePosts, loading: _vm.isLoading },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "calendar-button-content", attrs: { id: "section1" } },
      [
        _c("h2", { staticClass: "section-title sr-only" }, [
          _vm._v("Calendar"),
        ]),
        _vm._v(" "),
        _c("CalendarBlock", {
          attrs: {
            "event-categories": _vm.eventCategories,
            "events-registered": _vm.eventsRegistered,
            loading: _vm.isLoading,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "favorite-button-content", attrs: { id: "section2" } },
      [
        _c("h2", { staticClass: "section-title sr-only" }, [
          _vm._v("Favorite"),
        ]),
        _vm._v(" "),
        _c("PostsBlock", {
          attrs: {
            posts: _vm.newsPosts,
            cats: _vm.newsCategories,
            loading: _vm.isLoading,
            "max-pages": _vm.numOfPostPages,
            "current-page": _vm.currentPostPage,
          },
          on: {
            buildCats: _vm.buildCats,
            buildAllCats: _vm.buildAllCats,
            buildSearch: _vm.buildSearch,
            goToPostsPage: _vm.goToPostsPage,
            resetPosts: _vm.resetPosts,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }