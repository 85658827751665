<template>
	<div class="row tablet-settings-container">
		<div class="col-xs-10 tablet-settings">
			<div class="tablet-settings-head">
				<div class="tablet-advanced-filters">
					<img :src="icons.filter"
					     alt="Advanced Filters Icons">
					<a href="#">Advanced Filters</a>
				</div>
				<a @click.prevent="search"
				   href="#"
				   id="advanced-submit-btn"
				   class="curve-btn blue"><span>Done</span></a>
			</div>
			<hr>

			<div class="row advanced-filters">
				<form id="advanced-filter-form" action="">
					<div aria-expanded="false" class="col-xs-12 advanced-filter dropdown">
						<div @click.prevent="toggleDropdown"
						     class="advanced-filter-title">Category
							<a class="dropdown-filter-toggle" href="#">
								<img :src="icons.filterPlus"
								     alt="Expand Categories">
							</a>
						</div>
						<div class="advanced-filter-dropdown-options">
							<div class="control-group category">
								<fieldset
									@click="buildTypes($event)"
									class="checkbox-field category-everything"
									data-cat="0"
									data-name="all-tablet-events">
									<input
										type="checkbox"
										id="all-tablet-cats"
										aria-labelledby="All Categories" />
									<label for="all-tablet-cats">All Categories</label>
								</fieldset>
								<fieldset v-for="({term_id, name, slug}) in types"
								          :key="term_id"
								          @click="buildTypes($event)"
								          :class="`checkbox-field category-${slug}`"
								          :data-cat="term_id"
								          :data-name="name">
									<input
										type="checkbox"
										:id="`category-${slug}`"
										:aria-labelledby="slug" />
									<label :for="slug"
									       v-html="name"></label>
								</fieldset>
							</div>
						</div>
					</div>

				</form>
				<div class="calendar-filter-buttons">
					<a @click.prevent="reset"
						id="advanced-reset-btn"
						class="curve-btn white"
						role="button"
						href="#"><span>Reset all filters</span></a>
				</div>
			</div>

		</div>

		<div class="col-xs-2 tablet-settings-overlay"></div>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            types: [Array, Object],
            icons: [Array, Object]
        },
        mounted() {
            $(".tablet-advanced-settings-toggle").on("click", () => {
                $(".tablet-calendar-settings-container, .tablet-settings-overlay").addClass("active");
                $(".main, body").addClass("no-scroll");
            });
        },
        methods: {
            toggleDropdown() {
                const el = $(event.currentTarget);
                const parent = el.parents(".dropdown");
                const sibling = el.next();

                if ($(sibling.is(":visible")))
                    parent.attr("aria-expanded", false);
                else
                    parent.attr("aria-expanded", true);

                sibling.slideToggle();
                el.find("img").toggleClass("active");
            },
            buildTypes(elm) {
                const el = elm.currentTarget;
                const parentNode = el.parentNode;
                const filterItems = parentNode.querySelectorAll("[class^=\"checkbox-field\"]");
                const filterAll = parentNode.querySelector("[data-name=\"all-tablet-events\"]");
                // const name = el.dataset.name;
                // const cat = el.dataset.cat;

                console.log(el.classList);

                if (!el.classList.contains('active')) el.classList.add('active');
                else (el.dataset.name !== 'all-tablet-events') ? el.classList.remove('active') : null;

                if (el.dataset.name === 'all-tablet-events') [...filterItems].map(item => item.classList.remove('active'));
                else filterAll.classList.remove('active');

                this.$emit("eventSearchFilter", el);

            },
            search() {
                $(".tablet-calendar-settings-container, .tablet-settings-overlay").removeClass("active");
                $(".main, body").removeClass("no-scroll");
            },
	        reset() {
                /**
                 * Uncheck checkboxes and toggle up any dropdowns
                 */
                document.querySelectorAll(".checkbox-field input").forEach(elm => elm.checked = false);
                document.querySelectorAll(".advanced-filter").forEach(elm => $(elm).removeClass("active"));

                this.$emit("searchReset");
	        }
        },
        name: "EventTabletFilters"
    };
</script>

<style scoped>

</style>
