<template>
    <div :class="`${titleClass} section-title`">
        <h2 v-text="title" />
    </div>
</template>

<script type="application/javascript">
    export default {
        props: {
            title: String,
            titleClass: String,
        },
        name: "BlockTitle"
    };
</script>

<style scoped>

</style>
