var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "helpful-links-slider__container" }, [
    _c(
      "div",
      { staticClass: "swiper-container", attrs: { id: "swiper-posts-block" } },
      [
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          _vm._l(_vm.posts, function (post, index) {
            return _c("div", { key: index, staticClass: "swiper-slide" }, [
              _c(
                "div",
                { staticClass: "helpful-links-slider__slide" },
                [
                  _c("Post", {
                    attrs: {
                      "post-class": _vm.postClass,
                      credits: post.credits,
                      date: post.date,
                      format: post.format,
                      image: post.image,
                      permalink: post.permalink,
                      "start-time": post.startTime,
                      title: post.title,
                    },
                  }),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "helpful-links-slider__nav" }, [
      _c(
        "div",
        {
          staticClass: "nav-prev",
          attrs: {
            id: "posts-block-prev",
            tabindex: "0",
            role: "button",
            "aria-label": "Previous slide",
          },
        },
        [
          _c("span", { staticClass: "arrow" }, [_vm._v("❮")]),
          _vm._v(" "),
          _c("span", { staticClass: "copy" }, [_vm._v("Previous")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "nav-pagination swiper-pagination-bullets",
          attrs: { id: "posts-block-pagination" },
        },
        [
          _c("span", {
            staticClass:
              "swiper-pagination-bullet swiper-pagination-bullet-active",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "nav-next",
          attrs: {
            id: "posts-block-next",
            tabindex: "0",
            role: "button",
            "aria-label": "Next slide",
          },
        },
        [
          _c("span", { staticClass: "copy" }, [_vm._v("Next")]),
          _vm._v(" "),
          _c("span", { staticClass: "arrow" }, [_vm._v("❯")]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }