<template>
	<div class="favorite-filters-container">
		<form>
			<div class="row visible-advanced-search-filters">
				<div v-if="cats"
				     class="col-xs-3">
					<fieldset>
						<label class="advanced-search-label" for="advanced-search-category">Category</label>
						<ul class="sidebar-filters">
							<li>
								<a href="#"><span class="text" v-text="countText"></span><span class="arrow"></span></a>
								<ul class="sub-filters">
									<li id="cat-dropdown">
										<fieldset class="checkbox-field">
											<input @click="buildFilter($event,'buildAllCats', 'all-categories')"
											       id="all-categories"
											       type="checkbox"
											       name="All"
											       value="0"
											       data-slug="all-categories"
											       aria-labelledby="all-categories" />
											<label for="all-categories">All</label>
										</fieldset>
										<fieldset v-for="({cat_id, slug, title}) in cats"
										          :key="cat_id"
										          class="checkbox-field">
											<input @click="buildFilter($event,'buildCats', 'cat')"
											       class="populated-input"
											       :id="slug"
											       type="checkbox"
											       :name="title"
											       :value="cat_id"
											       :data-slug="slug"
											       :aria-labelledby="slug" />
											<label :for="slug" v-text="title"></label>
										</fieldset>
									</li>
								</ul>
							</li>
						</ul>
					</fieldset>
				</div>
				<div class="col-xs-4 space">
					<fieldset>
						<label class="advanced-search-label" for="advanced-search-keyword">Keyword</label>
						<input v-model="searchText"
						       v-on:keypress.enter.prevent="$parent.$emit('buildSearch', searchText)"
						       id="advanced-search-keyword"
						       type="text"
						       placeholder="Type here">
					</fieldset>
				</div>
				<div class="col-xs-5">
					<div class="advance-search-buttons">
						<a @click.prevent="$parent.$emit('buildSearch', searchText)"
						   id="submit-btn"
						   class="curve-btn blue"
						   href="#"
						   role="button"><span>Search</span></a>
						<a @click.prevent="resetContent"
						   id="reset-btn"
						   class="curve-btn white"
						   href="#"
						   role="button"><span>Reset all filters</span></a>
						<span v-if="filterStatusText"
						      class="search-filters-update-text"
						      v-text="filterStatusText"></span>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            cats: [Array, Object],
	        catsSelected: [Array, Object]
        },
        data() {
            return {
                first: "All",
                catCount: 0,
                searchText: "",
                filterStatusText: ""
            };
        },
        methods: {
           buildFilter(elm, emitFunction, first = "") {
                const elmTarget = elm.target;
                const checkBoxes = document.getElementById("cat-dropdown");
                this.$parent.$emit(emitFunction, elmTarget);

                if (first === "cat") {
                    // Add the aria-selected attribute and incrememnt catCount
                    if (elmTarget.checked === true) {
                        this.catCount++;
                        elmTarget.setAttribute("aria-selected", true);
                    }
                    // Remove the aria-selected attribute and decrememnt catCount, but stop at 0
                    else if (elmTarget.checked === false) {
                        (this.catCount > 0) ? this.catCount-- : null;
                        elmTarget.removeAttribute("aria-selected");
                    }

                    // If it's the first non-all item checked, we'll update the Select field to make it this
                    if (this.catCount === 1 && elmTarget.checked === true) this.first = elmTarget.name;
                    // Otherwise, if there are others selected, determined by catCount, we'll grab the first selected item from the DOM
                    else if (this.catCount >= 1 && elmTarget.checked === false) {
                        const selectedItems = checkBoxes.querySelectorAll("[aria-selected]");
                        this.first = selectedItems[0].name;
                    }
                    // Lastly, we'll just set "All" input as the master
                    else (this.catCount === 0) ? this.first = "All" : null;

                }
                // Our conditional for all-categories
                else if (first === "all-categories") {
                    this.catCount = 0;
                    this.first = "All";
                }

                this.statusText("Search filters updated!");
            },
            resetContent() {
                this.first = "All";
                this.catCount = 0;
                this.searchText = "";
                this.filterStatusText = "";
                this.$parent.$emit("resetPosts");
                this.statusText("Filters Reset");
            },
            statusText(text) {
                this.filterStatusText = text;
            }
        },
	    computed: {
            countText() {
                let count = '';
                this.catCount > 1 ? count = ` + ${this.catCount}` : null;
                return this.first + count;
            }
	    },
        watch: {
            filterStatusText() {
                setTimeout(() => this.filterStatusText = "", 2000);
            },
        },
        name: "PostsFilter"
    };
</script>

<style scoped>

</style>
