var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", { attrs: { id: "sticky-cart-form", action: "" } }, [
    _vm.$root.isLoggedIn === "true"
      ? _c("fieldset", [_c("label", [_vm._v(_vm._s(_vm.text.title))])])
      : _vm._e(),
    _vm._v(" "),
    _vm.$root.isLoggedIn === "true"
      ? _c("div", { staticClass: "cart-checkout-buttons" }, [
          _c(
            "a",
            {
              staticClass: "curve-btn blue",
              attrs: { href: _vm.api, target: "_blank" },
            },
            [_c("span", [_vm._v(_vm._s(_vm.text.btnText))])]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$root.isLoggedIn === "false"
      ? _c("div", [_c("LoginBtn", { attrs: { text: _vm.loginBtnText } })], 1)
      : _vm._e(),
    _vm._v(" "),
    _vm.footnoteText
      ? _c("div", {
          staticClass: "footnote-content",
          domProps: { innerHTML: _vm._s(_vm.footnoteText) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }