<template>
	<div>
		<div v-if="activeEvents > 0 && !isLoading"
		     class="events-wrapper">
			<SingleEvent v-if="events.length"
                         v-for="event in events"
			             :key="event.id"
			             :event="event"
			             :is-visible="!!(moment(event.start).isSameOrBefore(activeDate) && moment(event.end).isSameOrAfter(activeDate))"
			             :show-date="activeDate" />
			<NoEvents v-if="!events.length"
			          :text="noEventsText" />
		</div>
		<div v-if="!events.length && !isLoading">
			<div class="events-wrapper no-events">
				<NoEvents :text="noEventsText" />
			</div>
		</div>
	</div>
</template>

<script type="application/javascript">
    import SingleEvent from "./SingleEvent.vue";
    import NoEvents from "./NoEvents.vue";
    import moment from "moment";

    export default {
        props: {
            activeDate: String,
            activeEvents: Number,
            events: [Object, Array],
            isLoading: Boolean,
            visibleChildren: Number
        },
        data() {
            return {
                noEventsText: CAL.noEventsText,
	            moment: moment
            };
        },
        components: {
            SingleEvent,
            NoEvents,
        },
        name: "EventsWeek"
    };
</script>

<style scoped>

</style>
