var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "reveal" }, [
    _c("a", { attrs: { href: _vm.post.permalink } }, [
      _vm._v(_vm._s(_vm.post.title)),
      _c("img", { attrs: { src: _vm.post.image, alt: "Right Arrow" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }