html<template>
    <div :class="`post col-xs-12 ${postClass || ''}`">
        <div v-if="image && !isObjEmpty(image)"
             class="post-image">
            <PostImage :image="image" />
        </div>
        <div class="post-content">
            <div class="post-meta">
                <p v-if="date"
                   class="post-meta-item post-date"
                   v-text="date" />
                <p v-if="date && startTime"
                   class="post-separator"
                   v-html="`&blacksquare;`" />
                <p v-if="startTime"
                   class="post-meta-item post-start-time"
                   v-text="startTime" />
                <p v-if="(date || startTime) && format"
                   class="post-separator"
                   v-html="`&blacksquare;`" />
                <p v-if="format"
                   :class="`post-meta-item post-format`"
                   v-text="`${format}`" />
                <p v-if="(date || startTime || format) && credits"
                   :class="checkIfLast(date, startTime, credits, format)"
                   v-html="`&blacksquare;`" />
                <p v-if="credits"
                   :class="`post-meta-item post-credits${date && startTime && credits ? ' last-item' : ''}`"
                   v-text="credits" />
            </div>
            <div class="post-title">
                <h3>
                    <a :href="permalink"
                       class="post-link"
                       v-text="title" />
                </h3>
            </div>
        </div>
    </div>
</template>

<script type="application/javascript">
    import PostImage from "./PostImage.vue";
    import mixins from "../../Util/mixins.js";

    export default {
        props: {
            credits: [Number, String],
            date: String,
            format: String,
            image: [Array, Object],
            permalink: String,
            postClass: String,
            startTime: String,
            title: String,
        },
        methods: {
            checkIfLast(date, startTime, credits, format) {
                if (date && startTime && credits && format) {
                    return 'post-separator last-separator'
                }
                return 'post-separator'
            }
        },
        mixins: [mixins],
        name: "Post",
        components: {PostImage}
    };
</script>

<style scoped>

</style>
