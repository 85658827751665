var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "div",
        {
          class: `event ${_vm.event.className.join(" ")}`,
          attrs: { id: _vm.date, "data-date": _vm.date },
        },
        [
          _c("div", { staticClass: "row" }, [
            _vm.event.image.length !== 0
              ? _c("div", { staticClass: "col-xs-5" }, [
                  _c(
                    "div",
                    { staticClass: "thumb" },
                    [
                      _vm._l(_vm.event.categoryName, function (cat) {
                        return _c("div", {
                          key: cat.id,
                          class: `event-category category-${cat.slug}`,
                          domProps: { textContent: _vm._s(cat.name) },
                        })
                      }),
                      _vm._v(" "),
                      _c("picture", [
                        _vm.event.image.webpSrcset
                          ? _c("source", {
                              attrs: {
                                srcset: _vm.event.image.webpSrcset,
                                type: "image/webp",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            srcset: _vm.event.image.srcset,
                            type: _vm.event.image.type,
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            role: "img",
                            src: _vm.event.image.src,
                            alt: _vm.event.image.alt,
                          },
                        }),
                      ]),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: _vm.event.image.length !== 0 ? "col-xs-7" : "col-xs-12",
              },
              [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _vm._l(_vm.event.categoryName, function (cat) {
                      return _c("div", {
                        key: cat.id,
                        class: `event-category mobile-cat category-${cat.slug}`,
                        domProps: { textContent: _vm._s(cat.name) },
                      })
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "date" }, [
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.eventDate()) },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "title" }, [
                      _c("a", {
                        attrs: { href: _vm.event.url },
                        domProps: { textContent: _vm._s(_vm.event.title) },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "excerpt",
                      domProps: { textContent: _vm._s(_vm.event.excerpt) },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "renew-btn blue",
                        attrs: { href: _vm.event.url },
                      },
                      [_c("span", [_vm._v("Read More")])]
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }