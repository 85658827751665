var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `post-listing ${!!_vm.loading ? _vm.postListingLoadingClass : ""}`,
    },
    [
      _c("div", {
        class: `loading ${!!_vm.loading ? _vm.loadingElmClass : ""}`,
      }),
      _vm._v(" "),
      _vm.noPosts && _vm.loading === false
        ? _c("div", { staticClass: "no-results" }, [
            _c("p", [_vm._v("Sorry, no results found.")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.posts, function (post) {
        return _vm.posts.length > 0 && _vm.loading === false
          ? _c("EventPost", { key: post.id, attrs: { post: post } })
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }