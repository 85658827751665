<template>
	<div class="row leaders-filter">

		<a href="#" class="close"><span>Close Filters</span> <img :src='icons.close' alt="Close Filters" /></a>

		<div v-if="leaderTypes.length"
		     class="col-xs-12">
			<div class="category-list">
				<ul>
					<li :class="`all active`"
					    id="all"
					    data-slug="all"
					    data-id="0"
					    @click.prevent="leaderTypeSelected($event)">
						<a href="#">All</a>
					</li>
					<li v-for="({name, slug, term_id}) in leaderTypes"
					    :key="term_id"
					    :class="`leader-type ${slug}`"
					    :data-slug="slug"
					    :data-id="term_id"
					    @click.prevent="leaderTypeSelected($event)">
						<a href="#" v-text="name"></a>
					</li>
				</ul>
			</div>
		</div>

		<div class="col-xs-4">
			<fieldset>
				<label for="leaders-keyword">Keyword</label>
				<input v-model="searchText"
				       v-on:keypress.enter="$emit('buildSearch', searchText)"
				       :disabled="loading"
				       :aria-disabled="loading"
				       id="leaders-keyword" type="text" placeholder="Type here">
			</fieldset>
		</div>

		<div class="col-xs-4">
			<div class="advance-search-buttons">
				<a @click.prevent="$emit('buildSearch', searchText)"
				   id="submit-btn"
				   :class="`curve-btn blue`"
				   role="button"
				   href="#"><span>Search</span></a>
				<a @click.prevent="$emit('resetSearch')"
				   id="reset-btn"
				   :class="`curve-btn white`"
				   role="button"
				   href="#"><span>Reset all filters</span></a>
			</div>
		</div>

	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            icons: [Array, Object],
            leaderTypes: [Array, Object],
	        loading: Boolean,
	        search: String
        },
	    data() {
            return {
                searchText: "",
            }
	    },
        methods: {
            leaderTypeSelected(elm) {
                const catList = document.querySelector('.category-list');
	            const elmTarget = (elm.target.nodeName === 'A') ? elm.target.parentNode : elm.target;
                let emitTarget = 'buildTypes';

                /**
                 * Need to stop user from clicking right away
                 */
                if (!elmTarget.classList.contains('disabled')) {

                    if (elmTarget.classList.contains('active')) elmTarget.classList.remove('active');
                    else elmTarget.classList.add('active');

                    if (elmTarget.dataset.slug === 'all') {
                        emitTarget = 'buildAllTypes';
                        const otherCats = catList.querySelectorAll('.leader-type');
                        Array.prototype.forEach.call(otherCats, (elm) => elm.classList.remove('active'))
                    }
                    else {
                        catList.querySelector('#all').classList.remove('active');
                    }

                    this.$emit(emitTarget, elmTarget);
                }

            }
	    },
	    watch: {
            search(newText) {
                this.searchText = newText;
            }
	    },
        name: "LeadershipFilter"
    };
</script>

<style lang="scss" scoped>
	li {
		&.disabled {
			filter: grayscale(0.5);
		}
	}
	.officers {
		padding: 0;
	}
	.disabled,
	.disabled a{
		cursor: not-allowed!important;
	}
</style>
