<template>
	<div class="tooltip">
		<!-- For loop -->
		<div v-if="cats"
			 class="category-container">
			<div v-for="({name, id, slug}) in cats"
			     :key="id"
			     :class="`category-${slug}`">{{name}}</div>
		</div>
		{{title}}
		<p class="small-text">{{region}}</p>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            title: [String],
			region: [String],
	        cats: [Object, Array]
        },
        name: "EventPopup"
    };
</script>

<style scoped>

</style>
