import { render, staticRenderFns } from "./LeadershipFilter.vue?vue&type=template&id=a56e63fc&scoped=true&"
import script from "./LeadershipFilter.vue?vue&type=script&lang=js&"
export * from "./LeadershipFilter.vue?vue&type=script&lang=js&"
import style0 from "./LeadershipFilter.vue?vue&type=style&index=0&id=a56e63fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a56e63fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.nysba.cliquedomains.com/web/app/themes/nysba/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a56e63fc')) {
      api.createRecord('a56e63fc', component.options)
    } else {
      api.reload('a56e63fc', component.options)
    }
    module.hot.accept("./LeadershipFilter.vue?vue&type=template&id=a56e63fc&scoped=true&", function () {
      api.rerender('a56e63fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/scripts/Vue/Components/Leadership/LeadershipFilter.vue"
export default component.exports