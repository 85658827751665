var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row leaders-list" },
    [
      _c("div", { class: `loading ${_vm.loading ? "is-loading" : ""}` }),
      _vm._v(" "),
      _vm._l(_vm.leaders, function (leader) {
        return _vm.leaders.length && !_vm.loading
          ? _c("LeaderPost", {
              key: leader.ID,
              attrs: { leader: leader, icons: _vm.icons },
            })
          : !_vm.leaders.length && !_vm.loading
          ? _c("div", [_vm._v("\n\t\tNo Posts\n\t")])
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }