var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.activeEvents > 0 && !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "events-wrapper" },
          [
            _vm._l(_vm.events, function (event) {
              return _vm.events.length
                ? _c("SingleEvent", {
                    key: event.id,
                    attrs: {
                      event: event,
                      "is-visible": !!(
                        _vm
                          .moment(event.start)
                          .isSameOrBefore(_vm.activeDate) &&
                        _vm.moment(event.end).isSameOrAfter(_vm.activeDate)
                      ),
                      "show-date": _vm.activeDate,
                    },
                  })
                : _vm._e()
            }),
            _vm._v(" "),
            !_vm.events.length
              ? _c("NoEvents", { attrs: { text: _vm.noEventsText } })
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.events.length && !_vm.isLoading
      ? _c("div", [
          _c(
            "div",
            { staticClass: "events-wrapper no-events" },
            [_c("NoEvents", { attrs: { text: _vm.noEventsText } })],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }