var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-wrapper" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm.titles
      ? _c(
          "ul",
          { attrs: { role: "tablist" } },
          _vm._l(_vm.titles, function (title, index) {
            return _c("li", { key: index, attrs: { role: "presentation" } }, [
              _c(
                "a",
                {
                  staticClass: "tab-header",
                  attrs: {
                    href: `#section${index}`,
                    id: `tab-header${index}`,
                    tabindex: "-1",
                    "aria-selected": `${index === 0}`,
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clickToggleTabVisible($event)
                    },
                    keydown: function ($event) {
                      $event.preventDefault()
                      return _vm.keyTabVisible($event)
                    },
                  },
                },
                [
                  _c("img", { attrs: { src: _vm.icon, alt: "white line" } }),
                  _vm._v(" "),
                  _c("span", { domProps: { textContent: _vm._s(title) } }),
                ]
              ),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "mobile-dd", attrs: { href: "#" } }, [
      _c("span", { staticClass: "text" }, [_vm._v("Dashboard")]),
      _c("span", { staticClass: "icon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }