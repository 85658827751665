<template>
	<div class="calendar-filters">
		<div class="row">
			<form id="calendar-filters-form" action="">
				<div class="col-xs-3">
					<fieldset>
						<label class="calendar-filter-label" for="date">Date MM/DD/YYYY</label>
						<DatePicker v-model="filter.date"
						            input-id="date"
						            type="date"
						            value-zone="local"
						            :value="filter.date"
						            :full-month-name="true"
						            :week-start="7"
						            @close="dateHandler" />
					</fieldset>
				</div>

				<div class="col-xs-3">
					<fieldset>
						<label class="calendar-filter-label" for="calendar-date-display">Display</label>
						<select name="calendar-date-display" id="calendar-date-display" class="advanced-search-dropdown" aria-invalid="false" data-selectric>
							<option disabled aria-disabled="true" value="disabled">{{selected}}</option>
							<option v-for="({title, value}, index) in views"
							        :key="index"
							        :value="value">{{title}}
							</option>
						</select>
					</fieldset>
				</div>

				<div class="col-xs-3">
					<fieldset>
						<label class="advanced-search-label" for="calendar-date-region">Region</label>
						<ul class="sidebar-filters">
							<li>
								<a href="#"><span class="text">{{firstRegion}} {{regionCount > 1 ? `+ ${regionCount}` : null}}</span>
									<span class="arrow"></span></a>
								<ul class="sub-filters">
									<li id="regions-dropdown" class="region-list">
										<fieldset class="checkbox-field">
											<input @click="filterRegions($event)"
											       type="checkbox"
											       id="all-regions"
											       data-name="All Regions"
											       data-region-slug="all-regions"
											       aria-labelledby="All Regions"
											       name="All Regions" />
											<label for="all-regions">All Regions</label>
										</fieldset>
										<fieldset v-for="({term_id, name, slug}) in regions"
										          :key="term_id"
										          class="checkbox-field">
											<input @click="filterRegions($event)"
											       type="checkbox"
											       class="other-regions"
											       :id="slug"
											       :data-name="name"
											       :data-region-slug="slug"
											       :data-region-term="term_id"
											       :aria-labelledby="slug"
											       :name="name" />
											<label :for="slug"
											       v-html="name"></label>
										</fieldset>
									</li>
								</ul>
							</li>
						</ul>
					</fieldset>
				</div>

				<div class="col-xs-3 align-items-flex-end calendar-filter-buttons">
					<a @click.prevent="searchHandler"
					   id="submit-btn"
					   role="button"
					   href="#"
					   class="curve-btn blue"><span>Search</span></a>
					<a @click.prevent="resetHandler"
					   id="reset-btn"
					   role="button"
					   href="#"
					   class="curve-btn white"><span>Reset all filters</span></a>
				</div>

				<div class="col-xs-12 hide">
					<span class="search-filters-update-text">Search filters updated!</span>
				</div>

			</form>
		</div>
	</div>
</template>

<script type="application/javascript">
    import {Datetime} from "vue-datetime";
    import moment from "moment";
    import selectric from "selectric";
    import "vue-datetime/dist/vue-datetime.css";

    export default {
        props: {
            regions: [Object, Array],
            views: [Object, Array],
            selectedRegions: [Object, Array],
            queryDate: String,
        },
        data() {
            return {
                filter: {
                    date: ""
                },
                selected: "Monthly",
                firstRegion: "All Regions",
                regionCount: 0
            };
        },
        beforeMount() {
        },
        mounted() {
            $('select').selectric({});

            $("#calendar-date-display").on("change", () => {
                const select = $("#calendar-date-display option:selected");
                this.selected = select.text();
                const selectVal = select.val();

                this.$emit("calSwitch", selectVal);
            });
        },
        methods: {
            searchHandler() {
                // Literally does nothing atm anymore
            },
            selectedOption(option) {
                return this.value ? option.code === this.value.code : false;
            },
            filterRegions(elm) {
                const el = elm.currentTarget;
                const regionContainer = document.querySelector("[id=\"regions-dropdown\"]");
                const allRegions = regionContainer.querySelector("[id=\"all-regions\"]");
                const otherRegions = regionContainer.querySelectorAll("[class=\"other-regions\"]");
                if (el.id === "all-regions") {
                    this.firstRegion = "All Regions";
                    this.regionCount = 0;
                    [...otherRegions].map(region => region.checked = false);
                }
                else {
                    allRegions.checked = false;
                    if (el.checked === true) {
                        this.regionCount++;
                        if (this.regionCount === 1) this.firstRegion = el.name;
                    }
                    else {
                        if (this.regionCount > 0) {
                            this.regionCount--;
                            this.firstRegion = regionContainer.querySelectorAll(`input:not(#all-regions):not(#${el.id}`)[0].name;
                        }
                        if (this.regionCount === 0) this.firstRegion = "All Regions";
                    }
                }
                this.$emit("searchRegions", elm);
            },
            resetHandler() {
                this.firstRegion = "All Regions";
                this.regionCount = 0;
                this.filter.date = "";

                /**
                 * Uncheck checkboxes and toggle up any dropdowns
                 */
                document.querySelectorAll(".checkbox-field input").forEach(elm => elm.checked = false);
                document.querySelectorAll(".sub-filters").forEach(elm => $(elm).slideUp(400));
                document.querySelectorAll(".filter").forEach(elm => $(elm).removeClass("active"));

                this.$emit("searchReset");
            },
            dateHandler() {
                if (this.filter.date.length > 0) {
                    this.filter.date = moment(this.filter.date).format("YYYY-MM-DD");
                    this.$emit("dateSearch", this.filter);
                }
            }
        },
        watch: {
            views: {
                handler(obj) {
                    let index;
                    if (obj.monthly.selected === true) {
                        this.selected = "Monthly";
                        index = obj.monthly.index;
                    }
                    else if (obj.weekly.selected === true) {
                        this.selected = "Weekly";
                        index = obj.weekly.index;
                    }
                    $("#calendar-date-display").prop("selectedIndex", index).selectric();
                },
                deep: true
            },
            selectedRegions(newRegions) {
                this.regionCount = newRegions.length;
                const regionContainer = document.querySelector("[id=\"regions-dropdown\"]");
                const otherRegions = regionContainer.querySelectorAll("input:checked");
                if (otherRegions.length) this.firstRegion = otherRegions[0].name;
            },
            queryDate(newDate) {
                this.filter.date = newDate;
            }
        },
        components: {
            DatePicker: Datetime
        },
        name: "Filters"
    };
</script>

<style lang="scss">
	#calendar-filters-form {
		.selectric-open {
			.selectric-items {
				.disabled {
					display: none;
				}
			}
		}
	}
</style>
