var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cart-footer" }, [
    _c("div", { staticClass: "cart-total-items-container" }, [
      _vm._v("\n\t\tTotal ("),
      _c("span", { staticClass: "cart-total-items" }, [
        _vm._v(_vm._s(_vm.size)),
      ]),
      _vm._v(" item" + _vm._s(_vm.size > 1 ? "s" : null) + "): $\n\t\t"),
      _c("span", { staticClass: "cart-total-items-price" }, [
        _vm._v(_vm._s(_vm.total)),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "curve-btn blue", attrs: { href: "#" } }, [
      _c("span", [_vm._v("Purchase")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }