<template>
	<div class="col-xs-4">
		<div class="checkered-item">
			<p v-if="category.length"
			   class="label" v-text="category"></p>
			<p><a :href="link"
			      v-text="title"></a></p>
		</div>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            title: String,
	        link: String,
	        category: String
        },
        name: "Posts"
    };
</script>

<style scoped>

</style>
