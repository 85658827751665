var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "calendar-title" }, [
    _c("h2", [_vm._v(_vm._s(_vm.pageTitle))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "onoffswitch" },
      [
        _c("input", {
          staticClass: "onoffswitch-checkbox",
          attrs: {
            type: "checkbox",
            name: "onoffswitch",
            id: "myonoffswitch",
            checked: "",
          },
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.views, function (view, index) {
          return _c("p", {
            key: index,
            class: view.title.toLowerCase(),
            domProps: { textContent: _vm._s(view.title) },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "onoffswitch-label", attrs: { for: "myonoffswitch" } },
      [
        _c("span", { staticClass: "onoffswitch-inner" }),
        _vm._v(" "),
        _c("span", { staticClass: "onoffswitch-switch" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }