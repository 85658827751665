import Vue from "vue";
import axios from "axios";
import {MediaQueries} from "vue-media-queries";
import {store} from "./Vuex/store";
import VueHtmlToPaper from 'vue-html-to-paper';
import Cookies from "js-cookie";

window.Vue = Vue;

const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);

const options = {
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
};

Vue.use(VueHtmlToPaper, options);

Vue.config.productionTip = false;

/** vue **/
// TODO Migrate these to their correct templates
const VueInstance = new Vue({
    store,
    beforeCreate() {
        // Check and set Cookie for the cart
        const windowUrl = window.location;
        const newWindowUrl = (new URL(window.location));
        const checkCookie = Cookies.get("cart-id");

        if (newWindowUrl.search.length && (checkCookie === undefined || checkCookie === "undefined")) {
            const searchParams = newWindowUrl.search.split("&");
            searchParams.forEach(param => {
                const paramSplit = param.split("=");
                if (paramSplit.indexOf("salesOrderId") > -1) {
                    let date = new Date();
                    date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
                    Cookies.set("cart-id", paramSplit[1], {expires: date, secure: true, sameSite: "strict"});
                    // setTimeout(() => window.location.reload(), 100);
                }
            });
        }
    },
    created() {
        const url = window.location.href;
        const param = url.split("?");
        const params = (param[1] !== undefined) ? param[1].split("&")[1] : null;

        (params && params.includes("salesOrderId") === true) ? this.$nextTick(function() { this.$store.dispatch("getCart"); }) : this.$store.dispatch("getCart");

        // Builds the FAST_CASE url
        if (typeof FAST_CASE !== "undefined") this.$store.dispatch('checkNysbaId');

        // Used only on the My NYSBA Card
        if($('#my-nysba-container').length) this.$store.dispatch("getMemberData");
    },
});

const homepagePostsBlock = document.getElementById('homepage-posts-block');
const homepageNewsBlock = document.getElementById('homepage-news-block');
const tagPage = document.getElementById('vue-tag-posts');
const countdownBanner = document.getElementById('vue-countdown-banner');
const eventsProductsFlex = document.querySelectorAll('[class^="vue-events-products"]');

if (homepagePostsBlock) {
    const homePostsBlock = Vue.component('HomepagePostsBlock', require('./Components/HomepagePostsBlocks.vue').default);
    const homepagePostsBlockElm = new Vue({
        el: '#homepage-posts-block',
        mediaQueries: mediaQueries,
        store,
        render: h => h(homePostsBlock)
    })
}

if (homepageNewsBlock) {
    const homeNewsBlock = Vue.component('HomepageNewsBlock', require('./Components/HomepageNewsBlock.vue').default);
    const homepageNewssBlockElm = new Vue({
        el: '#homepage-news-block',
        mediaQueries: mediaQueries,
        store,
        render: h => h(homeNewsBlock),
    });
}

/**
 * Single Committee Past Events Block
 */
if ($("#committee-events").length) {
    const committeeEvents = Vue.component("CommiteeEvents", require("./Components/CommitteeEvents.vue").default);
    const committeePastEventsContainer = new Vue({
        el: "#committee-events",
        store,
        render: h => h(committeeEvents )
    });
}

/**
 * Single Committee Chapters Block
 */
if ($(".sidebar-tabs .rosters").length) {
    const CommitteeRosterListing = Vue.component("committee-roster", require("./Components/CommitteeRoster.vue").default);
    const rosterBlock = new Vue({
        el: "#roster-block",
        components: {CommitteeRosterListing}
    });
}

/**
 * MyNYSBA Card
 */
if ($("#my-nysba-container").length) {
    const myNysba = Vue.component("my-nysba-card", require("./Components/MyNYSBA.vue").default);
    const Card = new Vue({
        el: "#my-nysba-container",
        mediaQueries: mediaQueries,
        store,
        render: h => h(myNysba)
    });

    if ($('#my-nysba-tabs').length) {
        const myNysbaTabs = Vue.component("my-nysba-tabs", require("./Components/MyNYSBATabs.vue").default);
        const CardTabs = new Vue({
            el: "#my-nysba-tabs",
            mediaQueries: mediaQueries,
            store,
            render: h => h(myNysbaTabs)
        });
    }

}

/**
 * Single Products Format
 */
if ($("#single-product").length) {
    const ProductFormats = Vue.component("product-formats", require("./Components/ProductFormats.vue").default);
    const productSidebarQty = Vue.component("product-qty", require("./Components/ProductSidebarQty.vue").default);
    const tabletProductSidebarQty = Vue.component("product-tablet-qty", require("./Components/TabletProductQty.vue").default);
    const productSidebar = new Vue({
        el: "#single-product",
        store,
        mediaQueries: mediaQueries,
        data() {
            return {
                title: "",
                formats: [],
                checkoutUrl: null,
                isLoggedIn: PRODUCT.isLoggedIn,
                loginUrl: null,
            };
        },
        created() {
            const pageUrl = new URL(window.location);
            this.loginUrl = NYSBA.ssoUrl + encodeURIComponent(window.location.href);
            this.title = (PROD_FORMAT.title) ? PROD_FORMAT.title : "Format";
            this.getFormats();

            this.$store.dispatch("setupProduct");

            const checkoutApi = (NYSBA.checkoutApi !== undefined) ? NYSBA.checkoutApi : null;
            const c = document.cookie.match("(^|;) ?cart-id=([^;]*)(;|$)");
            const cookie = c ? c[2] : null;

            if (cookie !== null) {
                //let uriUrl = '/nysbaportal/s/store?#/store/checkout/'
                let uriUrl = '/nysbaportal/s/store?#/store/cart/'
                if (pageUrl.hostname === 'nysba.org') {
                    // uriUrl = '/s/store?#/store/checkout/';
                    uriUrl = '/s/store?#/store/cart/';
                }

                // this.checkoutUrl = checkoutApi + "?RelayState=" + encodeURIComponent(uriUrl + cookie) ;
                // Test checkoutUrl
                this.checkoutUrl = checkoutApi + cookie;
            }

        },
        computed: {
            text() {return this.$store.state.productData.text;},
            icons() {return this.$store.state.productData.icons;},
            qty() {return this.$store.state.productData.qty;},
            total() {return (this.$store.state.productData.qty !== 0) ? ((PRODUCT.price !== 0) ? this.$store.state.productData.qty*PRODUCT.price : 0) : 0;},
            api() {
                const pageUrl = new URL(window.location);
                let relayState = '/nysbaportal/FS_AddToCart'

                if (pageUrl.hostname === 'nysba.org') {
                    relayState = '/FS_AddToCart';
                }

                const encodedRedirect = encodeURIComponent(`?productId=${PRODUCT.productId}&qty=${this.$store.state.productData.qty}&redirectUrl=${window.location.href}`);
                return (this.$store.state.productData.qty !== 0) ? `${PRODUCT.api}/?RelayState=${relayState}${encodedRedirect}` : null;
            }
        },
        methods: {
            getFormats() {
                const api = (PROD_FORMAT.formatApi) ? PROD_FORMAT.formatApi : "";
                let config = {
                    params: {
                        productClass: (PROD_FORMAT.productClass) ? PROD_FORMAT.productClass : null
                    }
                };

                axios.get(`${api}`, config)
                     .then(({data, status}) => {
                         if (status === 200) {
                             this.formats = data;
                         }
                         return;
                     });
            }
        },
        components: {ProductFormats, tabletProductSidebarQty, productSidebarQty}
    });
}

/**
 * Single Event Sidebar
 */
if ($("#event-cart-sidebar").length) {
    const eventSidebarQty = Vue.component("event-qty", require("./Components/EventSidebarQty.vue").default);
    const eventSidebarQtyComp = new Vue({
        el: "#event-cart-sidebar",
        data() {
            return {
                isLoggedIn: EVENT.isLoggedIn,
                loginUrl: null,
            };
        },
        created() {
            this.loginUrl = NYSBA.ssoUrl + encodeURIComponent(window.location.href);
        },
        render: h => h(eventSidebarQty)
    });
}

/**
 * Navigation Cart
 */
if ($(".navigation .nav .cart").length) {
    const navCart = Vue.component("nav-cart", require("./Components/NavCart.vue").default);
    const navCartComp = new Vue({
        el: ".navigation .nav .cart",
        store,
        render: h => h(navCart)
    });
}

/**
 * Cart Template
 */
if ($("#cart-container").length) {
    const cart = Vue.component("cart", require("./Components/Cart.vue").default);
    const cartContainer = new Vue({
        el: "#cart-container",
        mediaQueries: mediaQueries,
        store,
        render: h => h(cart)
    });
}

/**
 * Leadership Template
 */
if ($("#leaders-container").length) {
    const container = Vue.component("cart", require("./Components/LeadershipContainer.vue").default);
    const leadershipContainer = new Vue({
        el: "#leaders-container",
        mediaQueries: mediaQueries,
        store,
        render: h => h(container)
    });
}

/**
 * Social Media Share
 */
if ($("#sm-share").length) {
    let sm = Vue.component("sm", require("./Components/SocialMedia.vue").default);
    const socialMedia = new Vue({
        el: "#sm-share",
        render: h => h(sm)
    });
}

/**
 * Social Media Share Tablet
 */
if ($("#sm-share-tablet").length) {
    let sm = Vue.component("sm", require("./Components/SocialMedia.vue").default);
    const socialMediaTablet = new Vue({
        el: "#sm-share-tablet",
        render: h => h(sm)
    });
}

/**
 * Store Catalog & Sidebar Filter
 */
if ($("#store-listing").length) {
    let storeList = Vue.component("products", require("./Components/StoreListing.vue").default);
    const storeListing = new Vue({
        el: "#store-listing",
        mediaQueries: mediaQueries,
        render: h => h(storeList)
    });
}

/**
 * CLE Listing & Sidebar Filter
 */
if ($("#cle-listing").length) {
    let cleList = Vue.component("CLEListing", require("./Components/CLEListing.vue").default);
    const cleListing = new Vue({
        el: "#cle-listing",
        mediaQueries: mediaQueries,
        render: h => h(cleList)
    });
}

/**
 * Committee Listing & Filter
 */
if ($("#committee-listing").length) {
    let committeeList = Vue.component("CommitteeListing", require("./Components/CommitteeListing.vue").default);
    const committeeListing = new Vue({
        el: "#committee-listing",
        mediaQueries: mediaQueries,
        render: h => h(committeeList)
    });
}

/**
 * Events Calendar
 */
if ($("#calendar-container").length) {
    let calendar = Vue.component("Calendar", require("./Components/Calendar.vue").default);
    const calendarView = new Vue({
        el: "#calendar-container",
        mediaQueries: mediaQueries,
        render: h => h(calendar)
    });
}

/**
 * Creates the fastcase login button
 */
if ($('.fastcase-container').length) {
    const fastcaseLoginButton = Vue.component('FastCaseButton', require("./Components/FastCaseButton.vue").default);
    document.querySelectorAll('.fastcase-container').forEach(elm => {
        const fastcaseComponent = new Vue({
            el: `.${elm.classList.value}`,
            store,
            render: h => h(fastcaseLoginButton),
        })
    })
}

if (eventsProductsFlex.length) {
    const eventsProductsElm = Vue.component('event-products', require("./Components/EventsProducts.vue").default);
    eventsProductsFlex.forEach(elm => {
        const eventsProductsComponent = new Vue({
            el: `.${elm.classList.value}`,
            components: {eventsProductsElm}
        })
    })
}

/**
 * Tags Search Template
 */
if (tagPage) {
    const tagPostsBlock = Vue.component('TagPostsBlock', require('./Components/TagPosts.vue').default);
    const tagPostsBlockElm = new Vue({
        el: '#vue-tag-posts',
        mediaQueries: mediaQueries,
        store,
        render: h => h(tagPostsBlock),
    });

    const tagPagination = Vue.component('TagPagination', require('./Components/TagPostPagination.vue').default);
    const tagPaginationBlockElm = new Vue({
        el: '#vue-tag-pagination',
        mediaQueries: mediaQueries,
        store,
        render: h => h(tagPagination),
    });
}

/**
 * Countdown Banner
 */
if (countdownBanner && !Cookies.get('countdownDismissed')) {
    const countdownBannerBlock = Vue.component('CountdownBanner', require('./Components/CountdownBanner.vue').default);
    const countdownBannerElm = new Vue({
        el: "#vue-countdown-banner",
        mediaQueries: mediaQueries,
        store,
        render: h => h(countdownBannerBlock)
    })
}
