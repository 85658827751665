var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: !!_vm.isLoading ? _vm.loadingClass : "" },
    [
      _vm.$resize && _vm.$mq.above(1025)
        ? _c("MemberCard", {
            attrs: {
              data: _vm.memberData,
              editLinks: _vm.editLinks,
              loading: _vm.isLoading,
              icons: _vm.icons,
              "allow-update": _vm.allowUpdate,
              "time-update": _vm.canUpdate,
            },
            on: { refreshMemberData: _vm.refreshMemberData },
          })
        : _c("MemberCardTablet", {
            attrs: {
              data: _vm.memberData,
              editLinks: _vm.editLinks,
              loading: _vm.isLoading,
              icons: _vm.icons,
              "time-update": _vm.canUpdate,
            },
            on: { refreshMemberData: _vm.refreshMemberData },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }