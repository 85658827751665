var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tags-content__post" }, [
    _vm.objHasKey(_vm.post, "img") && _vm.post.img
      ? _c(
          "div",
          { staticClass: "thumb" },
          [_c("PostImage", { attrs: { image: _vm.post.img } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "post-meta" }, [
        _vm.post.date
          ? _c("p", { domProps: { innerHTML: _vm._s(_vm.post.date) } })
          : _vm._e(),
        _vm.post.category
          ? _c("p", {
              staticClass: "post-category",
              domProps: { innerHTML: _vm._s(_vm.post.category) },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "post-title" }, [
        _c("a", { attrs: { href: _vm.post.permalink } }, [
          _c("span", {
            staticClass: "strikethrough",
            domProps: { innerHTML: _vm._s(_vm.post.title) },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm.post.excerpt
        ? _c("div", { staticClass: "excerpt" }, [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.post.excerpt) } }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "line-btn blue", attrs: { href: _vm.post.permalink } },
        [_c("span", [_vm._v("Read More")])]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }