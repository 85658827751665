var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tag-posts" },
    [
      _c("Loading", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _vm._l(_vm.posts, function (post) {
        return _vm.posts.length
          ? _c("TagPost", { key: post.id, attrs: { post: post } })
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }