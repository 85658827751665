<template>
	<div class="col-xs-3">
		<div class="leader-cnt">
			<div class="photo-cnt">
				<div class="overlay">
					<a @click.prevent="openModal($event)"
					   href="#" class="js-modal line-btn white"
					   data-modal-prefix-class="bio"
					   data-modal-text=""
					   data-modal-title=""
					   :data-modal-close-img="icons.searchClose"
					   :data-modal-content-id="`modal-bio-${leader.ID}`"
					   data-modal-close-text="Close"
					   data-modal-close-title="Close this window"><span v-text="readMoreText"></span>
					</a>
				</div>
				<div class="photo">
					<div class="tags">
						<div v-if="leader.types"
						     v-for="({name, termId, slug}) in leader.types"
						     :class="`title-tag ${slug}`"
						     :key="termId"
						     v-text="name"></div>
					</div>
					<picture v-if="leader.image">
						<source v-if="leader.image.webpSrcset"
						        :srcset="leader.image.webpSrcset"
						        :sizes="leader.image.sizes"
						        type="image/webp" />
						<source :srcset="leader.image.srcset"
						        :sizes="leader.image.sizes"
						        :type="leader.image.type" />
						<img :src="leader.image.src"
						     :content="leader.image.src"
						     :alt="leader.image.alt"
						     role="img"
						     property="v:image" />
					</picture>
				</div>
			</div>
			<p class="name" v-text="leader.title"></p>
			<p class="title" v-text="leader.organization"></p>
			<div v-if="leader.typeText"
           class="title-container">
				<p class="title">
          <span v-for="(type, index) in leader.typeText"
                :key="index"
                v-text="type"></span>
        </p>
			</div>
		</div>
	</div>
</template>

<script type="application/javascript">
    import {EventBus} from "../../Util/event-bus.js";

    export default {
        props: {
            leader: [Array, Object],
	        icons: [Array, Object]
        },
	    data() {
            return {
                readMoreText: (LEADER.readMoreText) || 'Read Bio',
            }
	    },
	    methods: {
            openModal(elm) {
                EventBus.$emit("leader-data", this.leader);
            }
	    },
	    name: "LeaderPost"
    };
</script>

<style lang="scss" scoped>
.title-container {
  span {display: block;}
}
</style>
