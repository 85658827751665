<template>
	<div class="favorite-filter-results">
		<Results :total="totalPosts" />

		<PostsFilter :cats="cats" />

		<PostsContainer :posts="posts"
		                :max-pages="maxPages"
		                :current-page="currentPage"
		                :loading="loading" />

	</div>
</template>

<script type="application/javascript">
    import PostsFilter from "./PostsFilter.vue";
    import PostsContainer from "./PostsContainer.vue";
    import Results from "./Results.vue";

    export default {
        props: {
            posts: [Array, Object],
            cats: [Array, Object],
            maxPages: Number,
            currentPage: Number,
            loading: Boolean
        },
        data() {
            return {
                totalPosts: 0,
            };
        },
        watch: {
            posts(posts) {
                this.totalPosts = typeof posts === "object" ? Object.keys(posts).length : 0;
            }
        },
        components: {PostsFilter, PostsContainer, Results},
        name: "PostsBlock"
    };
</script>

<style scoped>

</style>
