<template>
	<div class="row">
		<div class="col-xs-12">
			<p class="no-results"
			   v-text="noResultsText"></p>
		</div>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            noResultsText: String
        },
        name: "NoResults"
    };
</script>

<style scoped>

</style>
