<template>
	<div class="committee-roster col-xs-12">
		<div class="loading committee-roster-loading"></div>
		<div v-if="posts">
			<RosterListing :roster="posts" />
			<Pagination
				v-if="maxPage > 1"
				:page-count="maxPage"
				:page-range="3"
				:force-page="paged"
				:margin-pages="1"
				:click-handler="gotoPage"
				:prev-text="''"
				:next-text="''"
				:container-class="'pagination'"
				:page-link-class="'page-numbers'"
				:next-link-class="'next'"
				:prev-link-class="'prev'"
				:active-class="'current'"
				:break-view-link-class="'dots'"
				:no-li-surround="true"
			/>
		</div>
	</div>
</template>

<script>
    import axios from "axios";

    import RosterListing from "./Committee-Detail/Roster/RosterListing.vue";
    import Pagination from "vuejs-paginate";

    export default {
        props: {
            postid: Number,
        },
        data() {
            return {
                allPosts: null,
                arrEnd: 20,
                arrStart: 0,
                currentPage: 1,
                maxPage: 0,
                paged: 1,
                ppp: 20,
                posts: null,
                resultsQty: 0,
            };
        },
        beforeMount() {
            // EventBus.$on("committee-post-id", committee_id => {
            //     this.committee_id = committee_id;
            //     this.getCommitteePost();
            // });
            this.getRosterList(this.paged);
        },
        mounted() {
            let sidebarTab = $(".sidebar-tabs li");
            // sidebarTab.on("click", (elm) => {
            //     const aTarget = elm.target;
            //     const spanTarget = elm.target.parentElement;
            //     if ((aTarget.classList.contains('rosters') && aTarget.classList.contains('active-link')) || spanTarget.classList.contains('rosters') ) {
            //         this.getRosterList();
            //     }
            //     // this.committee_id = 0;
            //     // this.data = null;
            //     // $("#committee-list, #committees-list-title").show();
            // });
        },
        methods: {
            getRosterList: function(page) {
                const loading = $(".committee-roster-loading");
                const post = $(".committee-roster");
                const api_url = (NYSBA.rosterApi !== undefined) ? NYSBA.rosterApi : "";
                let config;

                if (api_url) {
                    let params = {};

                    (NYSBA.loggedIn) ? params.loggedIn = true : null;
                    params.paged = page;

                    config = { params };

                    loading.show();
                    axios
                        .get(`${api_url + this.postid}`, config)
                        .then(({data, status}) => {
                            if (status === 200) {
                                console.log(data);
                                this.resultsQty = data.total;
                                this.allPosts = data.posts;
                                // this.posts = data.posts;
                                this.maxPage = data.maxPages;
                                this.slicePosts(1);
                            }
                        })
                        .catch(err => console.log(err))
                        .then(function() {
                            loading.hide();
                        });
                }
            },
            gotoPage(pageNum) {
                if ((event !== undefined)) {
                    if (event.currentTarget.classList.contains("page-numbers") || event.currentTarget.classList.contains("next") || event.currentTarget.classList.contains("prev")) {
                        $("html, body").animate({
                            scrollTop: $("#roster-container").offset().top - 50
                        }, 1000);
                    }
                }

                this.paged = pageNum;
                this.slicePosts(pageNum, true);
            },
            slicePosts(page = this.currentPage, fromPagination = false) {
                const ppp = this.ppp;
                if (page !== 1) this.arrStart = ((ppp*page) - ppp);
                else this.arrStart = 0;
    
                if (page === this.maxPage) this.arrEnd = this.resultsQty
                else this.arrEnd = (ppp*page);
                
                this.posts = Object.values(this.allPosts).slice(this.arrStart, this.arrEnd);
            }
        },
        components: {RosterListing, Pagination},
        name: "CommitteeRoster"
    };
</script>

<style scoped>

</style>
