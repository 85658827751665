<template>
    <div class="tags-content__post">
        <div v-if="objHasKey(post, 'img') && post.img"
             class="thumb">
            <PostImage :image="post.img" />
        </div>
        <div class="content">
            <div class="post-meta">
                <p v-if="post.date"
                   v-html="post.date">
                <p v-if="post.category"
                   class="post-category"
                   v-html="post.category" />
            </div>
            <h3 class="post-title">
                <a :href="post.permalink">
                    <span class="strikethrough"
                          v-html="post.title" />
                </a>
            </h3>
            <div v-if="post.excerpt"
                 class="excerpt">
                <p v-html="post.excerpt" />
            </div>
            <a :href="post.permalink"
               class="line-btn blue">
                <span>Read More</span>
            </a>
        </div>
    </div>
</template>

<script type="application/javascript">
    import mixins from "../../Util/mixins.js";
    import PostImage from "./PostImage.vue";

    export default {
        props: {
            post: [Array, Object]
        },
        mixins: [mixins],
        components: {PostImage},
        name: "TagPost"
    };
</script>

<style scoped>

</style>
