var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-content" }, [
    _c("div", { staticClass: "modal-close" }, [
      _c(
        "a",
        {
          staticClass: "close",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.closeModal.apply(null, arguments)
            },
          },
        },
        [
          _c("span", { staticClass: "sr-only" }, [
            _vm._v("Close event modal window"),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal-event-date" }, [
      _vm._v(_vm._s(_vm.eventDate)),
    ]),
    _vm._v(" "),
    _vm.events.length
      ? _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.events, function (event, i) {
            return _c("div", { key: i, staticClass: "col-xs-6" }, [
              _c(
                "div",
                {
                  class: `modal-event modal-event-${event.eventRange.def.extendedProps.slug}`,
                },
                [
                  event.eventRange.def.extendedProps.hasOwnProperty(
                    "categoryName"
                  )
                    ? _c(
                        "div",
                        { staticClass: "modal-event-category-container" },
                        _vm._l(
                          event.eventRange.def.extendedProps.categoryName,
                          function (event) {
                            return _c(
                              "div",
                              {
                                key: event.id,
                                class: `modal-event-category ${event.slug}`,
                              },
                              [
                                event.img !== undefined
                                  ? _c("img", {
                                      attrs: {
                                        src: event.img,
                                        alt: "Registered Event",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", {
                                  class:
                                    event.slug === "registered"
                                      ? "category-name sr-only"
                                      : "category-name",
                                  domProps: { textContent: _vm._s(event.name) },
                                }),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-event-title" }, [
                    _c("a", { attrs: { href: event.eventRange.def.url } }, [
                      _vm._v(_vm._s(event.eventRange.def.title)),
                    ]),
                  ]),
                  _vm._v(" "),
                  event.eventRange.def.extendedProps.hasOwnProperty("region")
                    ? _c("div", { staticClass: "region-title" }, [
                        _c("p", { staticClass: "small-text" }, [
                          _vm._v(
                            _vm._s(event.eventRange.def.extendedProps.region)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }