<template>
	<div id="desktop-calendar-header" class="calendar-category-container">
		<div class="row">
			<div class="col-xs-12">
				<p class="label">Show</p>
				<ul class="category-list">
					<li id="category-everything"
					    @click.prevent="filterType($event)"
					    data-cat="0"
					    data-name="all-events"
					    class="filter-all filter category-everything active">
						<a href="#">Everything</a>
					</li>
					<li v-for="({term_id, slug, name}) in types"
					    :key="term_id"
					    @click.prevent="filterType($event)"
					    :id="`category-${slug}`"
					    :data-cat="term_id"
					    :data-name="slug"
					    :class="`filter-cat filter category-${slug}`">
						<a v-html="name"
						   href="#"></a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            types: [Array, Object]
        },
        methods: {
            filterType(elm) {
                const el = elm.currentTarget;
                const parentNode = el.parentNode;
                const filterItems = parentNode.querySelectorAll('[class^="filter-cat"]');
                const filterAll = parentNode.querySelector('[data-name="all-events"]');

                if (!el.classList.contains('active')) el.classList.add('active');
                else (el.dataset.name !== 'all-events') ? el.classList.remove('active') : null;

                if (el.dataset.name === 'all-events') [...filterItems].map(item => item.classList.remove('active'));
                else filterAll.classList.remove('active');

                this.$emit("eventSearchFilter", el);
            }
        },
        name: "EventHeaderCategories"
    };
</script>

<style scoped>

</style>
