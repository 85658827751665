var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "swiper-container", attrs: { id: "news-swiper" } },
    [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        _vm._l(
          _vm.posts,
          function ({ category, date, image, permalink, title }, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "programs-slider__slide swiper-slide",
              },
              [
                _c("NewsPost", {
                  attrs: {
                    category: category,
                    date: date,
                    image: image,
                    permalink: permalink,
                    title: title,
                  },
                }),
              ],
              1
            )
          }
        ),
        0
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", {
        staticClass: "swiper-pagination",
        attrs: { id: "news-swiper-pagination" },
      }),
      _vm._v(" "),
      _vm._m(1),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "swiper-button-prev", attrs: { id: "news-button-prev" } },
      [_c("span", { staticClass: "prev-text" }, [_vm._v("Previous")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "swiper-button-next", attrs: { id: "news-button-next" } },
      [_c("span", { staticClass: "next-text" }, [_vm._v("Next")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }