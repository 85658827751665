<template>
	<form v-if="$resize && $mq.above(1025)"
	      id="sticky-cart-form"
	      action="">
		<fieldset v-if="$root.isLoggedIn === 'true'">
			<label for="cart-quantity">{{ qtyText }}</label>
			<div class="plus-minus-container">
				<button @click.prevent="decrement"
				        class="minus">
					<img :src="minusIcon"
					     alt="Subtract One Quantity" />
				</button>
				<input type="number"
				       id="cart-quantity"
				       name="cart-quantity"
				       aria-labelledby="cart-quantity"
				       :value="$root.qty">
				<button @click.prevent="increment"
				        class="plus">
					<img :src="plusIcon"
					     alt="Add One Quantity" />
				</button>
			</div>
		</fieldset>

		<!--		<div class="Cart-total-items-container">-->
		<!--			Total (<span class="Cart-total-items">{{ $root.qty }}</span> items): $-->
		<!--			<span-->
		<!--				:data-price="$root.total"-->
		<!--				class="Cart-total-items-price">-->
		<!--			{{ $root.total }}-->
		<!--			</span>-->
		<!--		</div>-->

		<div v-if="$root.isLoggedIn === 'true'"
		      class="cart-checkout-buttons">
			<a v-if="$root.checkoutUrl"
			   :href="$root.checkoutUrl"
			   class="curve-btn blue"><span>{{ checkoutText }}</span></a>
			<a class="curve-btn white"
			   :href="($root.qty > 0 ) ? $root.api : null">
				<span>{{ addToCartText }}</span>
			</a>
		</div>

		<div v-if="$root.isLoggedIn === 'true'">
			<a v-if="$root.checkoutUrl"
			   :href="$root.checkoutUrl"
			   class="line-btn blue"><span>{{ viewCartText }}</span></a>
		</div>

		<div v-if="$root.isLoggedIn === 'false'">
			<LoginBtn :text="loginBtnText"/>
		</div>
        
        <div v-if="footnoteText"
             class="footnote-content"
             v-html="footnoteText"></div>
        
	</form>
</template>

<script>
    import LoginBtn from "./LoginBtn.vue";
    export default {
        data() {
            return {
                qtyText: this.$root.text.qtyText,
                checkoutText: this.$root.text.checkoutText,
                addToCartText: this.$root.text.addToCartText,
                viewCartText: this.$root.text.viewCartText,
                footnoteText: this.$root.text.footnoteText,
                minusIcon: this.$root.icons.minusIcon,
                plusIcon: this.$root.icons.plusIcon,
                loginBtnText: 'Login To Register'
            };
        },
	    beforeMount() {
            (PRODUCT.loginBtnText) ? this.loginBtnText = PRODUCT.loginBtnText : null;
	    },
        methods: {
            increment() {
                this.$store.dispatch("increment");
            },
            decrement() {
                this.$store.dispatch("decrement");
            }
        },
        components: {
            LoginBtn
        },
        name: "product-qty"
    };
</script>

<style scoped>

</style>
