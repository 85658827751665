<template>
	<div class="row">
		<RosterMember v-for="(member) in roster"
		              :member="member"
		              :key="member.id" />
	</div>
</template>

<script type="application/javascript">
    import RosterMember from "./RosterMember.vue";

    export default {
        props: {
            roster: [Object, Array]
        },
        components: {RosterMember},
        name: "RosterListing"
    };
</script>
