<template>
    <div class="home__featured wrapper">
        <div class="home__featured__container">
            <div v-if="loading"
                 class="loading-container">
                <Loading :loading="loading" />
            </div>
            <div class="home__featured__column">
                <div v-if="slidePosts && !loading"
                     id="slider-block"
                     class="home__featured__slider helpful-links-slider">
                    <BlockTitle title-class="slider-posts__title"
                                :title="slideTitle" />
                    
                    <SliderBlock post-class="slider-posts__post"
                                 :posts="slidePosts" />
                </div>
                
                <div v-if="smallPosts && !loading"
                     id="small-posts-block"
                     class="home__featured__list home__featured__list--left">
                    <BlockTitle title-class="small-posts__title"
                                :title="smallTitle" />
                    
                    <PostsBlock post-class="small-posts__post"
                                :posts="smallPosts" />
                </div>
            
            </div>
            
            <div class="home__featured__column">
                <div v-if="largePosts && !loading"
                     id="large-posts-block"
                     class="home__featured__list">
                    <BlockTitle title-class="large-posts__title"
                                :title="largeTitle" />
                    
                    <PostsBlock post-class="large-posts__post"
                                :posts="largePosts" />
                </div>
            
            </div>
        
        </div>
        
        <div v-if="!loading && postsLink"
             class="row center-xs mt-50">
            <div class="col-xs-12">
                    <a :href="postsLink.url"
                       class="curve-btn blue"
                       :target="postsLink.target">
                        <span v-html="postsLink.title" />
                    </a>
            </div>
        </div>
    
    </div>
</template>

<script type="application/javascript">
    /**
     * Plugin Imports
     */
    import axios from "axios";
    
    /**
     * Components
     */
    import BlockTitle from "./Homepage/BlockTitle.vue";
    import Loading from "./Blocks/Loading.vue";
    import PostsBlock from "./Homepage/PostsBlock.vue";
    import SliderBlock from "./Homepage/SliderBlock.vue";
    
    export default {
        props: {
            titles: [Array, Object]
        },
        data() {
            return {
                api: HOME.blocksApi,
                largePosts: null,
                largeTitle: HOME.titles.largeTitle,
                loading: true,
                slidePosts: null,
                postsLink: null,
                slideTitle: HOME.titles.slideTitle,
                smallPosts: null,
                smallTitle: HOME.titles.smallTitle,
            };
        },
        created() {
            this.getPosts();
        },
        beforeMount() {
        
        },
        methods: {
            getPosts() {
                axios.get(this.api)
                     .then(({status, data}) => status === 200 && data)
                     .then(({slidePosts, smallPosts, postsLink, largePosts}) => {
                         this.slidePosts = slidePosts;
                         this.smallPosts = smallPosts;
                         this.postsLink = postsLink;
                         this.largePosts = largePosts;
                     })
                     .catch(e => console.error(e))
                     .finally(() => this.loading = false);
            }
        },
        components: {BlockTitle, Loading, PostsBlock, SliderBlock},
        name: "HomepagePostsBlocks",
    };
</script>

<style lang="scss" scoped>
    
    .home__featured__container {
        position: relative;
    }
    
    .loading-container {
        position:        relative;
        display:         flex;
        flex-flow:       row nowrap;
        align-items:     center;
        justify-content: center;
        width:           100%;
        min-height:      100px;
        flex:            0 0 100%;
    }
    
    .small-posts__cta-container {
        margin-left:  auto;
        margin-right: auto;
    }
    
    .mt-50 {
        margin-top: 50px;
    }
    
</style>
