<template>
    <div v-if="countdownDate"
         ref="countDownBanner"
         id="top-alert-banner" class="top-alert-banner">
        <div class="top-alert-banner__content">
            <VueCountdown class="countdown"
                          tag="div"
                          :time="countdownDate"
                          :transform="transform">
                <template v-slot="props">
                    <span class="countdown__inner countdown__inner--day">
				        <span class="day countdown__time">{{ props.days }}</span>
				        <span class="countdown__label">days</span>
			        </span>
                    <span class="countdown__inner countdown__inner--hrs">
				        <span class="hrs countdown__time">{{ props.hours }}</span>
				        <span class="countdown__label">hrs</span>
			        </span>
                    <span class="countdown__inner countdown__inner--min">
				        <span class="min countdown__time">{{ props.minutes }}</span>
				        <span class="countdown__label">min</span>
			        </span>
                </template>
            </VueCountdown>
            <div v-if="countdownText"
                 class="txt" v-html="countdownText" />
            <div v-if="!isObjEmpty(countdownCta)"
                 class="cta-container">
                <a :href="countdownCta.url"
                   class="curve-btn white"
                    :target="countdownCta.target || ''"
                    :rel="countdownCta.target ? 'noopener' : ''">
                    <span v-html="countdownCta.title" />
                </a>
            </div>
            
            <button class="close"
                    @click.prevent="bannerDismissed"
                    @keydown.enter.prevent="bannerDismissed">
                <span class="sr-only">Close alert</span>
            </button>
        </div>
    </div>
</template>

<script type="application/javascript">
    /**
     * Vue Plugins
     */
    import VueCountdown from "@chenfengyuan/vue-countdown";

    /**
     * JS Scripts
     */
    import Cookies from "js-cookie";

    /**
     * Vue Scripts
     */
    import mixins from "../Util/mixins.js";
    
    export default {
        props: {},
        data: () => ({
            countdownCta: NYSBA.countdownCta || null,
            countdownDate: null,
            countdownText: NYSBA.countdownText || null,
        }),
        beforeCreate() {},
        created() {
            if (typeof NYSBA !== "undefined" && NYSBA.countdownDateTime) {
                const dateTime = new Date(Number(NYSBA.countdownDateTime));
                this.countdownDate = dateTime.getTime();
            }
        },
        beforeMount() {},
        mounted() {},
        methods: {
            transform(props) {
                Object.entries(props).forEach(([key, value]) => {
                    // Adds leading zero
                    props[key] = value < 10 ? `0${value}` : value;
                });
        
                return props;
            },
            bannerDismissed() {
                Cookies.set('countdownDismissed', true, {secure: true, sameSite: 'strict',});
                this.countdownDate = null;
            }
        },
        computed: {},
        watch: {},
        components: {VueCountdown},
        mixins: [mixins],
        name: "CountdownBanner"
    };
</script>

<style scoped>

</style>
