import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Cookies from "js-cookie";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        cartData: {
            totalSize: 0,
            cartContents: null,
            total: 0,
        },
        productData: {
            text: {},
            icons: {},
            qty: 1,
        },
        memberData: [],
        nysbaId: null,
        fastcaseUrl: null,
        tagPostsMaxPage: 0,
        tagPostsCurrentPage: 1,
    },
    mutations: {
        SET_CART_DATA(currentState, {size, cart, total}) {
            currentState.cartData.totalSize = size;
            currentState.cartData.cartContents = cart;
            currentState.cartData.total = total;
        },
        SET_CART_CONTENTS(currentState, cart) {
            currentState.cartContents = cart;
        },
        SETUP_PRODUCT(currentState, {text, icons}) {
            currentState.productData.text = text;
            currentState.productData.icons = icons;
        },
        GET_NYSBA_ID(currentState, {nysbaID}) {
            currentState.nysbaId = nysbaID;
        },
        BUILD_FASTCASE_URL(currentState, {username}) {
            if (username.length) {
                const fc = FAST_CASE;
                const fcUrl = new URL(`${fc.url}`);
                const params = {};
                /**
                 * Setup parameters
                 */
                params.UserName = username;
                fc.email.length ? params.Email = fc.email : null;
                fc.firstName.length ? params.FirstName = fc.firstName : null;
                fc.lastName.length ? params.LastName = fc.LastName : null;
                fc.logoutUrl.length ? params.LogoutUrl = fc.logoutUrl : null;
                fc.companyUID.length ? params.CompanyUID = fc.companyUID : null;
                fc.passkey.length ? params.PassKey = fc.passkey : null;
    
                fcUrl.search = new URLSearchParams(params);
    
                currentState.fastcaseUrl = fcUrl;
            }
        },
        GET_MEMBER_DATA(currentState, {data}){
            currentState.memberData = data;
        },
        increment(currentState) {
            currentState.productData.qty++;
        },
        decrement(currentState) {
            (currentState.productData.qty > 0) ? currentState.productData.qty-- : null;
        },
        tagPostPagination(currentState, {maxPage, currentPage}) {
            currentState.tagPostsMaxPage = maxPage;
            currentState.tagPostsCurrentPage = currentPage;
        },
    },
    actions: {
        getCart(store) {
            const api = (NYSBA.cartApi !== undefined) ? NYSBA.cartApi : null;
            const c = document.cookie.match("(^|;) ?cart-id=([^;]*)(;|$)");
            const cookie = c ? c[2] : null;
            
            if (api !== null && cookie !== null) {
                axios
                    .get(`${api}${cookie}`)
                    .then(({status, data}) => {
                        if (status === 200 && data) {
                            if (data.status && data.status === 400) {
                                Cookies.remove("cart-id");
                            }
                            else {
                                store.commit("SET_CART_DATA", {
                                    size: data.totalSize,
                                    cart: data.cartItems,
                                    total: data.cartTotal,
                                });
                            }
                        }
                    })
                    .catch(err => console.log(err));
            }
            return store.state;
        },
        checkNysbaId(store) {
            let api, sfId;
            if (NYSBA !== undefined && NYSBA.sfApi !== undefined && NYSBA.sfId !== undefined) {
                api = NYSBA.sfApi;
                sfId = NYSBA.sfId;
                
                axios.get(`${api}/${sfId}`)
                     .then(({status, data}) => {
                         if (status === 200 && data) {
                             /**
                              * Need to check if the API call isn't successful
                              */
                             if (data.status !== 400) {
                                 store.commit("GET_NYSBA_ID", {nysbaID: data.id});
                                 if (FAST_CASE !== undefined) store.commit("BUILD_FASTCASE_URL", {username: data.id});
                             }
                         }
                     })
                     .catch(err => console.log(err));
            }
            else if (typeof NYSBA !== undefined && NYSBA.nysbaId !== undefined) {
                store.commit("GET_NYSBA_ID", {nysbaID: NYSBA.nysbaId});
                
                if (typeof FAST_CASE !== undefined) {
                    store.commit("BUILD_FASTCASE_URL", {username: NYSBA.nysbaId});
                }
            }
            
            return store.state;
        },
        setupProduct(store) {
            if (typeof PRODUCT !== undefined) {
                store.commit("SETUP_PRODUCT", {
                    text: {
                        qtyText: (PRODUCT.qtyText) ? PRODUCT.qtyText : null,
                        checkoutText: (PRODUCT.checkoutText) ? PRODUCT.checkoutText : null,
                        addToCartText: (PRODUCT.addToCartText) ? PRODUCT.addToCartText : null,
                        viewCartText: (PRODUCT.viewCartText) ? PRODUCT.viewCartText : null,
                        footnoteText: (PRODUCT.footnoteText) ? PRODUCT.footnoteText : null,
                    },
                    icons: {
                        plusIcon: (PRODUCT.icon.plus) ? PRODUCT.icon.plus : null,
                        minusIcon: (PRODUCT.icon.minus) ? PRODUCT.icon.minus : null
                    }
                });
            }
        },
        getMemberData(store) {
            if (typeof MYNYSBA !== undefined) {
                let config;
                
                const api = (MYNYSBA.myNysbaApi !== undefined) ? MYNYSBA.myNysbaApi : "";
                const memberId = (MYNYSBA.userId) ? MYNYSBA.userId : "";
                const pageId = (MYNYSBA.pageID) ? MYNYSBA.pageID : "";
    
                pageId ? config = {params: {page: pageId}} : null;
    
                axios
                    .get(`${api + memberId}`, config)
                    .then(({data, status}) => {
                        if (status === 200) store.commit("GET_MEMBER_DATA", {data: data});
                    })
                    .catch(err => console.log(err))
            }
        },
        increment(store) {
            store.commit("increment");
        },
        decrement(store) {
            store.commit("decrement");
        },
    },
    getters: {},
});
