var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-xs-12" },
    [
      _vm.$resize && _vm.$mq.above(1025)
        ? _c("cart-table", {
            attrs: { contents: _vm.getContents, icons: _vm.icons },
          })
        : _c("cart-tablet", {
            attrs: { contents: _vm.getContents, icons: _vm.icons },
          }),
      _vm._v(" "),
      _c("cart-totals", { attrs: { size: _vm.getSize, total: _vm.getTotal } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }