<template>
	<div class="result-item">
		<a :href="post.permalink" class="mobile-only">{{post.title}}</a>
		<ul class="top">
			<li v-if="post.type">{{post.type}}</li>
			<li><a :href="post.permalink">{{post.title}}</a></li>
		</ul>
		<ul class="bottom">
			<li v-if="post.pa">{{post.pa}}</li>
			<li v-if="post.hostGroup">{{post.hostGroup}}</li>
		</ul>
	</div>
</template>

<script>
    export default {
        props: {
            post: [Object]
        },
        name: "CommitteeItemTablet"
    };
</script>
