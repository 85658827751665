<template>
    <section class="calendar-monthly-weekly wrapper">
        <Title
            :pageTitle="pageTitle"
            @dateSearch="dateSearch"
            @calSwitch="calendarViewSwitch"
            :views="optionsViews" />
        
        <div
            v-if="$resize && $mq.above(1024)"
            id="desktop-calendar-header"
            class="calendar-category-container">
            <EventTypes :types="types" :selectedTypes="selectedTypes" @searchFilter="searchTypes" />
            <Filters
                :regions="regions"
                :views="optionsViews"
                :selectedRegions="selectedRegions"
                :queryDate="queriedDate"
                @dateSearch="dateSearch"
                @calSwitch="calendarViewSwitch"
                @searchRegions="searchRegions"
                @searchReset="resetAll" />
        </div>
        
        <div class="line-header-container">
            <div class="line-header">
                <h2 v-text="`Result (Total ${numOfEvents + (view === 'week' ? ` Events` : null)})`"></h2>
            </div>
        </div>
        
        <div v-if="$resize && $mq.below(1025)" class="tablet-advanced-settings-toggle">
            <div class="tablet-advanced-filters">
                <img :src="icons.filter" alt="advanced filters icons" /> Open Advanced Filters
            </div>
        </div>
        
        <div id="tablet-calendar-settings" class="tablet-calendar-settings-container">
            <TabletFilters
                :types="types"
                :regions="regions"
                :icons="icons"
                @searchTypes="searchTypes"
                @dateSearch="dateSearch"
                @searchRegions="searchRegions"
                @searchReset="resetAll" />
        </div>
        
        <div id="calendar" :class="isLoading ? 'is-loading' : null">
            <FullCalendar
                ref="calendar"
                :header="config.header"
                :plugins="config.plugins"
                :views="config.views"
                :next-day-threshold="config.nextDayThreshold"
                :editable="config.editable"
                :default-view="config.defaultView"
                :custom-buttons="config.customNavigationButtons"
                :events="events"
                :now="now"
                :event-render="eventRender"
                :event-limit-click="eventLimitCheck"
                :dates-render="handleChange"
                :nav-links="true"
                :nav-link-day-click="navWeekHeaderDayClick"
                :handle-window-resize="mediaQueryHandler"
                :unselect-auto="false"
                @eventMouseEnter="monthlyMouseEnter"
                @eventMouseLeave="monthlyMouseLeave"
                @dateClick="tooltipHide"
                @eventResizeStart="tooltipHide"
                @eventDragStart="tooltipHide"
                @viewDisplay="tooltipHide"
                @windowResize="mediaQueryHandler(true)" />
            
            <div v-if="view === 'week'" class="calendar-week-container">
                <SingleEventWeek
                    :activeDate="activeDate"
                    :activeEvents="numOfEvents"
                    :events="events"
                    :isLoading="isLoading"
                    :visibleChildren="visibleChildren" />
            </div>
            <div class="loading"></div>
        </div>
        
        <div id="modal" class="modal">
            <div class="modal-events"></div>
        </div>
    </section>
</template>

<script type="application/javascript">
    // jQuery Libraries
    import axios from "axios";
    import moment from "moment";
    import momentPlugin from "@fullcalendar/moment";
    import dayGridPlugin from "@fullcalendar/daygrid";
    import timeGridPlugin from "@fullcalendar/timegrid";
    import interactionPlugin from "@fullcalendar/interaction";
    import {EventBus} from "../Util/event-bus.js";
    
    // Components
    import FullCalendar from "@fullcalendar/vue";
    import Title from "./Calendar/Header/Title.vue";
    import EventTypes from "./Calendar/Header/EventTypes.vue";
    import Filters from "./Calendar/Header/Filters.vue";
    import TabletFilters from "./Calendar/Header/TabletFilters.vue";
    import Legend from "./Calendar/Header/CalendarLegend.vue";
    import EventPopup from "./Calendar/Monthly/EventPopup.vue";
    import EventModal from "./Calendar/Monthly/EventModal.vue";
    import SingleEventMonthModal from "./Calendar/Monthly/SingleEventModal.vue";
    import SingleEventMonthLink from "./Calendar/Monthly/SingleEventLink.vue";
    import SingleEventWeek from "./Calendar/Weekly/EventsWeek.vue";
    import ViewNav from "./Calendar/View-Nav.vue";
    
    export default {
        data() {
            return {
                activeDate: "",
                config: {
                    header: {
                        left: "prev",
                        center: "title",
                        right: "next",
                    },
                    customNavigationButtons: {
                        prev: {
                            text: "",
                            icon: "chevron-left",
                            click: () => {
                                this.$refs.calendar.getApi().prev();
                                if (this.view === "week") {
                                    this.activeDate = this.eventDate;
                                    this.weekNavDateShow(this.eventDate);
                                }
                                this.eventSearchDate = "";
                            },
                        },
                        next: {
                            text: "",
                            icon: "chevron-right",
                            click: () => {
                                this.$refs.calendar.getApi().next();
                                if (this.view === "week") {
                                    this.activeDate = this.eventDate;
                                    this.weekNavDateShow(this.eventDate);
                                }
                                this.eventSearchDate = "";
                            },
                        },
                    },
                    views: {},
                    // defaultView: "dayGrid", // Debugging Purposes
                    // defaultView: "timeGridWeek", // Debugging Purposes
                    defaultView: "dayGridMonth",
                    plugins: [
                        momentPlugin,
                        timeGridPlugin,
                        dayGridPlugin,
                        interactionPlugin,
                    ],
                    nextDayThreshold: "23:59:59",
                    editable: false,
                    eventLimitText: " events",
                    date: "",
                },
                filters: {
                    view: "month",
                    // view: "week", // Debugging Purposes
                    returnSingleDay: false,
                    date: {first: "", last: ""},
                    regions: [],
                    types: [],
                    search: "",
                    searchDate: "",
                },
                eventDate: null,
                events: [],
                eventSearchDate: "",
                eventStart: "",
                icons: {},
                isLoading: true,
                maxPage: 0,
                numOfEvents: 0,
                now: moment().format("YYYY-MM-DD"),
                optionsViews: {
                    monthly: {index: 1, title: "Monthly", value: "month", selected: true},
                    weekly: {index: 2, title: "Weekly", value: "week", selected: false},
                    // Debugging Week view
                    // monthly: {index: 1, title: "Monthly", value: "month", selected: false},
                    // weekly: {index: 2, title: "Weekly", value: "week", selected: true},
                },
                pageTitle: CAL.pageTitle,
                queriedDate: "",
                regions: [],
                types: [],
                selectedTypes: [],
                selectedRegions: [],
                view: "month",
                // view: "week", // Debugging Purposes
                visibleChildren: 0,
            };
        },
        created() {
            const getUrl = new URL(window.location);
            ICONS.close ? (this.icons.close = ICONS.close) : null;
            ICONS.filterIcon ? (this.icons.filter = ICONS.filterIcon) : null;
            ICONS.filterPlus ? (this.icons.filterPlus = ICONS.filterPlus) : null;
            this.eventDate = this.now;
            
            if (getUrl.search.length === 0) {
                if (this.view === "month") {
                    this.filters.date.first = moment()
                        .startOf("month")
                        .format("YYYY-MM-DD");
                    this.filters.date.last = moment().endOf("month").format("YYYY-MM-DD");
                }
                else if (this.view === "week") {
                    this.filters.date.first = moment().startOf("week").format("YYYY-MM-DD");
                    this.filters.date.last = moment().endOf("week").format("YYYY-MM-DD");
                    this.activeDate = this.eventDate;
                    this.weekNavDateShow(this.eventDate);
                }
            }
            
            /**
             * Our event bus to grab the event ID to open the modal for single events on
             * tablet and mobile
             */
            EventBus.$on("modal-event-id", (envId) => this.monthlyModal(envId));
            
            this.mediaQueryHandler();
            this.getTax(getUrl);
        },
        mounted() {
            if (window.history && window.history.pushState) {
                window.addEventListener("popstate", () => {
                    const hashLocation = location.hash;
                    const hashSplit = hashLocation.split("#!/");
                    const hashName = hashSplit[1];
                    if (hashName !== "") {
                        const hash = window.location.hash;
                        if (hash === "") {
                            this.selectedTypes = [];
                            this.selectedRegions = [];
                            this.queriedDate = "";
                            const getUrl = new URL(window.location);
                            this.isLoading = true;
                            this.getTax(getUrl, true);
                        }
                    }
                });
            }
            this.toggleSelectedDayHeader(this.now, $(".fc-today a"));
        },
        methods: {
            /**
             * This grabs out taxonomy, and is the first API call made on page load
             * @param getUrl Helps to pre-set filters and select items
             */
            getTax(getUrl = false) {
                const api =
                    CAL.taxApi && typeof CAL !== "undefined"
                    ? CAL.taxApi
                    : "wp-json/events/v1/taxonomy";
                
                this.isLoading = true;
                
                axios
                    .get(`${api}`)
                    .then(({data, status}) => {
                        if (status === 200) {
                            data.calTypes ? (this.types = data.calTypes) : null;
                            data.region ? (this.regions = data.region) : null;
                        }
                    })
                    .then(() => {
                        if (getUrl && getUrl.search.length > 0) {
                            let urlParams = getUrl.search.replace("?", "");
                            let searchParameters = new URLSearchParams(urlParams);
                            urlParams = urlParams.split("&");
                            setTimeout(() => {
                                urlParams.map((param) => this.checkParams(param));
                            }, 500);
                            setTimeout(() => {
                                let hasDate = searchParameters.has("date");
                                let paramDate = null;
                                
                                if (hasDate) paramDate = moment(searchParameters.get("date"), "MM/DD/YYYY").format("YYYY-MM-DD");
                                
                                this.updateFilters(hasDate, paramDate);
                                
                                this.calendarViewSwitch(
                                    this.view,
                                    this.eventSearchDate || this.now,
                                    hasDate
                                );
                            }, 500);
                        }
                        else this.getEvents(this.filters);
                    })
                    .catch((err) => console.error(err));
            },
            /**
             * This parses the loop of parameters from the address bar
             * @param param The param grabbed from the URL
             */
            checkParams(param) {
                const paramSplit = param.split("=");
                const paramType = paramSplit[0];
                const paramVal = paramSplit[1];
                let splitSlugs = paramVal.split(",");
                
                // Params for testing
                // ?format=administrative,informational&regions=buffalo,albany&search-date=2020-07-01&view=month
                
                /**
                 * This sets up the event format param
                 */
                if (paramType === "format") {
                    try {
                        const typesContainer = document.querySelector("[id=\"category-everything\"]");
                        const mapFormatSlugs = splitSlugs.map((type) => this.queryFormatFilters(type));
                        typesContainer ? typesContainer.classList.remove("active") : null;
                    } catch (e) {console.error(e);}
                }
                
                /**
                 * This sets up the event date param
                 */
                if (paramType === "search-date") {
                    try {const mapDateSlugs = this.queryDateFilters(paramVal, paramType);} catch (e) {console.error(e);}
                }
                
                /**
                 * This sets up the event date param
                 */
                if (paramType === "date") {
                    try {const mapDateSlugs = this.queryDateFilters(paramVal, paramType);} catch (e) {console.error(e);}
                }
                
                /**
                 * This sets up our region param
                 */
                if (paramType === "regions") {
                    try {const mapRegionSlugs = splitSlugs.map((region) => this.queryRegionFilters(region));} catch (e) {console.error(e);}
                }
                
                /**
                 * This sets up the calendar view
                 */
                if (paramType === "view") {
                    try {
                        if (paramVal === "month") {
                            this.optionsViews.monthly.selected = true;
                            this.optionsViews.weekly.selected = false;
                            this.view = "month";
                        }
                        else if (paramVal === "week") {
                            this.optionsViews.monthly.selected = false;
                            this.optionsViews.weekly.selected = true;
                            this.view = "week";
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }
                
                return true;
            },
            /**
             * Grabs the events from the API
             * @param filter This adjusts the data returned from the API
             * @param url    This is used to update the browser history when a user selects a filter
             */
            getEvents(filter, url = null) {
                // $("#calendar").addClass("is-loading");
                this.isLoading = true;
                
                this.events = {};
                /**
                 * Setup our API call
                 */
                const api = CAL.api;
                let config = {params: filter};
                
                axios
                    .get(`${api}`, config)
                    .then(({data, status}) => {
                        if (status === 200) {
                            this.events = data.hasOwnProperty("events") ? data.events : {};
                            this.maxPage = data.maxPages;
                        }
                    })
                    .then(() => {
                        this.numOfEvents = this.events.length || 0;
                        if (this.view === "month") {
                            /**
                             * Reset the classes for event-dates and the events array
                             */
                            const fcDay = document.querySelectorAll(".fc td.fc-day.has-events");
                            const fcToolbar = $(".fc-toolbar");
                            fcDay.forEach((elm) => $(elm).removeClass("has-events"));
                            /**
                             * Generates a legend on Tablet and Mobile
                             */
                            if (this.$mq.below(1025)) {
                                fcToolbar.find(".calendar-keys").remove();
                                const legend = Vue.extend(Legend);
                                const instance = new legend();
                                instance.$mount();
                                fcToolbar.append(instance.$el);
                            }
                            else fcToolbar.remove(".calendar-keys");
                            /**
                             * Removes the legend on desktop
                             */
                            
                            /**
                             * Replaces the single event listing with an indicator of a single event
                             */
                            // setTimeout(() => {
                            if (this.$resize && this.$mq.below(1025)) this.updateEventMonths();
                            // }, 250);
                        }
                        
                        if (this.view === "week") {
                            $(".fc-view .fc-body").hide();
                            if (this.$mq.above(1025)) this.checkForVisibleChildren();
                        }
                        
                        // setTimeout(() => $("#calendar").removeClass("is-loading"), 1000);
                        
                        if (url !== null) window.history.pushState({path: url}, "", url);
                    })
                    .catch((err) => console.error(err))
                    .finally(() => setTimeout(() => this.isLoading = false), 500);
            },
            calFilter(theEvent, elmTargetId) {
                let vals = [];
                $(`#${elmTargetId} .control`).each((elm) =>
                    $(elm).hasClass("active") ? vals.push($(elm).data("category")) : null
                );
                for (let i = 0; i < vals.length; i++) {
                    return vals.indexOf(theEvent.classNames[i]) === -1;
                }
            },
            eventRender(e) {
                const eventDate = e.event.start;
                const eventProps = e.event.extendedProps;
                let modalDate = moment(eventDate).format("MMMM DD, YYYY");
                
                // Add the classes to dates that have events
                this.eventStart = moment(eventDate).format("YYYY-MM-DD");
                this.toggleHasEvents(e);
                
                e.el.dataset.id = e.event.id;
                
                if (this.$mq.below(1025)) {
                    e.el.dataset.date = modalDate;
                    if (eventProps.hasOwnProperty("categoryName"))
                        eventProps.categoryName.map(({slug}, index) => $(e.el).attr(`data-cat${index}`, slug));
                    
                    return this.calFilter(e.event, "tablet-calendar-settings");
                }
                else if (this.$mq.above(1025) && !$(".filter-all").hasClass("active")) {
                    return this.calFilter(e.event, "desktop-calendar-settings");
                }
            },
            eventLimitCheck(e) {
                const eventArray = e.segs;
                const eventDate = moment(e.date).format("MMMM DD, YYYY");
                const eventModal = Vue.extend(EventModal);
                
                const instance = new eventModal({
                    propsData: {
                        eventDate: eventDate,
                        events: eventArray,
                        closeIcon: this.icons.close,
                    },
                });
                
                /**
                 * Mount the EventModal with the data provided and open the modal window
                 */
                instance.$mount();
                $("#modal .modal-events").html(instance.$el);
                $("#modal").show();
            },
            mediaQueryHandler(resized = false) {
                let changeDate = null;
                $("#calendar .fc-prev-button .prev-dates, #calendar .fc-next-button .next-dates").remove();
                this.fullCalendarConfigMediaQuery();
                
                if (resized) {
                    this.getEvents(this.filters);
                    this.calendarViewSwitch(this.view, changeDate);
                }
            },
            handleChange(e, grabEvents = true) {
                if (grabEvents === true) this.isLoading = true;
                
                let firstDate, lastDate;
                let calApi = this.$refs.calendar.getApi();
                
                this.filters.returnSingleDay = this.view === "week" && this.$mq.below(1025);
                
                // if (e.hasOwnProperty("view")) {
                if (calApi.view) {
                    const date = calApi.view.currentStart;
                    firstDate = moment(date).startOf(this.view).format("YYYY-MM-DD");
                    lastDate = moment(date).endOf(this.view).format("YYYY-MM-DD");
                    if (this.view === "week") {
                        if (this.$mq.above(1025)) {
                            this.toggleSelectedDayHeader(
                                moment(firstDate).add(1, "day"),
                                $(".fc-widget-header .fc-mon a")
                            );
                        }
                        /**
                         * @deprecated Not in use currently
                         */
                        else if (this.$mq.below(1025)) {
                            lastDate = firstDate = moment(date).format("YYYY-MM-DD");
                            this.activeDate = firstDate;
                            this.eventDate = firstDate;
                            this.eventStart = firstDate;
                        }
                    }
                }
                
                firstDate ? (this.filters.date.first = firstDate) : null;
                lastDate ? (this.filters.date.last = lastDate) : null;
                
                const url = this.buildUrlQuery("date", moment(firstDate).format("MM/DD/YYYY"));
                
                if (grabEvents === true) {
                    this.getEvents(this.filters, url);
                    this.toggleHasEvents(calApi);
                    this.checkForVisibleChildren();
                }
                
                if (!this.eventSearchDate) {
                    const url = this.buildUrlQuery("search-date", "remove");
                    window.history.pushState({path: url}, "", url);
                }
                
                /**
                 * @depreciated Might not be needed as of 07/30/2020, leaving for now
                 */
                // if (this.eventSearchDate) {
                //   setTimeout(() => {
                //     calApi.gotoDate(this.eventSearchDate);
                //     calApi.select(this.eventSearchDate);
                //     this.activeSearchDate(this.eventSearchDate);
                //   }, 500);
                // }
            },
            toggleHasEvents(eve) {
                const compareDate = this.eventStart;
                const date = eve.view.currentStart;
                
                if (this.view === "month") {
                    const fcDay = document.querySelectorAll(".fc td.fc-day");
                    const startOfRange = moment(date).startOf("month").format("YYYY-MM-DD");
                    const endOfRange = moment(date).endOf("month").format("YYYY-MM-DD");
                    
                    /**
                     * Adds a class to the days that have events
                     * @type {string}
                     */
                    if (compareDate !== undefined) {
                        fcDay.forEach((elm) => {
                            let elmDate = $(elm).data("date");
                            if (elmDate === compareDate && compareDate >= startOfRange && compareDate <= endOfRange) elm.classList.add("has-events");
                        });
                    }
                }
                
                if (this.view === "week" && this.$mq.below(1025) && this.eventDate !== this.eventStart)
                    this.eventDate = this.eventStart;
            },
            navWeekHeaderDayClick(date, jsEvent) {
                console.log(date);
                this.activeDate = moment(date).format("YYYY-MM-DD");
                const elm = $(jsEvent.target);
                let parent = elm.is("span") ? elm.parent() : elm.is("a") ? elm : null;
                
                this.checkForVisibleChildren();
                this.toggleSelectedDayHeader(date, parent, true);
            },
            toggleSelectedDayHeader(date, parent, navClicked = false) {
                if (parent !== null) {
                    /**
                     * Disable the clicking of the today's header
                     */
                    if (parent.hasClass("selected")) return false;
                    
                    this.eventDate = moment(date).format("YYYY-MM-DD");
                    
                    // navClicked ? this.eventSearchDate = this.eventDate : null;
                    navClicked ? (this.activeDate = this.eventDate) : null;
                    console.log(this.activeSearchDate());
                    console.log(this.eventDate);
                    
                    const navLinks = $(".fc-day-header").map((i, el) =>
                        $(el).find("a").removeClass("active-link selected")
                    );
                    
                    if (this.view === "month" && this.$mq.above(1025)) {
                        const tableHeader = $(parent).parents(".fc-day-top");
                        let dataDate = tableHeader[0].dataset.date;
                        if (dataDate === this.now && !$(".today-text").length) {
                            $(tableHeader).append("<span class=\"date-text today-text\">Today</span>");
                        }
                    }
                    
                    if (this.view === "week") $(parent).addClass("active-link selected");
                }
            },
            monthlyMouseEnter(e) {
                const elm = e.el;
                const elmEvent = e.event;
                const popup = Vue.extend(EventPopup);
                let cats;
                let region;
                
                if (elmEvent.extendedProps.hasOwnProperty("categoryName"))
                    cats = elmEvent.extendedProps.categoryName;

                if (elmEvent.extendedProps.hasOwnProperty("region"))
                    region = elmEvent.extendedProps.region;
                
                const instance = new popup({
                    propsData: {
                        title: elmEvent.title,
                        region: region,
                        cats: cats,
                    },
                });
                
                instance.$mount();
                
                const popupElm = $(instance.$el).appendTo("body");
                let offsetTop = $(elm).offset().top - ($(elm).height() + 110);
                let offsetLeft = $(elm).offset().left - $(elm).width()/2;
                popupElm.css({top: offsetTop + "px", left: offsetLeft + "px"});
                
                $(elm)
                    .mouseover(() => {
                        $(elm).css("z-index", 1000);
                        popupElm.fadeIn(500).fadeTo("10", 1.9);
                    })
                    .mousemove((e) => {
                        let $tooltip = $(".tooltip");
                        let offsetTop = e.pageY - ($tooltip.height() + 110);
                        let offsetLeft = e.pageX - $tooltip.width()/2;
                        $tooltip.css({top: offsetTop + "px", left: offsetLeft + "px"});
                    });
            },
            monthlyMouseLeave(e) {
                $(e.el).css("z-index", 8);
                $(".tooltip").remove();
            },
            fullCalendarConfigMediaQuery() {
                /**
                 * Tablet View
                 */
                if (this.$mq.between([768, 1025])) {
                    this.config.views = {
                        timeGridWeek: {
                            columnHeaderHtml: function(date) {
                                const today = this.now;
                                const formatDate = moment(date).format("YYYY-MM-DD");
                                const formatDayOfWeek = moment(date).format("D");
    
                                if (today === formatDate) {
                                    return `<span class="num">${formatDayOfWeek}</span><span class="day">Today</span>`;
                                }
                                return `<span class="num">${formatDayOfWeek}</span><span class="day">${moment(date)
                                    .format("dddd")}</span>`;
                            },
                            titleFormat: {
                                month: "long",
                                day: "numeric",
                            },
                        },
                        dayGridMonth: {
                            eventLimit: 1,
                            eventLimitText: (numEvents) => `${numEvents} events`,
                            columnHeaderFormat: {weekday: "short"},
                            titleFormat: {
                                month: "long",
                            },
                        },
                        timeGridDay: {},
                    };
                }
                else if (this.$mq.below(768)) {
                    /**
                     * Mobile View
                     */
                    this.config.views = {
                        timeGridWeek: {
                            columnHeaderFormat: {
                                day: "numeric",
                                weekday: "short",
                            },
                            titleFormat: {
                                month: "long",
                                day: "numeric",
                            },
                        },
                        dayGridMonth: {
                            eventLimit: 1,
                            eventLimitText: (numEvents) => `+ ${numEvents}`,
                            columnHeaderFormat: {weekday: "short"},
                            titleFormat: {
                                month: "long",
                            },
                        },
                        dayGrid: {
                            titleFormat: {
                                month: "long",
                                day: "numeric",
                            },
                        },
                    };
                }
                else if (this.$mq.above(1025)) {
                    /**
                     * Desktop View
                     */
                    this.config.views = {
                        timeGridWeek: {
                            columnHeaderHtml: function(date) {
                                const today = this.now;
                                const formatDate = moment(date).format("YYYY-MM-DD");
                                const formatDayOfWeek = moment(date).format("D");
    
                                if (today === formatDate) {
                                    return `<span class="num">${formatDayOfWeek}</span><span class="day">Today</span>`;
                                }
                                return `<span class="num">${formatDayOfWeek}</span><span class="day">${moment(date)
                                    .format("dddd")}</span>`;
                            },
                            titleFormat: {
                                month: "long",
                                day: "numeric",
                            },
                        },
                        dayGridMonth: {
                            eventLimit: 3,
                            eventLimitText: (numEvents) => `+ ${numEvents} more`,
                            columnHeaderFormat: {weekday: "long"},
                            titleFormat: {
                                month: "long",
                            },
                        },
                    };
                }
            },
            tooltipHide() {
                $(".tooltip").hide();
            },
            monthlyModal(envId) {
                /**
                 * This is used to create a modal for Tablet and Mobile Single Events
                 */
                const calApi = this.$refs.calendar.getApi();
                if (calApi !== undefined) {
                    const event = calApi.getEventById(envId);
                    const eventModal = Vue.extend(SingleEventMonthModal);
                    const instance = new eventModal({
                        propsData: {
                            events: event,
                            eventDate: moment(event.start).format("MMMM DD, YYYY"),
                            closeIcons: this.icons.close,
                        },
                    });
                    
                    instance.$mount();
                    $("#modal .modal-events").html(instance.$el);
                    $("#modal").show();
                }
            },
            checkForVisibleChildren() {
                this.visibleChildren = 0;
                const grabbedEvents = this.events;
                
                if (grabbedEvents.length > 0) {
                    grabbedEvents.forEach((event) => {
                        const eventStart = event.start;
                        const eventEnd = event.end;
                        
                        moment(eventStart).isSameOrBefore(this.activeDate) && moment(eventEnd)
                            .isSameOrAfter(this.activeDate) ? this.visibleChildren++ : null;
                        
                        // event.start === this.activeDate ? this.visibleChildren++ : null;
                    });
                }
                this.numOfEvents = this.visibleChildren;
            },
            weekNavDateShow(date) {
                const startLastWeek = moment(date).startOf("week").subtract(1, "w").format("YYYY-MM-DD");
                const endLastWeek = moment(date).endOf("week").subtract(1, "w").format("YYYY-MM-DD");
                const starttNextWeek = moment(date).startOf("week").add(1, "w").format("YYYY-MM-DD");
                const endNextWeek = moment(date).endOf("week").add(1, "w").format("YYYY-MM-DD");
                const prevWeek = this.dateConditional(startLastWeek, endLastWeek, "prev");
                const nextWeek = this.dateConditional(starttNextWeek, endNextWeek, "next");
                
                $(".fc-left .fc-button").append(prevWeek);
                $(".fc-right .fc-button").append(nextWeek);
            },
            dateConditional(startDate, endDate, className) {
                let dateReturn;
                const year = moment(endDate).isSame(startDate, "year");
                const month = moment(endDate).isSame(startDate, "month");
                
                if (year === true) {
                    if (!month) dateReturn = `${moment(startDate).format("MMMM D")} - ${moment(endDate).format("MMMM D")}`;
                    else dateReturn = `${moment(startDate).format("MMMM D")} - ${moment(endDate).format("D")}`;
                }
                else dateReturn = `${moment(startDate).format("MMMM D, YYYY")} - ${moment(endDate).format("MMMM D, YYYY")}`;
                
                return `<span class="${className}-dates">${dateReturn}</span>`;
            },
            activeSearchDate(date) {
                /**
                 * Add text to indicate it's searched for on Desktop
                 */
                if (this.view === "month") {
                    $(".fc-day-top .searched-text").remove();
                    if (this.$mq.above(1025)) {
                        $(`.fc-content-skeleton td[data-date="${date}"]`).append(
                            "<span class=\"date-text searched-text\">Searched</span>"
                        );
                    }
                }
                else if (this.view === "week") {
                    /**
                     * Add text above header to indicate it's search for
                     */
                    if (moment(this.eventSearchDate).isBetween(this.filters.date.first, this.filters.date.last)) {
                        $(".fc-searched").removeClass("fc-searched");
                        $(`.fc-widget-header th[data-date="${date}"]`).addClass(
                            "fc-searched"
                        );
                    }
                }
            },
            /**
             * The method to add/remove category for our filters
             * @param elm The element in which we need to grab attributes from
             */
            searchTypes(elm) {
                const calendarApi = this.$refs.calendar.getApi();
                let el;
                
                if (elm.target.tagName === "A") el = elm.target.parentNode;
                else el = elm.target;
                
                const cat = el.dataset.cat;
                let name = el.dataset.formatSlug;
                let removeType = false;
                
                if (name === "all" || name === "all-tablet-regions") {
                    removeType = true;
                    name = "remove";
                    this.filters.types = [];
                }
                else {
                    if (el.classList.contains("active") || elm.target.checked) this.filters.types.push(cat);
                    else if (this.filters.types.indexOf(cat > -1)) {
                        removeType = true;
                        this.filters.types.splice(this.filters.types.indexOf(cat), 1);
                    }
                }
                
                /**
                 * Handlers for month view filtering
                 * Need to reinstantiate the calendar classes
                 */
                if (this.view === "month") this.eventStart = calendarApi.view.eventStart || this.eventStart;
                
                const newUrl = this.buildUrlQuery("format", name, removeType);
                this.getEvents(this.filters, newUrl);
            },
            /**
             * The method to add/remove regions for our filters
             * @param elm The element in which we need to grab attributes from
             */
            searchRegions(elm) {
                const calendarApi = this.$refs.calendar.getApi();
                const el = elm.target;
                let name = el.dataset.regionSlug;
                let removeType = false;
                
                if (name === "all-regions" || name === "all-tablet-regions") {
                    removeType = true;
                    name = "remove";
                    this.filters.regions = [];
                }
                else {
                    let termId = el.dataset.regionTerm;
                    // Adds the term to the regions array for API
                    if (el.checked === true) this.filters.regions.push(termId);
                    else if (this.filters.regions.indexOf(termId > -1)) {
                        // Removes the term from the regions array
                        removeType = true;
                        this.filters.regions.splice(this.filters.regions.indexOf(termId), 1);
                    }
                }
                
                /**
                 * Handlers for month view filtering
                 * Need to reinstantiate the calendar classes
                 */
                if (this.view === "month")
                    this.eventStart = calendarApi.view.eventStart ? calendarApi.view.eventStart : this.eventStart;
                const newUrl = this.buildUrlQuery("regions", name, removeType);
                
                this.getEvents(this.filters, newUrl);
            },
            /**
             * Our date search
             * @param e the elm
             */
            dateSearch(e) {
                const calendarApi = this.$refs.calendar.getApi();
                let removeType = false;
                let f = {view: {}};
                let date;
                
                if (e.hasOwnProperty("date")) date = e.date;
                else date = e.view.currentStart;
                this.eventSearchDate = moment(date).format("YYYY-MM-DD");
                this.filters.searchDate = this.eventSearchDate;
                
                if (this.view === "month") {
                    f.view.currentStart = moment(date).startOf("month");
                    f.view.currentEnd = moment(date).endOf("month");
                }
                else if (this.view === "week") {
                    f.view.currentStart = moment(date).startOf("week").format("YYYY-MM-DD");
                    f.view.currentEnd = moment(date).endOf("week").format("YYYY-MM-DD");
                    
                    if (this.$mq.above(1025)) {
                        setTimeout(() => {
                            let parent = $(".fc-timeGrid-view .fc-widget-header tr").find(`[data-date="${this.eventSearchDate}"] a`);
                            this.toggleSelectedDayHeader(this.eventSearchDate, parent, true);
                        }, 250);
                    }
                }
                
                if (this.view === "month" || this.view === "week") this.handleChange(f, false);
                
                calendarApi.gotoDate(this.eventSearchDate);
                calendarApi.select(this.eventSearchDate);
                
                this.activeSearchDate(this.eventSearchDate);
                
                const url = this.buildUrlQuery("search-date", moment(this.eventSearchDate).format("MM/DD/YYYY"), removeType);
                window.history.pushState({path: url}, "", url);
            },
            calendarViewSwitch(view, changeDate, getEvents = true) {
                if (!changeDate) changeDate = this.eventSearchDate || this.now;
                let calendarApi = this.$refs.calendar.getApi();
                
                this.fullCalendarConfigMediaQuery();
                
                if (view === "month") {
                    this.view = "month";
                    this.filters.view = "month";
                    this.optionsViews.monthly.selected = true;
                    this.optionsViews.weekly.selected = false;
                    calendarApi.changeView("dayGridMonth");
                }
                else if (view === "week") {
                    this.activeDate = changeDate;
                    this.view = "week";
                    this.filters.view = "week";
                    this.optionsViews.monthly.selected = false;
                    this.optionsViews.weekly.selected = true;
                    
                    getEvents ? this.getEvents(this.filters) : null;
                    
                    if (this.$mq.above(1025)) {
                        calendarApi.changeView("timeGridWeek");
                        setTimeout(() => {
                            const parent = $(".fc-timeGrid-view .fc-widget-header tr").find(`[data-date="${changeDate}"] a`);
                            
                            this.toggleSelectedDayHeader(changeDate, parent, true);
                            this.activeSearchDate(changeDate);
                            this.weekNavDateShow(changeDate);
                        }, 250);
                    }
                    else if (this.$mq.below(1025)) calendarApi.changeView("dayGrid");
                }
                
                const newUrl = this.buildUrlQuery("view", this.view);
                this.getEvents(this.filters, newUrl);
            },
            resetAll() {
                const calendarApi = this.$refs.calendar.getApi();
                const date = calendarApi.view.currentStart;
                this.filters.regions = [];
                this.filters.types = [];
                // this.filters.returnSingleDay = this.$mq.below(1025);
                this.filters.search = "";
                this.filters.searchDate = "";
                this.eventSearchDate = "";
                this.activeDate = this.now;
                this.selectedRegions = [];
                
                this.config = {
                    header: {
                        left: "prev",
                        center: "title",
                        right: "next",
                    },
                    customNavigationButtons: {
                        prev: {
                            text: "",
                            icon: "chevron-left",
                            click: () => {
                                this.$refs.calendar.getApi().prev();
                                if (this.view === "week") this.activeDate = this.eventDate;
                            },
                        },
                        next: {
                            text: "",
                            icon: "chevron-right",
                            click: () => {
                                this.$refs.calendar.getApi().next();
                                if (this.view === "week") this.activeDate = this.eventDate;
                            },
                        },
                    },
                    views: {},
                    defaultView: "dayGridMonth",
                    plugins: [
                        momentPlugin,
                        timeGridPlugin,
                        dayGridPlugin,
                        interactionPlugin,
                    ],
                    nextDayThreshold: "23:59:59",
                    editable: false,
                    eventLimitText: " events",
                    date: "",
                };
                
                let unitOfTime;
                if (this.view === "month") unitOfTime = "month";
                else if (this.view === "week") unitOfTime = "week";
                
                this.filters.date = {
                    first: moment(date).startOf(unitOfTime).format("YYYY-MM-DD"),
                    last: moment(date).endOf(unitOfTime).format("YYYY-MM-DD"),
                };
                
                this.eventStart = calendarApi.view.eventStart ? calendarApi.view.eventStart : this.eventStart;
                
                const newUrl = this.buildUrlQuery("reset-all", null);
                
                this.calendarViewSwitch("month");
                calendarApi.gotoDate(this.now);
                
                this.getEvents(this.filters, newUrl);
            },
            updateEventMonths() {
                document
                    .querySelectorAll(".fc-dayGridMonth-view .fc-row .fc-content-skeleton tr:not(.fc-limited) td.fc-event-container:not(.fc-limited)")
                    .forEach((elm) => {
                        /**
                         * Creation of our single event replacement link on tablet and mobile
                         */
                        
                        const _this = $(elm);
                        const envId = _this.find("a").data("id");
                        const SingleLink = Vue.extend(SingleEventMonthLink);
                        const instance = new SingleLink({
                            propsData: {
                                text: this.$mq.between([768, 1024]) ? "1 event" : (this.$mq.below(768) ? "+1" : null),
                                envId: envId,
                            },
                        });
                        
                        _this.find(".single-event.fc-div").remove();
                        
                        instance.$mount();
                        _this.append(instance.$el);
                    });
            },
            updateFilters(goToDate = false, date) {
                let startOf;
                if (this.eventSearchDate) {
                    const startDate = moment(this.eventSearchDate).startOf(this.view).format("YYYY-MM-DD");
                    const endDate = moment(this.eventSearchDate).endOf(this.view).format("YYYY-MM-DD");
                    
                    this.filters.date.first = startDate;
                    this.filters.date.last = endDate;
                }
                
                /**
                 * If the date is set in the url, we need to trigger the calendar to go to that day
                 */
                if (goToDate && date) {
                    this.isLoading = true;
                    this.$refs.calendar.getApi().gotoDate(date);
                }
            },
            queryFormatFilters(slug) {
                const formatElm = document.querySelector(`[data-format-slug="${slug}"]`);
                if (formatElm) {
                    if (this.$mq.above(1024)) formatElm.classList.add("active");
                    else if (this.$mq.below(1025)) formatElm.checked = true;
                    
                    this.filters.types.push(formatElm.dataset.cat);
                    this.selectedTypes.push(formatElm.dataset.formatSlug);
                }
            },
            queryRegionFilters(slug) {
                const regionElm = document.querySelector(`[data-region-slug="${slug}"]`);
                if (regionElm) {
                    regionElm.checked = true;
                    this.filters.regions.push(regionElm.dataset.regionTerm);
                    this.selectedRegions.push(regionElm.dataset.name);
                    return true;
                }
                else return false;
            },
            queryDateFilters(value, type) {
                let format = "YYYY-MM-DD";
                if (value.indexOf("/") !== -1) format = "MM/DD/YYYY";
                
                const formatedVal = moment(value, format).format("YYYY-MM-DD");
                
                if (type === "search-date") this.queriedDate = this.eventSearchDate = this.filters.searchDate = formatedVal;
                else if (type === "date") this.filters.searchDate = this.eventSearchDate = formatedVal;
            },
            buildDateRange(value, paramType, searchParameters) {
                let newParamVal;
                let format = "YYYY-MM-DD";
                
                if (value.indexOf("/") !== -1) format = "MM/DD/YYYY";
                
                value !== "remove" ? (newParamVal = moment(value, format).format("MM/DD/YYYY")) : null;
                
                if (newParamVal) searchParameters.set(paramType, newParamVal);
                else searchParameters.delete(paramType);
                
                return searchParameters;
            },
            /**
             * This method builds our new URL query params
             * @param paramType  The param type we need to set in the URL
             * @param value      The param value we need to set to the type
             * @param remove     Whether we need to remove the param or not
             * @returns {string} Returns the new URL for the browser history
             */
            buildUrlQuery(paramType = "", value = "", remove = false) {
                if (paramType === null) return;
                
                // Params for testing
                //?format=administrative,informational&regions=buffalo,albany&search-date=2020-07-01&view=month
                
                let windowUrl = new URL(window.location);
                let searchParameters = windowUrl.search.replace("?", "");
                searchParameters = new URLSearchParams(searchParameters);
                
                // See if there are search params at all
                if (value === "remove") searchParameters.delete(paramType);
                else if (windowUrl.search.length) {
                    if (searchParameters.has(paramType)) {
                        let existingVals = searchParameters.get(paramType);
                        
                        if (paramType === "reset-all") searchParameters = [];
                        else if (value.length && paramType === "search-date") this.buildDateRange(value, paramType, searchParameters);
                        else if (value.length && paramType === "date") this.buildDateRange(value, paramType, searchParameters);
                        else if (value.length && (paramType !== "search-date" || paramType !== "date")) {
                            if (!remove) {
                                if (paramType === "view") existingVals = value;
                                else existingVals = `${existingVals},${value}`;
                                
                                existingVals = Array.from(new Set(existingVals.split(","))).toString();
                                searchParameters.set(paramType, existingVals);
                            }
                            else {
                                existingVals = existingVals.split(",");
                                
                                if (existingVals.length > 1) {
                                    const elmIndex = existingVals.indexOf(value);
                                    if (elmIndex > -1) existingVals.splice(elmIndex, 1);
                                    
                                    searchParameters.set(paramType, existingVals.toString());
                                }
                                else searchParameters.delete(paramType);
                            }
                        }
                        else searchParameters.delete(paramType);
                    }
                    else if (paramType === "reset-all") searchParameters = [];
                    else {
                        if (value.length && !remove) searchParameters.append(paramType, value);
                        else searchParameters.delete(paramType);
                    }
                }
                else {
                    if (value.length && paramType === "search-date") this.buildDateRange(value, paramType, searchParameters);
                    else if (value.length && paramType === "date") this.buildDateRange(value, paramType, searchParameters);
                    else if (paramType === "reset-all") searchParameters = [];
                    else {
                        if (!remove && value.length) searchParameters.append(paramType, value);
                        else searchParameters.delete(paramType);
                    }
                }
                
                searchParameters = searchParameters.toString();
                searchParameters = searchParameters.length ? `?${decodeURIComponent(searchParameters)}` : ``;
                return `${windowUrl.origin + windowUrl.pathname + searchParameters}`;
            },
            columnHeaderFn(date) {
            
            }
        },
        watch: {
            eventDate() {
                if (this.$mq.below(1025)) {
                    this.activeDate = this.eventDate;
                    this.checkForVisibleChildren();
                }
            },
        },
        components: {
            Title,
            EventTypes,
            Filters,
            TabletFilters,
            Legend,
            FullCalendar,
            EventPopup,
            EventModal,
            SingleEventMonthModal,
            SingleEventMonthLink,
            SingleEventWeek,
            ViewNav,
        },
        name: "Calendar",
    };
</script>

<style lang="scss">
    @media (min-width: 1024px) {
        .prev-dates {
            left: 80px;
        }
        .next-dates {
            right: 80px;
        }
    }
</style>
