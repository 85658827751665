var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "tablet-user-information-card" } }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12" }, [
        _c("div", { staticClass: "user-information-card" }, [
          _c("div", { staticClass: "user-information-card-content" }, [
            _c("div", { staticClass: "loading member-card-loading" }),
            _vm._v(" "),
            _vm.data !== null && !_vm.loading
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-xs-3" }, [
                    _vm.data.profileImage
                      ? _c("div", {
                          staticClass: "user-information-card-avatar",
                          domProps: {
                            innerHTML: _vm._s(_vm.data.profileImage),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xs-9" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-xs-6" }, [
                        _c(
                          "div",
                          {
                            staticClass: "user-card-information-name-container",
                          },
                          [
                            _c("p", { staticClass: "label" }, [_vm._v("Name")]),
                            _vm._v(" "),
                            _c("h3", {
                              domProps: { textContent: _vm._s(_vm.data.name) },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xs-6" }, [
                        _c(
                          "div",
                          { staticClass: "user-information-card-edit" },
                          [
                            _c("ul", [
                              _vm.icons.editPhoto && _vm.editLinks.editPhoto
                                ? _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.editLinks.editPhoto,
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.icons.editPhoto,
                                            alt: "Edit Photo",
                                          },
                                        }),
                                        _vm._v("Edit profile photo"),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.icons.editInfo && _vm.editLinks.editInfo
                                ? _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: _vm.editLinks.editInfo },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.icons.editInfo,
                                            alt: "Edit Information",
                                          },
                                        }),
                                        _vm._v("Edit information"),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "refresh-button",
                                    attrs: { disabled: !_vm.allowUpdate },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.$emit("refreshMemberData")
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "icon" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.icons.refreshData,
                                          alt: "Refresh Data",
                                        },
                                      }),
                                    ]),
                                    _c("span", { staticClass: "text" }, [
                                      _vm._v("Refresh Data"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.timeUpdate
                                  ? _c("p", { staticClass: "description" }, [
                                      _vm._v("Next time you can update: "),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(_vm.timeUpdate),
                                        },
                                      }),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data !== null && !_vm.loading
              ? _c("div", { staticClass: "user-information-details" }, [
                  _c("div", { staticClass: "row" }, [
                    _vm.data.nysbaID
                      ? _c("div", { staticClass: "col-xs-6" }, [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("Member ID"),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            domProps: { textContent: _vm._s(_vm.data.nysbaID) },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.memberType
                      ? _c("div", { staticClass: "col-xs-6" }, [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("Member Type"),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            domProps: {
                              textContent: _vm._s(_vm.data.memberType),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.memberStatus
                      ? _c("div", { staticClass: "col-xs-6" }, [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("Member Status"),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            domProps: {
                              textContent: _vm._s(_vm.data.memberStatus),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.memberSince
                      ? _c("div", { staticClass: "col-xs-6" }, [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("Member Since"),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            domProps: {
                              textContent: _vm._s(_vm.data.memberSince),
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.renewalDate
                      ? _c("div", { staticClass: "col-xs-6" }, [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("Renewal Date"),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            domProps: {
                              textContent: _vm._s(_vm.data.renewalDate),
                            },
                          }),
                          _vm._v(" "),
                          _vm.data.renewMembership
                            ? _c(
                                "a",
                                {
                                  staticClass: "line-btn blue",
                                  attrs: { href: _vm.data.renewalLink },
                                },
                                [_c("span", [_vm._v("Renew membership")])]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.email
                      ? _c("div", { staticClass: "col-xs-6" }, [
                          _c("p", { staticClass: "label" }, [_vm._v("Email")]),
                          _vm._v(" "),
                          _c("p", {
                            domProps: { textContent: _vm._s(_vm.data.email) },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.company
                      ? _c("div", { staticClass: "col-xs-6" }, [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("Organization"),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            domProps: { textContent: _vm._s(_vm.data.company) },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.title
                      ? _c("div", { staticClass: "col-xs-6" }, [
                          _c("p", { staticClass: "label" }, [_vm._v("Title")]),
                          _vm._v(" "),
                          _c("p", {
                            domProps: { textContent: _vm._s(_vm.data.title) },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.location
                      ? _c("div", { staticClass: "col-xs-6" }, [
                          _c("p", { staticClass: "label" }, [
                            _vm._v("Location"),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            domProps: {
                              textContent: _vm._s(_vm.data.location),
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }