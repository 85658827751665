<template>
	<ul v-if="socialMedia">
		<li v-for="(sm) in socialMedia">
            <SocialMediaLinkIcon
                v-if="sm.smType === 'sm'"
                :icon="sm.icon"
                :link="sm.link"
                :title="sm.title"
                />
            <PrintLink
                v-else-if="sm.smType === 'print'"
                :icon="sm.icon"
                :title="sm.icon" />
		</li>
	</ul>
</template>

<script>
    import SocialMediaLinkIcon from "./Social-Media/SocialMediaLinkIcon.vue";
    import PrintLink from "./Social-Media/PrintLink.vue";
    export default {
        components: {PrintLink, SocialMediaLinkIcon},
        data() {
            return {
                socialMedia: {},
            };
        },
        beforeMount() {
            if (typeof SOCIAL_MEDIA !== "undefined") {
                this.setupSM();
            }
        },
        methods: {
            setupSM() {
                const sm = SOCIAL_MEDIA.social_media;
                const title = SOCIAL_MEDIA.title;
                const excerpt = SOCIAL_MEDIA.excerpt;
                const permalink = SOCIAL_MEDIA.permalink;
                if (sm) {
                    $.each(sm, (key, val) => {
                        let link;
                        let smType = 'sm';
                        this.socialMedia[key] = {
                            title: val.title.replace(/^[a-z]/, m => m.toUpperCase()),
                            icon: val.icon,
                        };
                        switch (key) {
                            case "facebook":
                                link = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(permalink)}`;
                                break;
                            case "twitter":
                                link = `https://twitter.com/intent/tweet?url=${permalink}&text=${title}`;
                                break;
                            case "linkedin":
                                link = `https://www.linkedin.com/shareArticle?mini=true&url=${permalink}&title=${title}`;
                                break;
                            case "email":
                                link = `mailto:?subject=NYSBA Article - ${title}&body=${excerpt}%0A%0A${permalink}`;
                                break;
                            case "print":
                                smType = 'print';
                                break;
                            default:
                                break;
                        }
                        link ? this.socialMedia[key].link = link : null;
                        this.socialMedia[key].smType = smType;
                    });
                }
            },
        },
        name: "SocialMedia"
    };
</script>

<style scoped>

</style>
