<template>
    <div :class="`events-list products-list${!posts.length && !loading ? ' no-posts' : ''}`"
         ref="eventsProducts">
        <h2 v-html="title" />
        <div :class="`loading ${loading ? ' is-loading' : ''}`" />
        
        <Post v-if="showPosts.length && !loading"
              v-for="({date, excerpt, id, image, region, registerLink, permalink, title, type}) in showPosts"
              :key="id"
              :date="date"
              :excerpt="excerpt"
              :image="image"
              :link-text="getLinkText(type)"
              :register-link="registerLink"
              :region="region"
              :permalink="permalink"
              :title="title" />
    
        <Paginate v-if="!loading && maxPages > 1"
                  :page-count="maxPages"
                  :page-range="3"
                  :force-page="currentPage"
                  :value="currentPage"
                  :margin-pages="1"
                  :click-handler="gotoPage"
                  :prev-text="''"
                  :next-text="''"
                  :container-class="'bottom-pagination pagination'"
                  :page-link-class="'page-numbers'"
                  :next-link-class="'next'"
                  :prev-link-class="'prev'"
                  :active-class="'current'"
                  :break-view-link-class="'dots'"
                  :no-li-surround="true"
        />
    </div>
</template>

<script type="application/javascript">
    /**
     * Vue Scripts
     */
    import axios from "axios";

    /**
     * Components
     */
    import Paginate from "vuejs-paginate";
    import Post from "./EventsProductsListing/EventProductPost.vue";
    
    export default {
        props: {
            index: [Number,String],
            eventLinkText: [Boolean, String],
            order: [Number,String],
            ppp: [Number,String],
            postid: [Number, String],
            productLinkText: [Boolean, String],
            title: String,
        },
        data: () => ({
            api: NYSBA.eventsProductsApi,
            arrEnd: 0,
            arrStart: 0,
            currentPage: 1,
            loading: true,
            maxPages: 0,
            paged: 1,
            posts: [],
            postsPerPage: 0,
            showPosts: [],
        }),
        beforeCreate() {},
        created() {},
        beforeMount() {
            this.postsPerPage = parseInt(this.ppp);
            this.arrEnd = this.postsPerPage;
            this.fetchPosts();
        },
        mounted() {},
        methods: {
            fetchPosts() {
                axios
                    .get(
                        `${this.api}/${this.index}`,
                        {
                            params: {
                                order: this.order,
                                postId: this.postid
                            }
                        }
                    )
                    .then(({data, status}) => {
                        if (status === 200) {
                            this.posts = data;
                            this.maxPages = Math.ceil(data.length / this.postsPerPage);
                            this.slicePosts()
                        }
                    })
                    .then(() => {
                        this.loading = false
                    })
                    .catch(err => console.log(err));
            },
            gotoPage(pageNum) {
                if ((event !== undefined) && (event.currentTarget.classList.contains("page-numbers"))
                    || (event.currentTarget.classList.contains("next")
                        || event.currentTarget.classList.contains("prev"))
                ) {
                    $("html, body").animate(
                        {
                            scrollTop: $(this.$refs.eventsProducts).offset().top - 100
                        },
                        1000
                    );
                }
                
                this.slicePosts(pageNum);
            },
            slicePosts(pageNum = 1) {
                if (pageNum === 1) {
                    this.arrStart = 0;
                    this.arrEnd = this.postsPerPage;
                } else {
                    this.arrStart = (pageNum - 1) * this.postsPerPage;
                    this.arrEnd = pageNum * this.postsPerPage
                }
                this.loading = true;
                this.showPosts = Object.values(this.posts)
                                        .slice(this.arrStart, this.arrEnd);
                this.loading = false;
                this.currentPage = pageNum;
            },
            getLinkText(type) {
                if (type) {
                    if (type === 'event') {
                        return this.eventLinkText.length && this.eventLinkText;
                    } else if (type === 'product') {
                        return this.productLinkText.length && this.productLinkText;
                    }
                }
                return '';
            }
        },
        computed: {},
        watch: {},
        components: {Post, Paginate},
        name: "EventsProducts"
    };
</script>

<style lang="scss" scoped>
    .events-list {
        position: relative;
        &:not(.no-posts) {
            min-height: 25vh;
            .is-loading {
                display: block !important;
            }
        }
    }
</style>
