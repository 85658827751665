var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "my-sections" } }, [
    _c("div", { staticClass: "line-header-container" }, [
      _c("div", { staticClass: "line-header" }, [
        _c("h2", { domProps: { textContent: _vm._s(_vm.blockTitle) } }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "checkered-item-container" },
      [
        _c("div", { staticClass: "loading section-loading" }),
        _vm._v(" "),
        _vm.sections && !_vm.loading
          ? _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.sections, function ({ title, link }) {
                return _c("div", { staticClass: "col-xs-4" }, [
                  _c("div", { staticClass: "checkered-item" }, [
                    _c("a", {
                      attrs: { href: link },
                      domProps: { textContent: _vm._s(title) },
                    }),
                  ]),
                ])
              }),
              0
            )
          : !_vm.loading
          ? _c("NoResults", { attrs: { "no-results-text": _vm.notFoundText } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }