<template>
    <div class="helpful-links-slider__container">
        <div class="swiper-container" id="swiper-posts-block">
            <div class="swiper-wrapper">
                <!-- Slide -->
                <div v-for="(post, index) in posts"
                     class="swiper-slide"
                     :key="index">
                     <div class="helpful-links-slider__slide">
                        <Post :post-class="postClass"
                              :credits="post.credits"
                              :date="post.date"
                              :format="post.format"
                              :image="post.image"
                              :permalink="post.permalink"
                              :start-time="post.startTime"
                              :title="post.title" />
                    </div>
                </div>
            </div>
        </div>
        <div class="helpful-links-slider__nav">
			<div class="nav-prev" id="posts-block-prev" tabindex="0" role="button" aria-label="Previous slide">
				<span class="arrow">&#10094;</span> <span class="copy">Previous</span>
			</div>
			<div class="nav-pagination swiper-pagination-bullets" id="posts-block-pagination"><span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span></div>
			<div class="nav-next" id="posts-block-next" tabindex="0" role="button" aria-label="Next slide">
				<span class="copy">Next</span> <span class="arrow">&#10095;</span>
			</div>
		</div>
    </div>
</template>

<script type="application/javascript">
    import Post from "../Blocks/Post.vue";
    import Swiper from "swiper";

    export default {
        props: {
            err: Boolean,
            postClass: String,
            posts: [Array, Object],
        },
        data() {
            return {
            };
        },
        mounted() {
            /**
             * Swiper instantiator
             *
             * API Document
             * @link https://github.com/nolimits4web/Swiper/blob/Swiper4/API.md
             * @type {Swiper}
             */
            const swiper = new Swiper('#swiper-posts-block', {
                spaceBetween: 0,
                slidesPerView: 1,
                loop: true,
                pagination: {
                    el: '#posts-block-pagination',
                },
                navigation: {
                    nextEl: '#posts-block-next',
                    prevEl: '#posts-block-prev',
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }
            });
            const swiperElm = document.getElementById('swiper-posts-block');
            swiperElm.addEventListener('mouseenter', () => swiper.autoplay.stop());
            swiperElm.addEventListener('mouseleave', () => swiper.autoplay.start());
            
            
        },
        name: "SliderBlock",
        components: {Post}
    };
</script>

<style scoped>

</style>
