var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      attrs: {
        href: _vm.link !== undefined ? _vm.link : null,
        "aria-label": `Share to ${_vm.title}`,
        target: "_blank",
      },
    },
    [
      _c("img", {
        staticClass: "svg",
        attrs: { src: _vm.icon, alt: _vm.title + " Icon" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }