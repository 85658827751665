<template>

	<div>
		<Search
			:types="typeTerms"
			:pas="paTerms"
			:search="search"
			:selectedTypes="filters.types"
			:selectedPas="filters.sortingType"
			@buildTypes="buildTypes"
			@buildPas="buildPas"
			@buildAllTypes="buildAllTypes"
			@buildAllPas="buildAllPas"
			@buildSearch="buildSearch"
			@resetAll="resetAll"
		/>

		<section id="listing-top" class="advanced-search-results">

			<Results v-if="$resize && $mq.above(1025)"
			         :posts="posts" />

			<ResultsTablet v-else-if="$resize && $mq.below(1025)"
			               :posts="posts" />

			<div v-if="maxPage > 1"
			     class="row">
				<div class="col-xs-12">
					<Pagination
						:page-count="maxPage"
						:page-range="3"
						:force-page="currentPage"
						:margin-pages="1"
						:click-handler="gotoPage"
						:prev-text="''"
						:next-text="''"
						:container-class="'pagination'"
						:page-link-class="'page-numbers'"
						:next-link-class="'next'"
						:prev-link-class="'prev'"
						:active-class="'current'"
						:break-view-link-class="'dots'"
						:no-li-surround="true"
					/>
				</div>
			</div>
		</section>

	</div>

</template>

<script>
    import axios from "axios";
    import Search from "./Committee-Listing/CommitteeSearch.vue";
    import Results from "./Committee-Listing/CommitteeResults.vue";
    import ResultsTablet from "./Committee-Listing/CommitteeResultsTablet.vue";
    import Pagination from "vuejs-paginate";

    export default {
        data() {
            return {
                posts: [],
                typeTerms: [],
                paTerms: [],
                page: 1,
                currentPage: 0,
                maxPage: 0,
                resultsQty: 0,
                filters: {
                    types: [],
                    sortingType: ''
                },
                search: "",
            };
        },
        created() {
            this.page = LISTING.page !== undefined ? LISTING.page : 1;
            this.getTax();
        },
        mounted() {
            setTimeout(() => this.committeeListing(this.page, this.filters), 1000);
        },
        methods: {
            getTax() {
                const api = (LISTING.taxApi && typeof LISTING !== "undefined") ? LISTING.taxApi : "wp-json/committees/v1/taxonomy";
                const getUrl = (new URL(window.location));
                axios.get(`${api}`)
                     .then(({data, status}) => {
                         if (status === 200) {
                             (data.typeTerms) ? this.typeTerms = data.typeTerms : null;
                             (data.paTerms) ? this.paTerms = data.paTerms : null;
                         }
                     })
                     .then(() => {
                         if (getUrl.search.length > 0) this.checkParams(getUrl);
                         else this.defaultListing();
                     })
                     .catch(err => console.log(err));
                return;
            },
            checkParams(getUrl) {
                if (getUrl.searchParams.has("type")) {
                    setTimeout(() => {
                        const formatSlug = getUrl.searchParams.get("type");
                        const splitSlugs = formatSlug.split(",");
                        const typeFormats = $("#type-dropdown");
                        const mapSlugs = splitSlugs.map(type => this.queryFilters(type));

                        typeFormats.find("a").addClass("open");
                        typeFormats.find(".sub-filters").slideDown(400);
                        this.committeeListing(1, this.filters);
                        return;
                    }, 750);
                }
            },
            defaultListing() {
                setTimeout(() => {
                    const formatSlug = "";
                    const typeFormats = $("#type-dropdown");
                    this.queryFilters(formatSlug);

                    typeFormats.find("a").addClass("open");
                    typeFormats.find(".sub-filters").slideDown(400);

                    this.committeeListing(1, this.filters);
                    return;
                }, 750);
            },
            committeeListing(page, filters) {
                // let api = (filters) ? LISTING.filterApi : LISTING.api;
                let api = LISTING.filterApi;
                let config;
                const filterParams = !filters ? filters : this.filters;
                const loading = $(".loading");
                const postContainer = $(".post-listing");

                this.posts = [];
                config = {params: filters};

                axios.get(`${api + page}`, config)
                     .then(({data, status}) => {
                         if (status === 200) {
                             this.resultsQty = data.total;
                             this.posts = data.posts;
                             this.maxPage = data.maxPages;
                         }
                     })
                     .catch(err => console.log(err));
                return;
            },
            gotoPage(pageNum) {
                if ((event !== undefined)) {
                    if (event.currentTarget.classList.contains("page-numbers") || event.currentTarget.classList.contains("next") || event.currentTarget.classList.contains("prev")) {
                        $("html, body").animate({
                            scrollTop: $("#listing-top").offset().top
                        }, 1000);
                    }
                }
                this.currentPage = pageNum;
                //const newurl = `${window.location.protocol}//${window.location.hostname + window.location.pathname}?get_page=${pageNum}`;
                //window.history.pushState({path: newurl}, "", newurl);

                this.committeeListing(pageNum, this.filters);
            },
            buildTypes(elm) {
                this.removeAll("#type-dropdown input#all-types");
                if (elm.checked) this.filters.types.push(elm.value);
                else if (this.filters.types.indexOf(elm.value) > -1)
                    this.filters.types.splice(this.filters.types.indexOf(elm.value), 1);

                this.gotoPage(1);
            },
            buildPas(elm) {
                this.removeAll("#pa-dropdown input#all-practice-areas");
                if (elm.checked) this.filters.sortingType = elm.value;
                else if (this.filters.sortingType.indexOf(elm.value) > -1)
                    this.filters.sortingType.splice(this.filters.sortingType.indexOf(elm.value), 1);

                this.gotoPage(1);
            },
            buildSearch(searchText) {
                searchText ? this.filters.searchText = searchText : this.filters.searchText = "";
                searchText ? this.search = searchText : this.search = "";
                this.gotoPage(1);
            },
            buildAllTypes() {
                this.allTax("#type-dropdown", "#all-types");
                this.setFilterAll();
                this.gotoPage(1);
            },
            buildAllPas() {
                this.allTax("#pa-dropdown", "#all-practice-areas");
                this.filters.sortingType = '';
                this.gotoPage(1);
            },
            allTax(elm, inputElm) {
                if (event.currentTarget.checked) {
                    $(elm).find(`input:checkbox:not(${inputElm})`).prop("checked", false);
                }
                else {
                    $(elm).find(`input${inputElm}`).prop("checked", false);
                }
            },
            setFilterAll() {
                /**
                 * Set filters data attribute to all taxonomy terms in typeTerms
                 */
                this.filters.types = [];

                if (this.typeTerms) {
                    this.typeTerms.map(type => {
                        this.filters.types.push(type.term_id);
                    });
                }
            },
            removeAll(elm) {
                /**
                 * Find out if the All checkbox is checked, and make sure to remove it from our filterList array
                 */
                const allCheckbox = $(elm);
                if (allCheckbox.prop("checked") === true) {
                    allCheckbox.prop("checked", false);
                    this.filters.types = [];
                }
            },
            resetAll() {
                $("#type-dropdown input:checkbox, #pa-dropdown input:checkbox").prop("checked", false);
                this.search = "";
                this.filters = {
                    types: [],
                    sortingType: ''
                };
                this.page = 1;
                this.gotoPage(1);
            },
            queryFilters(slug) {
                const typeElm = document.querySelector(`[data-slug="${slug}"]`);
                typeElm.checked = true;
                this.filters.types.push(typeElm.value);
            }
        },
        components: {Search, Results, Pagination, ResultsTablet},
        name: "CommitteeListing"
    };
</script>
