<template>
	<div class="col-xs-4 program-filters-sidebar">
		<a href="#" class="close">
			<span>Close Filters</span>
			<img :src="icons.close"
			     alt="Close Filters">
		</a>
		<aside>
			<div v-if="filters.length || search.length || filterDate.length || !isEmpty(filterCreditRange)"
			     class="program-filters">
				<!-- Filter Search Text -->
				<p v-if="search.length"
				   class="label">{{ filterForTitles.searchingFor }}
					<a @click="clearSearchInput"
					   class="search-item search-text"><span>{{search}}</span></a>
				</p>
				<!-- Filter List -->
				<p v-if="filters.length"
				   class="label">{{ filterForTitles.searchFilters }}</p>
				<ul v-if="filters.length">
					<li v-for="(item, index) in filters"
					    :key="index">
						<a @click.prevent="$emit('resetItem', $event)"
						   href="#"
						   :data-name="item"><span v-html="item"></span></a>
					</li>
				</ul>
				<!-- Filter Date -->
				<p v-if="filterDate.length"
				   class="label">{{ filterForTitles.searchDate }}
					<a class="search-item search-range search-date"><span>{{ filterDate }}</span></a></p>
				<!-- Filter Credit Range -->
				<div v-if="!isEmpty(filterCreditRange) && (filterCreditRange.min !== undefined || filterCreditRange.max !== undefined)">
					<p class="label">{{ filterForTitles.searchCredits }}</p>
					<p v-if="filterCreditRange.min"
					   class="search-item search-range search-credit-range">Min {{ filterCreditRange.min}}</p>
					<p v-if="filterCreditRange.max"
					   class="search-item search-range search-credit-range">Max {{ filterCreditRange.max}}</p>
				</div>
				<!-- Reset All Filters -->
				<div v-if="filters.length || filterDate.length || !isEmpty(filterCreditRange)"
				     class="row end-xs">
					<div class="col-xs-12">
						<a @click.prevent="resetFilters"
						   href="#"
						   class="reset-filters"><span>Reset All Filters</span></a>
					</div>
				</div>
			</div>
			<ul class="sidebar-filters">
				<!-- Event Date -->
				<li id="event-dates">
					<a href="#">
						<span>{{ titles.date }}</span>
						<img :src="icons.dropdown" alt="Toggle Button" />
					</a>
					<ul class="sub-filters">
						<li>
							<fieldset class="radio-field">
								<input @click="$emit('buildDate')"
								       type="radio"
								       id="all-time"
								       aria-labelledby="all-time"
								       name="date" />
								<label for="all-time">All Time</label>
							</fieldset>
<!--							<fieldset class="radio-field">-->
<!--								<input @click="$emit('buildDate')"-->
<!--								       type="radio"-->
<!--								       id="upcoming"-->
<!--								       aria-labelledby="upcoming"-->
<!--								       name="date" />-->
<!--								<label for="upcoming">Upcoming</label>-->
<!--							</fieldset>-->
<!--							<fieldset class="radio-field">-->
<!--								<input @click="$emit('buildDate')"-->
<!--								       type="radio"-->
<!--								       id="past-months"-->
<!--								       aria-labelledby="past-months"-->
<!--								       name="date" />-->
<!--								<label for="past-months">Past 6 months</label>-->
<!--							</fieldset>-->
							<fieldset class="radio-field">
								<input type="radio" id="custom" class="custom-date-radio" aria-labelledby="custom" name="date" />
								<label for="custom">Custom</label>
							</fieldset>
							<fieldset class="custom-date">
								<div class="row">
									<div class="col-xs-6">
										<label for="from-date" class="date">From (MM / YYYY)</label>
										<input v-model="dateRange.dFrom"
										       type="date"
										       id="from-date"
										       pattern="\d{4}-\d{2}-\d{2}" />
									</div>
									<div class="col-xs-6">
										<label for="to-date">Until (MM/YYYY) </label>
										<input v-model="dateRange.dTo"
										       type="date"
										       id="to-date"
										       pattern="\d{4}-\d{2}-\d{2}" />
									</div>
								</div>
								<div @click="$emit('buildDate', dateRange)"
								     class="search-btn">
									<input type="submit" value="Search Date" id="search-date" />
								</div>
								<p class="note">*Multiple date filters selection is unavailabe</p>
							</fieldset>
						</li>
					</ul>
				</li>
				<!-- Event Format -->
				<li id="event-formats">
					<a href="#"><span>{{ titles.format }}</span>
						<img :src="icons.dropdown"
						     alt="Toggle Button" />
					</a>
					<ul class="sub-filters">
						<li>
							<fieldset class="checkbox-field">
								<input @click="$emit('getAllFormats', $event)"
								       type="checkbox"
								       id="all-formats"
								       data-name="All Formats"
								       aria-labelledby="All Formats"
								       name="All Formats" />
								<label for="all-formats">All Formats</label>
							</fieldset>
							<fieldset v-for="({term_id, name, slug}) in format"
							          :key="term_id"
							          class="checkbox-field">
								<input @click="$emit('selectFormat', $event)"
								       type="checkbox"
								       :id="term_id"
								       :data-name="name"
								       :data-format-slug="slug"
								       :aria-labelledby="name"
								       :name="name" />
								<label :for="term_id"
								       v-html="name"></label>
							</fieldset>
							<div v-if="onDemandLink"
							     class="format-btn">
								<a @click="goToProductsPage"
								   :href="onDemandLink.url"
								   class="curve-btn blue"><span>{{onDemandLink.title}}</span></a>
							</div>
						</li>
					</ul>
				</li>
				<!-- Event Region -->
				<li id="event-regions">
					<a href="#"><span>{{ titles.region }}</span>
						<img :src="icons.dropdown"
						     alt="Toggle Button" />
					</a>
					<ul class="sub-filters">
						<li>
							<fieldset class="checkbox-field">
								<input @click="$emit('getAllRegions', $event)"
								       type="checkbox"
								       id="all-regions"
								       data-name="All Regions"
								       aria-labelledby="All Regions"
								       name="All Regions" />
								<label for="all-regions">All Regions</label>
							</fieldset>
							<fieldset
								v-for="({term_id, name, slug}) in region"
								:key="term_id"
								class="checkbox-field">
								<input @click="$emit('selectRegion', $event)"
								       type="checkbox"
								       :id="term_id"
								       :data-name="name"
								       :data-region-slug="slug"
								       :aria-labelledby="name"
								       :name="name" />
								<label :for="term_id"
								       v-html="name"></label>
							</fieldset>
						</li>
					</ul>
				</li>

				<li id="event-types-category">
					<a href="#"><span>Types</span>
						<img :src="icons.dropdown"
							 alt="Toggle Button" />
					</a>
					<ul class="sub-filters">
						<li>
							<fieldset class="checkbox-field">
								<input @click="$emit('getAllTypes', $event)"
									   type="checkbox"
									   id="all-types-cat"
									   data-name="All Types"
									   aria-labelledby="All Types"
									   name="All Types" />
								<label for="all-types-cat">All Types</label>
							</fieldset>
							<fieldset
								v-for="({term_id, name, slug}) in eventType"
								:key="term_id"
								class="checkbox-field">
								<input @click="$emit('selectType', $event)"
									   type="checkbox"
									   :id="term_id"
									   :data-name="name"
									   :data-type-slug="slug"
									   :aria-labelledby="name"
									   :name="name" />
								<label :for="term_id"
									   v-html="name"></label>
							</fieldset>
						</li>
					</ul>
				</li>
				<!-- Event Credits -->
				<li id="event-credits">
					<a href="#"><span>{{ titles.credit }}</span>
						<img :src="icons.dropdown"
						     alt="Toggle Button" />
					</a>
					<ul class="sub-filters">
						<li>
							<fieldset class="credit-range">
								<div class="row">
									<div class="col-xs-6">
										<label for="credit-min">Credit Min.</label>
										<input v-model="creditRange.min"
										       v-on:keypress.enter="$emit('buildCredits', creditRange)"
										       type="number"
										       step="0.5"
										       min="0"
										       placeholder="0"
										       id="credit-min"
										       class="credit-range-input"
										       aria-labelledby="Credit Minimum" />
									</div>
									<div class="col-xs-6">
										<label for="credit-max">Credit Max.</label>
										<input v-model="creditRange.max"
										       v-on:keypress.enter="$emit('buildCredits', creditRange)"
										       type="number"
										       step="0.5"
										       max="50"
										       min="0"
										       placeholder="50"
										       id="credit-max"
										       class="credit-range-input"
										       aria-labelledby="Credit Maximum" />
									</div>
								</div>
								<div @click="$emit('buildCredits', creditRange)"
								     class="search-btn">
									<input type="submit" value="Search Credits" />
								</div>
							</fieldset>
						</li>
					</ul>
				</li>
				<!-- Event Credit Type -->
				<li id="event-credit-types">
					<a href="#"><span>{{ titles.creditType }}</span>
						<img :src="icons.dropdown"
						     alt="Toggle Button" />
					</a>
					<ul class="sub-filters">
						<li>
							<fieldset class="checkbox-field">
								<input @click="$emit('getAllCreditTypes', $event)"
								       type="checkbox"
								       id="all-credit-types"
								       data-name="All Credit Types"
								       aria-labelledby="All Credit Types"
								       name="All Credit Types" />
								<label for="all-credit-types">All Credit Types</label>
							</fieldset>
							<fieldset v-for="({term_id, name, slug}) in creditType"
							          :key="term_id"
							          class="checkbox-field">
								<input @click="$emit('selectCreditType', $event)"
								       type="checkbox"
								       :id="term_id"
								       :data-name="name"
								       :data-ctype-slug="slug"
								       :aria-labelledby="name"
								       :name="name" />
								<label :for="term_id"
								       v-html="name"></label>
							</fieldset>
						</li>
					</ul>
				</li>
				<!-- Event Practice Area -->
				<li id="event-practice-areas">
					<a href="#"><span>{{ titles.pa }}</span>
						<img :src="icons.dropdown" alt="Toggle Button" />
					</a>
					<ul class="sub-filters">
						<li>
							<fieldset class="checkbox-field">
								<input
									type="checkbox"
									@click="$emit('getAllPA', $event)"
									id="all-practice-areas"
									data-name="All Practice Areas"
									aria-labelledby="All Practice Areas"
									name="All Practice Areas" />
								<label for="all-practice-areas">All Practice Areas</label>
							</fieldset>
							<fieldset
								v-for="({term_id, name, slug}) in pa"
								:key="term_id"
								class="checkbox-field">
								<input
									@click="$emit('selectPA', $event)"
									type="checkbox"
									:id="term_id"
									:data-name="name"
									:data-pa-slug="slug"
									:aria-labelledby="name"
									:name="name" />
								<label
									:for="term_id"
									v-html="name"></label>
							</fieldset>
						</li>
					</ul>
				</li>
			</ul>
			<div id="event-search" class="search-keyword">
				<label id="keyword" for="keyword">{{ titles.searchBox }}</label>
				<fieldset>
					<input v-model="searchText"
					       v-on:keypress.enter="$emit('buildSearch', searchText)"
					       aria-labelledby="keyword"
					       type="text"
					       name="keyword"
					       autocomplete="off" />
					<div @click.prevent="$emit('buildSearch', searchText)"
					     class="search-btn">
						<input type="submit" value="Search" />
					</div>
				</fieldset>
			</div>
			<a href="#listing-top" class="back-top scrollto">
				<span class="btn"></span>
				<span class="txt">Back to the Top</span>
			</a>
		</aside>
	</div>
</template>

<script>
    export default {
        props: {
            format: [Array, Object],
            region: [Array, Object],
            creditType: [Array, Object],
            eventType: [Array, Object],
            pa: [Array, Object],
            filters: [Array, Object],
            filterDate: [String],
            filterCreditRange: [Object, Array],
            search: String
        },
        data() {
            return {
                icons: {},
                filterForTitles: {
                    searchingFor: null,
                    searchFilters: null,
                    searchDate: null,
                    searchCredits: null,
                },
                titles: {
                    date: null,
                    format: null,
                    region: null,
                    credit: null,
                    creditType: null,
                    eventType: null,
                    pa: null,
                    searchBox: null,
                },
                searchText: "",
                dateRange: {},
                creditRange: {},
                onDemandLink: []
            };
        },
        beforeMount() {
            if (typeof SIDEBAR !== "undefined") {
                /**
                 * Filter Box Titles
                 */
                (SIDEBAR.searchingText) ? this.filterForTitles.searchingFor = SIDEBAR.searchingText : null;
                (SIDEBAR.searchFilters) ? this.filterForTitles.searchFilters = SIDEBAR.searchFilters : null;
                (SIDEBAR.searchDate) ? this.filterForTitles.searchDate = SIDEBAR.searchDate : null;
                (SIDEBAR.searchCredits) ? this.filterForTitles.searchCredits = SIDEBAR.searchCredits : null;

                /**
                 * Filter By Titles
                 */
                (SIDEBAR.dateTitle) ? this.titles.date = SIDEBAR.dateTitle : null;
                (SIDEBAR.formatTitle) ? this.titles.format = SIDEBAR.formatTitle : null;
                (SIDEBAR.regionTitle) ? this.titles.region = SIDEBAR.regionTitle : null;
                (SIDEBAR.eventTypeTitle) ? this.titles.eventType = SIDEBAR.eventTypeTitle : null;
                (SIDEBAR.creditTitle) ? this.titles.credit = SIDEBAR.creditTitle : null;
                (SIDEBAR.creditTypeTitle) ? this.titles.creditType = SIDEBAR.creditTypeTitle : null;
                (SIDEBAR.paTitle) ? this.titles.pa = SIDEBAR.paTitle : null;
                (SIDEBAR.searchTitle) ? this.titles.searchBox = SIDEBAR.searchTitle : null;

                (SIDEBAR.onDemandLink) ? this.onDemandLink = SIDEBAR.onDemandLink : null;
            }

            if (typeof ICONS !== "undefined") {
                (ICONS.close) ? this.icons.close = ICONS.close : null;
                (ICONS.dropdown) ? this.icons.dropdown = ICONS.dropdown : null;
            }
        },
        methods: {
            resetFilters() {
                this.$emit("resetFilters");
                this.creditRange = {};
            },
            clearSearchInput() {
                this.$emit("clearSearch", this.searchText);
                this.searchText = "";
            },
	        goToProductsPage() {
                window.location = this.onDemandLink.url
	        },
            isEmpty(obj) {
                return Object.keys(obj).length === 0;
            }
        },
	    watch: {
            filterCreditRange(newRange) {
                this.creditRange.min = newRange.min || '';
                this.creditRange.max = newRange.max || '';
            },
            search(newSearchText) {
                this.searchText = newSearchText;
            }
        },
        name: "CLESidebar"
    };
</script>

<style lang="scss" scoped>
	.remove-text {
		position: relative;
		z-index: 1;
		img {
			height: 11px;
			width: 11px;
		}
	}
	.search-credit-range { margin-top: 0; }
</style>
