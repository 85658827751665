<template>
  <div class="product-cnt">
    <h3 class="post-title"><a :href="post.permalink"><span class="strikethrough">{{ post.title }}</span></a></h3>
    <div class="excerpt">
      <div v-if="post.image"
           class="thumb">
        <picture>
          <source v-if="post.image.webpSrcset"
                  :srcset="post.image.webpSrcset"
                  :sizes="post.image.sizes"
                  type="image/webp" />
          <source :srcset="post.image.srcset"
                  :sizes="post.image.sizes"
                  :type="post.image.type" />
          <img :src="post.image.src"
               :content="post.image.src"
               :alt="post.image.alt"
               role="img"
               property="v:image" />
        </picture>
      </div>
      <div class="content">
        <p>{{ post.excerpt }}</p>
      </div>
    </div>
    <div class="info-rows">
      <div class="row">
          <!-- Publication/Recorded Date -->
          <div v-if="post.publicationDate && Object.keys(post.publicationDate.length)"
               class="col-xs-6 col-xl-4 info">
              <p class="label">{{ post.publicationTitle }}</p>
              <p>{{ post.publicationDate }}</p>
          </div>
          <!-- Practice Area -->
          <div v-if="post.practiceArea && Object.keys(post.practiceArea).length"
               class="col-xs-6 col-xl-4 info">
              <p class="label">Practice Area{{ post.practiceArea.length > 1 ? "s" : null }}</p>
              <p>
						<span v-for="({termId, termName}) in post.practiceArea"
                              :key="termId"
                              v-html="termName"></span>
              </p>
          </div>
        <!-- Member Price -->
        <div v-if="post.memberPrice"
             class="col-xs-6 col-xl-4 info">
          <p class="label">Member Price</p>
          <p>{{ post.memberPrice }}</p>
        </div>
      </div>
    <div class="row">
        <!-- Product Type -->
        <div v-if="post.productType && Object.keys(post.productType).length"
             class="col-xs-6 col-xl-4 info">
            <p class="label">Available Format{{ post.productType.length > 1 ? "s" : null }}</p>
            <p>
						<span v-for="({termId, termName}) in post.productType"
                              :key="termId"
                              v-html="termName"></span>
            </p>
        </div>
        <!-- Credits -->
        <div v-if="(post.credits && Object.keys(post.credits).length)"
             class="col-xs-6 col-xl-4 info">
            <p class="label">Credit Type{{ post.credits.length > 1 ? "s" : null }}</p>
            <p>
						<span v-for="({name}, index) in post.credits"
                              :key="index"
                              v-html="name"></span>
            </p>
        </div>
        <!-- Section Member Price -->
        <div v-if="post.sectMemberPrice && Object.keys(post.sectMemberPrice).length"
             class="col-xs-6 col-xl-4 info">
            <p class="label">Section Member Price</p>
            <p>{{ post.sectMemberPrice }}</p>
        </div>
    </div>
        <div class="row">
            <div class="col-xs-6 col-xl-4"></div>
            <!-- Total Credits -->
            <div v-if="post.totalCredits"
                 class="col-xs-6 col-xl-4 info">
                <p class="label">Total Credit{{ post.totalCredits.val > 1 ? "s" : null }}</p>
                <p>{{ post.totalCredits.val }}</p>
            </div>
        </div>
    </div>
    <div v-if="post.children && Object.keys(post.children).length > 0"
         class="row center-xs product-types">
      <div class="col-xs-12">
        <div class="accordion-items">
          <div class="row col-xs-12 text-left product-title">
            <div class="col-md-7 title">Related Products</div>
            <div class="col-md-3 title">Format</div>
            <div class="col-md-2 title">Member Price</div>
          </div>
          <div v-for="({id, title, permalink, price, productType}, index) in post.children"
               :key="id" class="row col-xs-12 text-left product-child">
            <div class="col-md-7 child"><a :href="permalink">{{ title }}</a>:</div>
            <div v-for="(type) in productType"
                 class="col-md-3 child">{{ type }}
            </div>
            <div class="col-md-2 child">{{ price }}</div>
          </div>
        </div>
        <a @click.prevent="expandAccordion($event)"
           href="#"
           class="toggle-btn blue"><span>Products</span></a>
      </div>
    </div>
    <div class="row center-xs more-row">
      <div class="col-xs-12">
        <a :href="post.permalink"
           class="line-btn blue"><span>More Details</span></a>
      </div>
    </div>
  </div>
</template>

<script>
import "waypoints/lib/jquery.waypoints.min.js";

export default {
  props: {
    post: [Object, Array]
  },
  methods: {
    expandAccordion(el) {
      const elm = el.currentTarget;
      if ($(elm).hasClass("open")) $(elm).removeClass("open").prev().slideUp(400);
      else $(elm).addClass("open").prev().slideDown(400);
      Waypoint.refreshAll();
    }
  },
  name: "ProductListing"
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .col-xl-4 {
    -webkit-box-sizing:      border-box;
    box-sizing:              border-box;
    -webkit-box-flex:        0;
    -moz-flex-grow:          0;
    -ms-flex-positive:       0;
    flex-grow:               0;
    -ms-flex-negative:       0;
    flex-shrink:             0;
    padding-right:           .5rem;
    padding-left:            .5rem;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis:              33.33333%;
    max-width:               33.33333%;
  }
}
</style>
