<template>
	<div class="modal-content">
		<div class="modal-close">
			<a @click.prevent="closeModal"
			   href="#" class="close">
				<span class="sr-only">Close event modal window</span>
			</a>
		</div>
		<div class="modal-event-date">{{eventDate}}</div>
		<div class="row">
			<div class="col-xs-6">
				<div :class="`modal-event modal-event-${event.extendedProps.slug}`">
					<div v-if="event.extendedProps.hasOwnProperty('categoryName')"
					     class="modal-event-category-container">
						<div v-for="({name, id, slug}) in event.extendedProps.categoryName"
						     :key="id"
						     :class="`modal-event-category ${slug}`">{{name}}
						</div>
					</div>
					<div class="modal-event-title">
						<a :href="event.url">{{event.title}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            eventDate: [String],
            event: [Object, Array],
        },
	    data() {
            return {
                close: ICONS.close
            }
	    },
        methods: {
            closeModal() {
                $("#modal").hide();
                $("#modal .modal-events").empty();
            }
        },
        name: "EventModal"
    };
</script>

<style scoped>

</style>
