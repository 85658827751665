var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "post" }, [
    _vm.image
      ? _c("div", { staticClass: "thumb" }, [
          _c("picture", [
            _vm.image.webpSrcset
              ? _c("source", {
                  attrs: {
                    srcset: _vm.image.webpSrcset,
                    sizes: _vm.image.sizes,
                    type: "image/webp",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("source", {
              attrs: {
                srcset: _vm.image.srcset,
                sizes: _vm.image.sizes,
                type: _vm.image.type,
              },
            }),
            _vm._v(" "),
            _c("img", {
              attrs: {
                src: _vm.image.src,
                content: _vm.image.src,
                alt: _vm.image.alt,
                role: "img",
                property: "v:image",
              },
            }),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _vm.date || _vm.region
        ? _c("div", { staticClass: "post-meta" }, [
            _vm.date
              ? _c("p", { domProps: { innerHTML: _vm._s(_vm.date) } })
              : _vm._e(),
            _vm._v(" "),
            _vm.region
              ? _c("p", { domProps: { innerHTML: _vm._s(_vm.region) } })
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h3", { staticClass: "post-title" }, [
        _c("a", { attrs: { href: _vm.permalink } }, [
          _c("span", {
            staticClass: "strikethrough",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm.excerpt
        ? _c("div", { staticClass: "excerpt" }, [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.excerpt) } }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.linkText.length
        ? _c(
            "a",
            { staticClass: "line-btn blue", attrs: { href: _vm.permalink } },
            [_c("span", { domProps: { innerHTML: _vm._s(_vm.linkText) } })]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }