<template>
	<div class="row">
		<div class="col-xs-12 calendar-category-title">Show</div>
		<div class="col-xs-12 category-list">
			<ul id="category-container" class="cat-list">
				<li @click.prevent="filterType($event)"
				    data-cat="0"
				    id="category-everything"
				    data-format-slug="all"
				    class="filter-all filter category-everything active">
					<a href="#">Everything</a>
				</li>
				<li v-for="({term_id, slug, name}) in types"
				    :key="term_id"
				    @click.prevent="filterType($event)"
				    :id="`category-${slug}`"
				    :data-cat="term_id"
				    :data-format-slug="slug"
				    :class="`filter-cat filter category-${slug}`">
					<a v-html="name"
					   href="#"></a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            types: [Object, Array],
            selectedTypes: [Object, Array],
        },
        methods: {
            filterType(elm) {
                const el = elm.currentTarget;
                const name = el.dataset.formatSlug;

                if (!el.classList.contains('active')) el.classList.add('active');
                else el.classList.remove('active');

                const filterContainer = document.querySelector("[id=\"category-container\"]");

                if (name === 'all') [...filterContainer.querySelectorAll(".filter-cat")].map(item => item.classList.remove('active'));
                else filterContainer.querySelector(".filter-all").classList.remove('active');

                this.$emit("searchFilter", elm);
            }
        },
        name: "Categories"
    };
</script>

<style scoped>

</style>
