var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    { attrs: { "aria-label": `Print` }, on: { click: _vm.printElm } },
    [
      _c("img", {
        staticClass: "svg",
        attrs: { src: _vm.icon, alt: _vm.title + " Icon" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }