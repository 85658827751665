<template>
    <Pagination v-if="maxPages > 1"
                :page-count="maxPages"
                :page-range="3"
                :force-page="currentPage"
                :value="currentPage"
                :margin-pages="1"
                :click-handler="triggerPageChange"
                :prev-text="''"
                :next-text="''"
                :container-class="'bottom-pagination pagination'"
                :page-link-class="'page-numbers'"
                :next-link-class="'next'"
                :prev-link-class="'prev'"
                :active-class="'current'"
                :break-view-link-class="'dots'"
                :no-li-surround="true"
    />
</template>

<script type="application/javascript">
    import Pagination from "vuejs-paginate";
    import {EventBus} from "./../Util/event-bus.js";
    
    export default {
        methods: {
            triggerPageChange(pageNum) {
                EventBus.$emit("tag-page", pageNum);
            }
        },
        computed: {
            maxPages() {
                return this.$store.state.tagPostsMaxPage;
            },
            currentPage() {
                return this.$store.state.tagPostsCurrentPage;
            }
        },
        components: {Pagination},
        name: "TagPostPagination"
    };
</script>

<style scoped>

</style>
