<template>
	<div class="line-header-container">
		<div class="line-header">
			<h2>RESULT (TOTAL {{total}} ITEMS)</h2>
		</div>
	</div>
</template>

<script type="application/javascript">
    export default {
        props: {
            total: Number,
        },
        name: "Results"
    };
</script>

<style scoped>

</style>
