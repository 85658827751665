var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "line-header-container" }, [
    _c("div", { staticClass: "line-header" }, [
      _c("h2", [_vm._v("RESULT (TOTAL " + _vm._s(_vm.total) + " ITEMS)")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }