<template>
	<div v-if="(typeof contents)"
	     class="tablet-cart-results-table">
		<CartItem v-for="(item, index) in contents"
		          :key="index"
		          :index="index"
		          :item="item"
		          :icons="icons" />
	</div>
</template>

<script>
    import CartItem from "./CartTabletItem.vue";

    export default {
        props: {
            contents: Object,
            icons: Object
        },
        components: {CartItem},
        name: "CartTablet"
    };
</script>
