<template>
	<form v-if="$resize && $mq.below(1025)"
	      id="tablet-sticky-cart-form" action="">
		<fieldset v-if="$root.isLoggedIn === 'true'">
			<label id="tablet-cart-quantity" for="tablet-cart-quantity">{{qtyText}}</label>
			<div class="plus-minus-container">
				<button class="minus" @click.prevent="decrement">
					<img :src="minusIcon"
					     alt="Subtract One Quantity" />
				</button>
				<input type="number"
				       id="cart-quantity-input"
				       name="tablet-cart-quantity"
				       aria-labelledby="tablet-cart-quantity"
				       :value="$root.qty"
				>
				<button class="plus" @click.prevent="increment">
					<img :src="plusIcon"
					     alt="Add One Quantity"
					/>
				</button>
			</div>
		</fieldset>

<!--		<div v-if="$root.isLoggedIn === 'true'"-->
<!--		      class="cart-total-items-container">-->
<!--			Total (<span class="cart-total-items">{{ $root.qty }}</span> items): $-->
<!--			<span class="cart-total-items-price"-->
<!--			      :data-price="$root.total">{{ $root.total }}</span>-->
<!--		</div>-->

		<div v-if="$root.isLoggedIn === 'true'"
		      class="cart-checkout-buttons">
			<a href="#" class="line-btn blue"><span>{{ viewCartText }}</span></a>
			<a class="curve-btn white"
			   :href="($root.qty > 0 ) ? $root.api : null"><span>{{ addToCartText }}</span></a>
			<a v-if="$root.checkoutUrl"
			   :href="$root.checkoutUrl" class="curve-btn blue"><span>{{ checkoutText }}</span></a>
		</div>
		<div v-if="$root.isLoggedIn === 'false'">
			<LoginBtn :text="'Login To Purchase'"/>
		</div>
        
        <div v-if="footnoteText"
             class="footnote-content"
             v-html="footnoteText"></div>
        
	</form>
</template>

<script>
	import LoginBtn from "./LoginBtn.vue";
    export default {
        data() {
            return {
                qtyText: this.$root.text.qtyText,
                checkoutText: this.$root.text.checkoutText,
                addToCartText: this.$root.text.addToCartText,
                viewCartText: this.$root.text.viewCartText,
                footnoteText: this.$root.text.footnoteText,
                minusIcon: this.$root.icons.minusIcon,
                plusIcon: this.$root.icons.plusIcon,
            };
        },
        methods: {
            increment() {
                this.$store.dispatch("increment");
            },
            decrement() {
                this.$store.dispatch("decrement");
            }
        },
	    components: {
            LoginBtn
	    },
        name: "TabletProductQty"
    };
</script>

<style scoped>

</style>
