<template>
	<div v-if="isVisible"
	     :id="date"
	     :class="`event ${event.className.join(' ')}`"
	     :data-date="date">
		<div class="row">
			<div v-if="event.image.length !== 0"
				class="col-xs-5">
				<div class="thumb">
					<div v-for="cat in event.categoryName"
					     :key="cat.id"
					     :class="`event-category category-${cat.slug}`"
						 v-text="cat.name"></div>
					<picture>
						<source v-if="event.image.webpSrcset"
						        :srcset="event.image.webpSrcset"
						        type="image/webp" />
						<source :srcset="event.image.srcset"
						        :type="event.image.type" />
						<img role="img"
						     :src="event.image.src"
						     :alt="event.image.alt" />
					</picture>
				</div>
			</div>
			<div :class="event.image.length !== 0 ? 'col-xs-7' : 'col-xs-12'">
				<div class="content">
					<div v-for="cat in event.categoryName"
					     :key="cat.id"
					     :class="`event-category mobile-cat category-${cat.slug}`"
					     v-text="cat.name"></div>
					<p class="date"><span v-text="eventDate()"></span></p>
					<p class="title">
						<a :href="event.url" v-text="event.title"></a>
					</p>
					<p class="excerpt" v-text="event.excerpt"></p>
					<a :href="event.url" class="renew-btn blue"><span>Read More</span></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="application/javascript">
	import moment from "moment";
    export default {
        props: {
            event: [Object, Array],
	        showDate: String,
	        isVisible: Boolean,
        },
	    data() {
            return {
                date: moment(this.event.start).format('YYYY-MM-DD'),
                endDate: moment(this.event.end).format('YYYY-MM-DD'),
	            title: this.event.title,
            }
	    },
	    beforeMount() {
            // const eventStart = this.event.start;
            // const eventEnd = this.event.end;
            // const activeDate = this.showDate;
            //  this.isVisible = !!(moment(event.start).isSameOrBefore(activeDate) && moment(event.end).isSameOrAfter(activeDate));
	    },
        methods: {
			eventDate() {
                let dateReturn;
                const sDate = this.date;
                const eDate = this.endDate;
                const year = moment(eDate).isSame(sDate, 'year');
                const month = moment(eDate).isSame(sDate, 'month');
                const day = moment(eDate).isSame(sDate, 'day');

                if (year === true) {
                    if (day && month) dateReturn = `${moment(sDate).format('MMMM D')}`;
                    else if (!day && month) dateReturn = `${moment(sDate).format('MMMM D')} - ${moment(eDate).format('D')}`;
                    else if (!month) dateReturn = `${moment(sDate).format('MMMM D')} - ${moment(eDate).format('MMMM D')}`;
                }
                else {
                    dateReturn = `${moment(sDate).format('MMMM D, YYYY')} - ${moment(eDate).format('MMMM D, YYYY')}`;
                }

                return dateReturn

			}
	    },
        name: "SingleEvent"
    };
</script>

<style scoped>

</style>
