<template>
    <div id="future-events-list"
         :class="`events-list ${!showEvents.length && !isLoading ? 'no-events' : ''}`">
        
        <h2 v-text="title"></h2>
        
        <div :class="`loading ${isLoading ? 'is-loading' : ''}`"></div>
        
        <Event v-if="showEvents"
               v-for="({date, excerpt, id, image, region, registerLink, permalink, title}) in showEvents"
               :key="id"
               :date="date"
               :excerpt="excerpt"
               :image="image"
               link-text="Register Now"
               :register-link="registerLink"
               :region="region"
               :permalink="permalink"
               :title="title" />
    
        <NoResults v-if="!events.length"
                   :no-results-text="`Sorry there are no past events` " />
        
        <Paginate v-if="!isLoading && maxPages > 1"
                  :page-count="maxPages"
                  :page-range="3"
                  :force-page="currentPage"
                  :value="currentPage"
                  :margin-pages="1"
                  :click-handler="gotoPage"
                  :prev-text="''"
                  :next-text="''"
                  :container-class="'bottom-pagination pagination'"
                  :page-link-class="'page-numbers'"
                  :next-link-class="'next'"
                  :prev-link-class="'prev'"
                  :active-class="'current'"
                  :break-view-link-class="'dots'"
                  :no-li-surround="true"
        />
    
    </div>
</template>

<script type="application/javascript">
    import Paginate from "vuejs-paginate";
    
    import Event from "./Event.vue";
    import NoResults from "../../MyNYSBA/NoResults.vue";
    
    export default {
        props: {
            events: [],
        },
        data() {
            return {
                arrEnd: 8,
                arrStart: 0,
                currentPage: 1,
                isLoading: false,
                maxPages: 0,
                paged: 1,
                showEvents: null,
                title: NYSBA.titles.future,
            };
        },
        created() {
            // this.getEvents();
            this.maxPages = Math.ceil(this.events.length / 8);
        },
        beforeMount() {
            this.slicePosts();
        },
        mounted() {
        },
        methods: {
            gotoPage(pageNum) {
                if ((event !== undefined) && (event.currentTarget.classList.contains("page-numbers"))
                    || (event.currentTarget.classList.contains("next")
                        || event.currentTarget.classList.contains("prev"))
                ) {
                    $("html, body").animate({scrollTop: $("#future-events-list").offset().top - 100}, 1000);
                }
                
                this.currentPage = pageNum;
                this.slicePosts(pageNum);
            },
            slicePosts(pageNum = 1) {
                if (pageNum === 1) {
                    this.arrStart = 0;
                    this.arrEnd = 8;
                } else {
                    this.arrStart = (pageNum - 1) * 8;
                    this.arrEnd = pageNum * 8;
                }
                this.isLoading = true;
                this.showEvents = Object.values(this.events).slice(this.arrStart, this.arrEnd);
                this.isLoading = false;
            }
        },
        components: {Event, NoResults, Paginate},
        name: "FutureEvents"
    };
</script>

<style lang="scss" scoped>
    .events-list {
        position: relative;
        &:not(.no-events) {
            min-height: 25vh;
            .is-loading {
                display: block !important;
            }
        }
    }
</style>
