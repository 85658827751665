var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home__featured wrapper" }, [
    _c("div", { staticClass: "home__featured__container" }, [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "loading-container" },
            [_c("Loading", { attrs: { loading: _vm.loading } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "home__featured__column" }, [
        _vm.slidePosts && !_vm.loading
          ? _c(
              "div",
              {
                staticClass: "home__featured__slider helpful-links-slider",
                attrs: { id: "slider-block" },
              },
              [
                _c("BlockTitle", {
                  attrs: {
                    "title-class": "slider-posts__title",
                    title: _vm.slideTitle,
                  },
                }),
                _vm._v(" "),
                _c("SliderBlock", {
                  attrs: {
                    "post-class": "slider-posts__post",
                    posts: _vm.slidePosts,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.smallPosts && !_vm.loading
          ? _c(
              "div",
              {
                staticClass: "home__featured__list home__featured__list--left",
                attrs: { id: "small-posts-block" },
              },
              [
                _c("BlockTitle", {
                  attrs: {
                    "title-class": "small-posts__title",
                    title: _vm.smallTitle,
                  },
                }),
                _vm._v(" "),
                _c("PostsBlock", {
                  attrs: {
                    "post-class": "small-posts__post",
                    posts: _vm.smallPosts,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "home__featured__column" }, [
        _vm.largePosts && !_vm.loading
          ? _c(
              "div",
              {
                staticClass: "home__featured__list",
                attrs: { id: "large-posts-block" },
              },
              [
                _c("BlockTitle", {
                  attrs: {
                    "title-class": "large-posts__title",
                    title: _vm.largeTitle,
                  },
                }),
                _vm._v(" "),
                _c("PostsBlock", {
                  attrs: {
                    "post-class": "large-posts__post",
                    posts: _vm.largePosts,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    !_vm.loading && _vm.postsLink
      ? _c("div", { staticClass: "row center-xs mt-50" }, [
          _c("div", { staticClass: "col-xs-12" }, [
            _c(
              "a",
              {
                staticClass: "curve-btn blue",
                attrs: {
                  href: _vm.postsLink.url,
                  target: _vm.postsLink.target,
                },
              },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.postsLink.title) },
                }),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }