<template>
    
    <div class="post">
        <div v-if="image"
             class="thumb">
            <picture>
                <source v-if="image.webpSrcset"
                        :srcset="image.webpSrcset"
                        :sizes="image.sizes"
                        type="image/webp" />
                <source :srcset="image.srcset"
                        :sizes="image.sizes"
                        :type="image.type" />
                <img :src="image.src"
                     :content="image.src"
                     :alt="image.alt"
                     role="img"
                     property="v:image" />
            </picture>
        </div>
        
        <div class="content">
            <div v-if="date || region"
                 class="post-meta">
                <p v-if="date"
                   v-html="date" />
                <p v-if="region"
                   v-html="region" />
            </div>
            
            <h3 class="post-title">
                <a :href="permalink">
          <span class="strikethrough"
                v-html="title" />
                </a>
            </h3>
            
            <div v-if="excerpt"
                 class="excerpt">
                <p v-html="excerpt" />
            </div>
            
            <a v-if="linkText"
               :href="permalink"
               class="line-btn blue">
                <span v-html="linkText" />
            </a>
        
        </div>
    
    </div>

</template>

<script type="application/javascript">
    export default {
        props: {
            date: [Boolean, String],
            excerpt: String,
            image: [Array, Boolean, Object],
            linkText: [Boolean, String],
            region: [Boolean, String],
            registerLink: [Boolean, String],
            permalink: [Boolean, String],
            title: String,
        },
        data() {
            return {
                link: null
            };
        },
        created() {
            this.link = (this.registerLink !== undefined) ? this.registerLink : this.permalink;
        },
        name: "PostItem"
    };
</script>

<style scoped>

</style>
