<template>
	<div v-if="$root.formats.length > 0">
		<p class="label">{{$root.title}}</p>
		<ul v-if="$root.formats" class="format">
			<ProductFormat v-for="format in $root.formats"
			               :key="format.id"
			               :format="format" />
		</ul>
	</div>
</template>

<script type="application/javascript">
	import ProductFormat from "./Products-Listing/ProductFormat.vue";
    export default {
        components: {ProductFormat},
        name: "ProductFormats"
    };
</script>

<style scoped>

</style>
