var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: `modal-bio-${_vm.id}` } }, [
    _c("div", { attrs: { id: "js-modal-content" } }, [
      _c("div", { staticClass: "overflow" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-5" }, [
            _c("div", { staticClass: "photo" }, [
              _vm.image
                ? _c("picture", [
                    _vm.image.webpSrcset
                      ? _c("source", {
                          attrs: {
                            srcset: _vm.image.webpSrcset,
                            sizes: _vm.image.sizes,
                            type: "image/webp",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("source", {
                      attrs: {
                        srcset: _vm.image.srcset,
                        sizes: _vm.image.sizes,
                        type: _vm.image.type,
                      },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: _vm.image.src,
                        content: _vm.image.src,
                        alt: _vm.image.alt,
                        role: "img",
                        property: "v:image",
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-7" },
            [
              _vm._l(_vm.types, function ({ name, slug, termId }) {
                return _c("p", {
                  key: termId,
                  class: `title-tag ${slug}`,
                  domProps: { textContent: _vm._s(name) },
                })
              }),
              _vm._v(" "),
              _c("p", {
                staticClass: "officer-name",
                domProps: { textContent: _vm._s(_vm.name) },
              }),
              _vm._v(" "),
              _c("p", {
                staticClass: "title",
                domProps: { textContent: _vm._s(_vm.organization) },
              }),
              _vm._v(" "),
              _vm.bio
                ? _c("div", {
                    staticClass: "bio",
                    domProps: { innerHTML: _vm._s(_vm.bio) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.location
                ? _c("p", {
                    staticClass: "location",
                    domProps: { textContent: _vm._s(_vm.location) },
                  })
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }