<template>
	<tr v-if="item !== null">
		<td :id="'item-id-' + item.id">
			<ul class="title-list">
				<li>
					<a class="title-list-trigger" href="#">
						<img :src="icons.titlePlus"
						     class="plus"
						     alt="Toggle Open" />
						<img :src="icons.titleMinus"
						     class="minus off"
						     alt="Toggle Closed" />
					</a>
					<a class="title-list-parent" href="#">{{ item.name }}</a>
					<ul class="title-list-submenu">
						<li><a href="#">1. Anti-Money Laundering Bank Security Act Compliance 2018</a></li>
						<li>
							<a href="#">2. In hac habitasse platea dictumst. Vivamus adipiscing ferm In hac habitasse platea dictumst. Vivamus adipiscing ferm In hac habitasse platea dictumst. Vivamus adipiscing ferm</a>
						</li>
						<li><a href="#">3. In hac habitasse platea dictumst. Vivamus adipiscing</a></li>
					</ul>
				</li>
			</ul>
		</td>
		<td>
			<p v-if="item.type">
				<span v-for="type in item.type">{{ type }}</span>
			</p>
		</td>
		<td>
			<fieldset class="change-qty">
				<label :id="'Cart-quantity-' + index"
				       for="desktop-cart-quantity"
				>Quantity</label>
				<div class="plus-minus-container">
					<button @click.prevent="(item.qty > 0) ? item.qty-- : null"
					        class="minus">
						<img :src="icons.qtyMinus"
						     alt="minus" />
					</button>
					<input type="number" :value="item.qty"
					       id="desktop-cart-quantity"
					       :aria-labelledby="'Cart-quantity-' + index"
					>
					<button @click.prevent="item.qty++"
					        class="plus">
						<img :src="icons.qtyMinus"
						     alt="minus" />
					</button>
				</div>
			</fieldset>
		</td>
		<td>
				<span v-if="item.total"
				      :data-price="item.total">${{ item.total }}</span>
		</td>
	</tr>
</template>

<script>
    export default {
        props: {
            item: Object,
            icons: Object,
            index: String,
        },
        name: "CartTableItem"
    };
</script>
