<template>
	<div class="row">
		<Sidebar
			:types="typeTerms"
			:creditType="creditTerms"
			:practiceArea="paTerms"
			:filterCreditRange="filterCreditRange"
			:filters="filterItems"
			:search="search"
			@buildFormats="buildFormats"
			@selectCreditType="buildCreditType"
			@buildCredits="buildCredits"
			@buildPracticeArea="buildPracticeArea"
			@buildSearch="buildSearch"
			@getAllFormats="getAllFormats"
			@getAllCreditTypes="getAllCreditTypes"
			@getAllPA="getAllPA"
			@clearSearch="clearSearchText"
			@resetItem="removeItem"
			@resetFilters="resetFilters"
		/>
		<div class="col-xs-8">
			<a href="#" class="program-open-filters">
				<img :src="filterIcon"
				     alt="Open Filters"> Open Advanced Filters</a>
			<div id="listing-top">
				<div class="results">
					<div v-if="maxPage > 1"
					     class="row">
						<div class="col-xs-12">
							<Paginate :page-count="maxPage"
							          :page-range="3"
							          :force-page="currentPage"
							          :value="currentPage"
							          :margin-pages="1"
							          :click-handler="gotoPage"
							          :prev-text="''"
							          :next-text="''"
							          :container-class="'top-pagination pagination'"
							          :page-link-class="'page-numbers'"
							          :next-link-class="'next'"
							          :prev-link-class="'prev'"
							          :active-class="'current'"
							          :break-view-link-class="'dots'"
							          :no-li-surround="true" />
						</div>
					</div>
					<ListContainer :posts="posts"
					               :loading="isLoading" />
					<div v-if="maxPage > 1"
					     class="row">
						<div class="col-xs-12">
							<Paginate v-model="currentPage"
							          :page-count="maxPage"
							          :page-range="3"
							          :force-page="currentPage"
							          :value="currentPage"
							          :margin-pages="1"
							          :click-handler="gotoPage"
							          :prev-text="''"
							          :next-text="''"
							          :container-class="'bottom-pagination pagination'"
							          :page-link-class="'page-numbers'"
							          :next-link-class="'next'"
							          :prev-link-class="'prev'"
							          :active-class="'current'"
							          :break-view-link-class="'dots'"
							          :no-li-surround="true" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import axios from "axios";
    import ListContainer from "./Products-Listing/ListContainer.vue";
    import Sidebar from "./Products-Listing/ProductSidebar.vue";
    import Paginate from "vuejs-paginate";

    export default {
        data() {
            return {
                posts: [],
                currentPage: 0,
                maxPage: 0,
                filterIcon: null,
                typeTerms: [],
                creditTerms: [],
                paTerms: [],
                filterItems: [],
                filterCreditRange: {},
                filters: {
                    types: [], // This is used for "Format" dropdown per client direction on mismatched taxonomy
                    creditType: [],
                    practiceArea: [],
                    searchText: "",
                },
                search: "",
                isLoading: true,
            };
        },
        created() {
            const getUrl = (new URL(window.location));
            (ICONS.filterIcon) ? this.filterIcon = ICONS.filterIcon : null;
            this.getTaxonomies(getUrl);
        },
        mounted() {
            const getUrl = (new URL(window.location));
            let page = PRODUCTS.page !== undefined ? PRODUCTS.page : 1;
            this.currentPage = parseInt(page);

            if (getUrl.search.length === 0) this.getListing(this.currentPage);
            // Params for testing
            // ?format=audio-cd,forms,coursebook&ctype=areas-of-professional-practice,skills&practice-area=administrative-law,banking&credit-range=min-1,max-15&search=test
            // ?format=audio-cd,forms,coursebook&ctype=areas-of-professional-practice,skills&practice-area=administrative-law,banking&credit-range=min-1,max-15&search=test&get_page=5

            const $programFiltersSidebar = $(".program-filters-sidebar aside");
            let btn_left = $programFiltersSidebar.offset().left;
            $(".back-top").css("left", btn_left + "px");

            $(window).on("resize", function() {
                let btn_left = $programFiltersSidebar.offset().left;
                $(".back-top").css("left", btn_left + "px");
                Waypoint.refreshAll();
            });

            $programFiltersSidebar.each(function(e) {
                let show_hide_btn1 = $(this);
                let waypoint_show_hide_btn1 = new Waypoint({
                    element: show_hide_btn1[0],
                    handler: function(direction) {
                        if (direction === "down") {
                            $(".back-top").addClass("on");
                        }
                        if (direction === "up") {
                            $(".back-top").removeClass("on");
                        }
                    },
                    offset: function() {
                        return this.context.innerHeight() - this.adapter.outerHeight() - 100;
                    }
                });
            });

            this.waypoints();

            if (window.history && window.history.pushState) {
                window.addEventListener("popstate", () => {
                    const hashLocation = location.hash;
                    const hashSplit = hashLocation.split("#!/");
                    const hashName = hashSplit[1];
                    if (hashName !== "") {
                        const hash = window.location.hash;
                        if (hash === "") {
                            const getUrl = (new URL(window.location));
                            this.isLoading = true;
                            this.getTaxonomies(getUrl, true);
                        }
                    }
                });
            }

        },
        updated() {
            this.waypoints();
        },
        methods: {
            waypoints() {
                const pagination = document.querySelectorAll(".listing-sections .pagination");
                const filtersSidebar = document.querySelectorAll(".program-filters-sidebar aside");

                filtersSidebar.forEach(value => {
                    new Waypoint({
                        element: value,
                        handler: function(direction) {
                            if (direction === "down") $(".back-top").addClass("on");
                            if (direction === "up") $(".back-top").removeClass("on");
                        }
                    });
                });

                pagination.forEach(value => {
                    new Waypoint({
                        element: value,
                        handler: function(direction) {
                            if (direction === "down") $(".back-top").removeClass("on");
                            if (direction === "up") $(".back-top").addClass("on");
                        },
                        offset: "bottom-in-view"
                    });
                });
            },
            getTaxonomies(getUrl = false, reset = false) {
                this.isLoading = true;
                const api = (SIDEBAR.taxApi && typeof SIDEBAR !== "undefined") ? SIDEBAR.taxApi : "wp-json/products/v1/taxonomy";

                reset ? this.resetFilters(false) : null;
                reset ? this.posts = [] : null;

                axios
                    .get(`${api}`)
                    .then(({data, status}) => {
                        if (status === 200) {
                            (data.type) ? this.typeTerms = data.type : null;
                            // if (getUrl && !getUrl.search.length > 0) {
                                (data.practiceArea) ? this.paTerms = data.practiceArea : null;
                            // }
                            (data.creditType) ? this.creditTerms = data.creditType : null;
                            return true;
                        }
                    })
                    .catch(err => console.log(err))
                    .then(() => {
                        if (getUrl && getUrl.search.length > 0) {
                            const urlParams = getUrl.search.replace("?", "").split("&");
                            setTimeout(() => {
                                urlParams.map(param => this.checkParams(param));
                                return true;
                            }, 500);
                        }
                    })
                    .finally(() => setTimeout(() => this.getListing(this.currentPage), 500));
            },
            checkParams(param) {
                const paramSplit = param.split("=");
                const paramType = paramSplit[0];
                const paramVal = paramSplit[1];
                let splitSlugs = paramVal.split(",");

                /**
                 * This sets up the product format param
                 */
                if (paramType === "format") {
                    try { const mapFormatSlugs = splitSlugs.map(type => this.queryFormatFilters(type));} catch (e) { console.log(e); }
                }

                /**
                 * This sets up our credit-type param
                 */
                if (paramType === "ctype") {
                    try {const mapTypeSlugs = splitSlugs.map(type => this.queryCreditsFilters(type));} catch (e) { console.log(e); }
                }

                /**
                 * This sets up our practice-area param
                 */
                if (paramType === "practice-area") {
                    try {const mapPracticeAreaSlugs = splitSlugs.map(type => this.queryPracticeAreaFilters(type));} catch (e) { console.log(e); }
                }

                /**
                 * This sets up our credit-type param
                 */
                if (paramType === "credit-range") {
                    this.filters.creditRange = {};
                    try {const mapCreditRangeValues = splitSlugs.map(type => this.queryCreditRange(type));} catch (e) { console.log(e); }
                }

                /**
                 * This sets up our search query
                 */
                if (paramType === "search") {
                    try {
                        const searchVal = paramVal.replaceAll('+', ' ');
                        this.search = this.filters.searchText = searchVal;
                    } catch (e) { console.log(e); }
                }

                /**
                 * This sets up our pagination
                 */
                if (paramType === "get_page") {
                    try {this.currentPage = parseInt(paramVal);} catch (e) { console.log(e); }
                }

                return true;
            },
            getListing(page) {
                let api = PRODUCTS.api;
                let config;
                this.isLoading = true;

                Object.keys(this.filters).forEach((key) => {
                    if (key.length > 0) {
                        this.posts = [];
                        config = {params: this.filters};
                        api = PRODUCTS.filterApi;
                    }
                });

                axios.get(`${api + page}`, config)
                     .then(({data, status}) => {
                         if (status === 200) {
                             this.posts = data.posts || [];
                             this.maxPage = data.maxPages;
                             this.paTerms = data.practiceAreas || [];
                         }
                     })
                     .then(() => this.isLoading = false)
                     .catch(err => console.log(err));
                return;
            },
            gotoPage(pageNum, url = null) {
                // Add animate scroll
                if ((event !== undefined) && (event.currentTarget.classList.contains("page-numbers") || event.currentTarget.classList.contains("next") || event.currentTarget.classList.contains("prev"))) {
                    $("html, body").animate({
                        scrollTop: $("#listing-top").offset().top
                    }, 1000);
                }

                /**
                 * Grab our posts
                 */
                this.currentPage = pageNum;
                this.getListing(pageNum, this.filters);

                // Grab URL Params
                let windowUrl = url || window.location;
                windowUrl = (new URL(windowUrl));
                let searchParams = windowUrl.search.replace("?", "");
                searchParams = new URLSearchParams(searchParams);

                // Update get_page to the current page
                if (searchParams.has("get_page")) searchParams.set("get_page", pageNum);
                // Add get_page if it doesn't exist
                else searchParams.append("get_page", pageNum);

                searchParams = searchParams.toString();

                const newurl = `${windowUrl.origin + windowUrl.pathname}?${decodeURIComponent(searchParams)}`;
                window.history.pushState({path: newurl}, "", newurl);
            },
            buildFormats(format) {
                if (format.currentTarget !== undefined) format = format.currentTarget;
                this.removeAll("All Formats", "#product-format-dropdown input#all-formats");
                let removeType = false;
                /**
                 * If the type is checked
                 */
                if (format.checked) {
                    this.filters.types.push(format.id);
                    this.filterItems.push(format.dataset.name);
                }
                else {
                    const removeId = this.filters.types.indexOf(format.id);
                    const removeName = this.filterItems.indexOf(format.dataset.name);
                    if (removeId > -1) this.filters.types.splice(removeId, 1);
                    if (removeName > -1) this.filterItems.splice(removeName, 1);
                    removeType = true;
                }

                let newUrl = this.buildUrlQuery("format", format.dataset.formatSlug, removeType);
                console.log(newUrl);

                this.gotoPage(1, newUrl);
            },
            buildCreditType(creditType) {
                creditType = creditType.currentTarget;
                this.removeAll("All Credit Types", "#product-credit-types input#all-credit-types");
                let removeType = false;

                /**
                 * If the Region is checked
                 */
                if (creditType.checked) {
                    this.filters.creditType.push(creditType.id);
                    this.filterItems.push(creditType.dataset.name);
                }
                else {
                    const removeId = this.filters.creditType.indexOf(creditType.id);
                    const removeName = this.filterItems.indexOf(creditType.dataset.name);
                    if (removeId > -1) this.filters.creditType.splice(removeId, 1);
                    if (removeName > -1) this.filterItems.splice(removeName, 1);
                    removeType = true;
                }

                const newUrl = this.buildUrlQuery("ctype", creditType.dataset.ctypeSlug, removeType);

                this.gotoPage(1, newUrl);
            },
            buildCredits(creditRange) {
                let removeType = false;
                const minRange = (creditRange.min) ? creditRange.min : null;
                const maxRange = creditRange.max;
                this.filters.creditRange = {};
                this.filterCreditRange = {};

                if (minRange) {
                    this.filters.creditRange.min = minRange;
                    this.filterCreditRange.min = minRange;
                }
                else if (minRange === null) removeType = true;

                if (maxRange) {
                    removeType = true;
                    this.filters.creditRange.max = maxRange;
                    this.filterCreditRange.max = maxRange;
                }
                else if (!maxRange) removeType = true;

                const newUrl = this.buildUrlQuery("credit-range", this.filterCreditRange, removeType);

                this.gotoPage(1, newUrl);
            },
            buildPracticeArea(practiceArea) {
                practiceArea = practiceArea.currentTarget;
                this.removeAll("All Practice Areas", "#practice-area-dropdown input#all-pa");
                let removeType = false;

                /**
                 * If the Format is checked
                 */
                if (practiceArea.checked) {
                    this.filters.practiceArea.push(practiceArea.id);
                    this.filterItems.push(practiceArea.dataset.name);
                }
                else {
                    const removeId = this.filters.practiceArea.indexOf(practiceArea.id);
                    const removeName = this.filterItems.indexOf(practiceArea.dataset.name);
                    if (removeId > -1) this.filters.practiceArea.splice(removeId, 1);
                    if (removeName > -1) this.filterItems.splice(removeName, 1);
                    removeType = true;
                }

                const newUrl = this.buildUrlQuery("practice-area", practiceArea.dataset.paSlug, removeType);

                this.gotoPage(1, newUrl);
            },
            buildSearch(searchText) {
                console.log(searchText);
                searchText ? this.filters.searchText = searchText : this.filters.searchText = "";
                searchText ? this.search = searchText : this.search = "";

                const newUrl = this.buildUrlQuery("search", searchText);
                this.gotoPage(1, newUrl);
            },
            getAllFormats() {
                const allTypesName = event.currentTarget.dataset.name;

                this.allTax("#product-format-dropdown", "#all-formats", allTypesName);

                this.filters.types = [];

                const newUrl = this.buildUrlQuery('format', 'remove');

                this.gotoPage(1, newUrl);
            },
            getAllCreditTypes() {
                const allName = event.currentTarget.dataset.name;

                this.allTax("#product-credit-types", "#all-credit-types", allName);

                this.filters.creditType = [];
                const newUrl = this.buildUrlQuery('ctype', 'remove');

                this.gotoPage(1, newUrl);
            },
            getAllPA() {
                const allPaName = event.currentTarget.dataset.name;

                this.allTax("#practice-area-dropdown", "#all-pa", allPaName);

                this.filters.practiceArea = [];
                const newUrl = this.buildUrlQuery('practice-area', 'remove');

                this.gotoPage(1, newUrl);
            },
            allTax(elm, inputElm, name) {
                this.filterItems.sort();

                /**
                 * Uncheck all the other checkboxes and remove them from the filterList
                 */
                let inputs = document.querySelectorAll(`${elm} input:not(${inputElm})`);
                inputs.forEach((elm) => {
                    const removeThisName = this.filterItems.indexOf(elm.dataset.name);
                    if (removeThisName > -1) {
                        this.filterItems.splice(name, 1);
                    }
                });

                if (event.currentTarget.checked) {
                    this.filterItems.push(name);
                    $(elm).find(`input:checkbox:not(${inputElm})`).prop("checked", false);
                }
                else {
                    const removeName = this.filterItems.indexOf(name);
                    if (removeName > -1) this.filterItems.splice(removeName, 1);
                    $(elm).find(`input${inputElm}`).prop("checked", false);
                }
            },
            clearSearchText(searchText) {
                this.search = "";
                this.filters.searchText = "";
                const newUrl = this.buildUrlQuery("search", searchText, true);
                this.gotoPage(1, newUrl);
            },
            removeAll(name, elm) {
                /**
                 * Find out if the All checkbox is checked, and make sure to remove it from our filterList array
                 */
                if (this.filterItems.indexOf(name) > -1) this.filterItems.splice(name, 1);
                const allCheckbox = $(elm);
                if (allCheckbox.prop("checked") === true) {
                    allCheckbox.prop("checked", false);
                }
            },
            removeItem(elm) {
                let el;
                let newUrl = '';
                if (elm.target.nodeName === "SPAN") el = elm.target.parentNode;
                else el = elm.target;
                const name = el.dataset.name;

                /**
                 * Attempts to remove the item from the filterList
                 * @type {string}
                 */
                const sidebar = document.querySelector(".sidebar-filters");
                const item = sidebar.querySelector(`[data-name="${name}"]`);
                const itemId = item.id ? (item.id).toString() : null;
                if (itemId !== null) {
                    item.checked = false;
                    const removeFilterItem = this.filterItems.indexOf(name);
                    if (removeFilterItem > -1) this.filterItems.splice(removeFilterItem, 1);

                    /**
                     * Need to determine if there is a slug on our item
                     * so that we can update the URL to omit this item
                     */
                    let queryType = null;
                    let elmSlug = null;
                    const dataset = item.dataset || null;
                    if (dataset !== null) {
                        if (dataset.paSlug !== undefined) {
                            queryType = "practice-area";
                            elmSlug = dataset.paSlug;

                            const removeFilterId = this.filters.practiceArea.indexOf(itemId);
                            if (removeFilterId > -1) this.filters.practiceArea.splice(removeFilterId, 1);
                        }
                        else if (dataset.formatSlug !== undefined) {
                            queryType = "format";
                            elmSlug = dataset.formatSlug;

                            const removeFilterId = this.filters.types.indexOf(itemId);
                            if (removeFilterId > -1) this.filters.types.splice(removeFilterId, 1);
                        }
                        else if (dataset.ctypeSlug !== undefined) {
                            queryType = "ctype";
                            elmSlug = dataset.ctypeSlug;

                            const removeFilterId = this.filters.creditType.indexOf(itemId);
                            if (removeFilterId > -1) this.filters.creditType.splice(removeFilterId, 1);
                        }

                        newUrl = this.buildUrlQuery(queryType, elmSlug, true);
                    }
                }

                this.gotoPage(1, newUrl);
            },
            resetFilters(resetUrl = true) {
                let newUrl = '';
                const inputCreditRange = document.querySelectorAll(".credit-range-input");
                const inputCheckbox = $("#product-format-dropdown, #product-credit-types, #practice-area-dropdown");

                this.filters = {
                    types: [],
                    // format: [],
                    creditType: [],
                    practiceArea: [],
                    searchText: ""
                };
                this.search = "";
                this.filterCreditRange = {};
                this.filterItems = [];

                /**
                 * Reset input fields
                 */
                inputCheckbox
                    .find("input:checkbox")
                    .prop("checked", false);
                inputCreditRange.forEach((elm) => elm.value = "");

                /**
                 * Rebuild the query if it's not called from a back button event
                 */
                resetUrl ? newUrl = this.buildUrlQuery("reset-all", null) : null;

                /**
                 * Reset Listing if we are rebuilding the URL Query
                 */
                resetUrl ? this.gotoPage(1, newUrl) : null;
            },
            queryFormatFilters(slug) {
                const formatElm = document.querySelector(`[data-format-slug="${slug}"]`);
                if (formatElm) formatElm.checked = true;
                this.filters.types.push(formatElm.id);
                this.filterItems.push(formatElm.dataset.name);
            },
            queryCreditsFilters(slug) {
                const formatElm = document.querySelector(`[data-ctype-slug="${slug}"]`);
                formatElm.checked = true;
                this.filters.creditType.push(formatElm.id);
                this.filterItems.push(formatElm.dataset.name);
            },
            queryPracticeAreaFilters(slug) {
                const formatElm = document.querySelector(`[data-pa-slug="${slug}"]`);
                formatElm.checked = true;
                this.filters.practiceArea.push(formatElm.id);
                this.filterItems.push(formatElm.dataset.name);
            },
            queryCreditRange(values) {
                const splitArr = values.split("-");
                const arrKey = splitArr[0];
                const arrVal = splitArr[1];
                const rangeInput = document.getElementById(`credit-${arrKey}`);
                rangeInput.value = arrVal;
                this.$set(this.filterCreditRange, arrKey, arrVal);
                this.$set(this.filters.creditRange, arrKey, arrVal);
            },
            buildUrlQuery(paramType = "", elm = "", remove = false) {
                if (paramType === null) return;

                //?format=audio-cd,forms,coursebook&ctype=areas-of-professional-practice,skills&practice-area=administrative-law,banking&credit-range=min-1,max-15&search=test&get_page=1
                let windowUrl = (new URL(window.location));
                let searchParameters = windowUrl.search.replace("?", "");
                searchParameters = new URLSearchParams(searchParameters);

                // See if there are search params at all
	            if (elm === 'remove') {
	                searchParameters.delete(paramType);
	            }
                else if (windowUrl.search.length) {
	                if (searchParameters.has(paramType) && paramType !== 'credit-range') {
                        let existingVals = searchParameters.get(paramType);
                        if (paramType === 'reset-all') searchParameters = [];
                        else if (!remove && elm.length) {
                            if (paramType === 'search') existingVals = elm;
                            else existingVals = `${existingVals},${elm}`;

                            existingVals = Array.from(new Set(existingVals.split(","))).toString();

                            // searchParameters.delete(paramType);
                            searchParameters.set(paramType, existingVals);
                        }
                        else if (elm.length) {
	                        existingVals = existingVals.split(',');

	                        if (existingVals.length > 1) {
                                const elmIndex = existingVals.indexOf(elm);
                                if (elmIndex > -1) existingVals.splice(elmIndex, 1);
                                searchParameters.set(paramType, existingVals.toString());
                            }
                            else searchParameters.delete(paramType);
	                    }
                        else searchParameters.delete(paramType);
	                }
	                else if (paramType === 'credit-range') this.buildCreditRange(elm, paramType, searchParameters);
                    else if (paramType === "reset-all") searchParameters = [];
                    else {
                        if (elm.length) searchParameters.append(paramType, elm);
                        else searchParameters.delete(paramType, elm);
                    }
                }
                else {
                    if (paramType === 'credit-range') this.buildCreditRange(elm, paramType, searchParameters);
                    else {
                        if (!remove && elm.lenth) searchParameters.append(paramType, elm);
                        else if (paramType === "reset-all") searchParameters = [];
                        else searchParameters.delete(paramType);
                    }
                }

                searchParameters = searchParameters.toString();
                searchParameters = searchParameters.length ? `${decodeURIComponent(searchParameters)}` : `get_page=1`;
                return `${windowUrl.origin + windowUrl.pathname}?${searchParameters}`;

            },
	        buildCreditRange(elm, paramType, searchParameters) {
                let newParamVal = [];
                const minRange = (elm.min) || null;
                const maxRange = (elm.max) || null;

                if (minRange) newParamVal.push(`min-${minRange}`);
                if (maxRange) newParamVal.push(`max-${maxRange}`);

                if (newParamVal.length) {
                    newParamVal = newParamVal.join(",");
                    searchParameters.delete(paramType);
                    searchParameters.set(paramType, newParamVal)
                }
                return searchParameters;
	        }
        },
        components: {ListContainer, Paginate, Sidebar,},
        name: "StoreListing"
    };
</script>
