<template>
    <div class="programs-slider__container">
        <div v-if="loading"
             class="loading-container">
            <Loading :loading="loading" />
        </div>

        <NewsSlider v-if="posts"
                    :posts="posts" />
    </div>
</template>

<script type="application/javascript">
    import axios from "axios";

    import Loading from "./Blocks/Loading.vue";
    import NewsSlider from "./Homepage/NewsSlider.vue";

    export default {
        data() {
            return {
                api: HOME.newsApi,
                loading: true,
                posts: null,
            };
        },
        created() {
            this.getPosts();
        },
        methods: {
            getPosts() {
                axios.get(this.api)
                     .then(({status, data}) => this.posts = status === 200 && data )
                     .catch(e => console.error(e))
                     .finally(() => this.loading = false);

            },
        },
        name: "HomepageNewsBlock",
        components: {Loading, NewsSlider}
    };
</script>

<style lang="scss" scoped>

    .featured-news__container {
        position: relative;
    }

    .loading-container {
        position:        relative;
        display:         flex;
        flex-flow:       row nowrap;
        align-items:     center;
        justify-content: center;
        width:           100%;
        min-height:      100px;
    }

</style>
