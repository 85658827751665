<template>
	<div class="calendar-monthly-weekly">

		<EventHeaderCategories v-if="eventCategories"
		                       :types="eventCategories"
		                       @eventSearchFilter="eventSearchFilter" />

		<EventResultsTitle :results="results" />

		<p class="registered-event">
			<img :src="icons.registered"
			     alt="Registered Event" /> Registered Event</p>

		<div class="tablet-advanced-settings-toggle">
			<div class="tablet-advanced-filters">
				<img :src="icons.filter"
				     alt="advanced filters icons"> Open Advanced Filters
			</div>
		</div>

		<div id="tablet-calendar-settings" class="tablet-calendar-settings-container">
			<EventTabletFilters v-if="eventCategories && $mq.below(1025)"
			                    :types="eventCategories"
			                    :icons="icons"
			                    @eventSearchFilter="eventSearchFilter"
			                    @searchReset="eventsReset" />
		</div>

		<div id="calendar-mynysba-container">
			<div class="loading calendar-loading"></div>
			<div v-if="events.length === 0 && !loading"
			     class="no-events">
				<NoResults :no-results-text="noResultsText" />
			</div>
			<FullCalendar ref="calendar"
			              v-if="events !== null && !loading"
			              :custom-buttons="config.customNavigationButtons"
			              :dates-render="handleChange"
			              :default-view="config.defaultView"
			              :editable="config.editable"
			              :event-limit-click="eventLimitCheck"
			              :event-render="eventRender"
			              :events="events"
			              :handle-window-resize="mediaQueryHandler"
			              :header="config.header"
			              :nav-links="true"
			              :next-day-threshold="config.nextDayThreshold"
			              :now="now"
			              :plugins="config.plugins"
			              :unselect-auto="false"
			              :views="config.views"
			              @eventMouseEnter="monthlyMouseEnter"
			              @eventMouseLeave="monthlyMouseLeave"
			              @dateClick="tooltipHide"
			              @eventResizeStart="tooltipHide"
			              @eventDragStart="tooltipHide"
			              @viewDisplay="tooltipHide"
			              @windowResize="mediaQueryHandler(true)"
			/>
		</div>

		<div id="modal" class="modal">
			<div class="modal-events"></div>
		</div>
		<div class="calendar-modal-overlay"></div>

	</div>
</template>

<script type="application/javascript">
    // jQuery Libraries
    import axios from "axios";
    import moment from "moment";
    import momentPlugin from "@fullcalendar/moment";
    import dayGridPlugin from "@fullcalendar/daygrid";
    import interactionPlugin from "@fullcalendar/interaction";
    import {EventBus} from "../../../../Util/event-bus.js";

    // Vue Components
    import EventHeaderCategories from "./EventHeaderCategories.vue";
    import EventModal from "../../../Calendar/Monthly/EventModal.vue";
    import EventPopup from "../../../Calendar/Monthly/EventPopup.vue";
    import EventResultsTitle from "./EventResultsTitle.vue";
    import EventTabletFilters from "./EventTabletFilters.vue";
    import FullCalendar from "@fullcalendar/vue";
    import Legend from "../../../Calendar/Header/CalendarLegend.vue";
    import NoResults from "../../NoResults.vue";
    import SingleEventMonthModal from "../../../Calendar/Monthly/SingleEventModal.vue";

    export default {
        props: {
            eventCategories: [Array, Object],
            eventsRegistered: [Array, Object],
            loading: Boolean
        },
        data() {
            return {
                activeDate: "",
                config: {
                    header: {
                        left: "prev",
                        center: "title",
                        right: "next"
                    },
                    customNavigationButtons: {
                        prev: {
                            text: "",
                            icon: "chevron-left",
                            click: () => {
                                this.$refs.calendar.getApi().prev();
                            }
                        },
                        next: {
                            text: "",
                            icon: "chevron-right",
                            click: () => {
                                this.$refs.calendar.getApi().next();
                            }
                        }
                    },
                    views: {},
                    defaultView: "dayGridMonth",
                    plugins: [momentPlugin, dayGridPlugin, interactionPlugin],
                    nextDayThreshold: "23:59:59",
                    editable: false,
                    eventLimitText: " events",
                    date: ""
                },
                eventDate: null,
                events: [],
                filters: {
                    view: "month",
                    date: {first: "", last: ""},
                    types: [],
                },
                icons: {
                    registered: null,
                    filter: null,
                    filterPlus: null
                },
                noResultsText: "",
                now: moment().format("YYYY-MM-DD"),
                results: 0,
                registeredIcon: null,
            };
        },
        created() {
            (ICONS.eventRegistered.length) ? this.icons.registered = ICONS.eventRegistered : null;
            (ICONS.filterIcon.length) ? this.icons.filter = ICONS.filterIcon : null;
            (ICONS.filterPlus) ? this.icons.filterPlus = ICONS.filterPlus : null;
            (MYNYSBA.tabs.notFoundText.events.length) ? this.noResultsText = MYNYSBA.tabs.notFoundText.events : null;

            this.eventDate = this.now;
            this.filters.date.first = moment().startOf("month").format("YYYY-MM-DD");
            this.filters.date.last = moment().endOf("month").format("YYYY-MM-DD");

            /**
             * Our event bus to grab the event ID to open the modal for single events on
             * tablet and mobile
             */
            EventBus.$on("modal-event-id", eveId => this.monthlyModal(eveId));

            this.mediaQueryHandler();
            this.getEvents(this.filters);

        },
        mounted() {
            const loading = $(".calendar-loading");
            this.toggleSelectedDayHeader(this.now, $(".fc-today a"));
            this.loading ? loading.show() : loading.hide();
        },
        methods: {
            getEvents(filter) {

                const loading = $(".calendar-loading");
                loading.show();

                this.events = {};

                const api = (MYNYSBA.eventsApi !== undefined) ? MYNYSBA.eventsApi : "";
                let config = {params: filter};

                axios.get(`${api}`, config)
                     .then(({data, status}) => {
                         if (status === 200) {
                             this.events = (data.hasOwnProperty("events")) ? data.events : {};
                         }
                     })
                     .then(() => {
                         this.results = this.events.length || 0;

                         /**
                          * Reset the classes for event-dates and the events array
                          */
                         const fcDay = document.querySelectorAll(".fc td.fc-day.has-events");
                         const fcToolbar = $(".fc-toolbar");
                         fcDay.forEach(elm => elm.classList.remove("has-events"));

                         /**
                          * Generates a legend on Tablet and Mobile
                          */
                         // if (this.$mq.below(1025)) {
                         //     fcToolbar.find(".calendar-keys").remove();
                         //     const legend = Vue.extend(Legend);
                         //     const instance = new legend();
                         //     instance.$mount();
                         //     fcToolbar.append(instance.$el);
                         // }

                         loading.hide();

                     })
                     .catch(err => console.log(err));
            },
            eventRender(eve) {
                const eventDate = eve.event.start;
                const eventProps = eve.event.extendedProps;
                const eventId = eve.event.id;
                let cats;
                let modalDate = moment(eventDate).format("MMMM DD, YYYY");

                // Add the classes to dates that have events
                this.eventStart = moment(eventDate).format("YYYY-MM-DD");
                this.toggleHasEvents(eve);

                eve.el.dataset.id = eve.event.id;

                if (this.$mq.below(1025)) {
                    eve.el.dataset.date = modalDate;
                    if (eventProps.hasOwnProperty("categoryName"))
                        eventProps.categoryName.map(({slug}, index) => eve.el.setAttribute(`data-cat${index}`, slug));
                    return this.calFilter(eve.event, "tablet-calendar-settings");
                }
                else if (this.$mq.above(1025)) {
                    if (!$(".filter-all").hasClass("active"))
                        return this.calFilter(eve.event, "desktop-calendar-settings");
                }

                if (this.eventsRegistered.indexOf(Number(eventId)) !== -1) eve.el.innerHTML += `<span class="registered-icon">Registered</span>`;

            },
            eventLimitCheck(events) {
                const eventsArray = events.segs;
                const eventDate = moment(events.date).format("MMMM DD, YYYY");
                const eventModal = Vue.extend(EventModal);
				const propData = {
                    eventDate: eventDate,
                    events: eventsArray,
					eventsRegistered: this.eventsRegistered,
                    closeIcon: ICONS.close || ""
                };

                eventsArray.map(item => {
                    if (this.eventsRegistered.indexOf(Number(item.el.dataset.id)) !== -1) {
                        item.eventRange.def.extendedProps.categoryName.push({
                            id: 0,
                            name: 'Registered',
                            slug: 'registered',
                            img: this.icons.registered
                        });
                    }
                });

                const instance = new eventModal({propsData: propData});

                /**
                 * Mount the EventModal with the data provided and open the modal window
                 */
                instance.$mount();
                const modal = document.getElementById("modal");
                const modalChild = modal.querySelector("[class=\"modal-events\"]");
                modalChild.appendChild(instance.$el);
                modal.style.display = "block";
            },
            toggleHasEvents(eve) {
                const compareDate = this.eventStart;
                const date = eve.view.currentStart;

                const fcDay = document.querySelectorAll(".fc td.fc-day");
                const startOfRange = moment(date).startOf("month").format("YYYY-MM-DD");
                const endOfRange = moment(date).endOf("month").format("YYYY-MM-DD");

                /**
                 * Adds a class to the days that have events
                 * @type {string}
                 */
                if (compareDate !== undefined) {
                    fcDay.forEach(elm => {
                        let elmDate = $(elm).data("date");
                        if (elmDate === compareDate && (compareDate >= startOfRange && compareDate <= endOfRange)) {
                            $(elm).addClass("has-events");
                        }
                    });
                }

            },
            handleChange(eve) {
                let firstDate, lastDate;
                let calApi = this.$refs.calendar.getApi();

                if (eve.hasOwnProperty("view")) {
                    const date = eve.view.currentStart;
                    firstDate = moment(date).startOf("month").format("YYYY-MM-DD");
                    lastDate = moment(date).endOf("month").format("YYYY-MM-DD");
                }

                firstDate ? this.filters.date.first = firstDate : null;
                lastDate ? this.filters.date.last = lastDate : null;
                this.getEvents(this.filters);
                this.toggleHasEvents(calApi);
            },
            mediaQueryHandler(resized = false) {
                $("#calendar .fc-prev-button .prev-dates, #calendar .fc-next-button .next-dates").remove();
                this.fullCalendarConfigMediaQuery();

                if (resized) this.getEvents(this.filters);

            },
            fullCalendarConfigMediaQuery() {
                /**
                 * Tablet View
                 */
                if (this.$mq.between([768, 1025])) {
                    this.config.views = {
                        dayGridMonth: {
                            eventLimit: 1,
                            eventLimitText: numEvents => `${numEvents} events`,
                            columnHeaderFormat: {weekday: "short"},
                            titleFormat: {
                                month: "long",
                            },
                        },
                    };
                }
                /**
                 * Mobile View
                 */
                else if (this.$mq.below(768)) {
                    this.config.views = {
                        dayGridMonth: {
                            eventLimit: 1,
                            eventLimitText: numEvents => `+ ${numEvents}`,
                            columnHeaderFormat: {weekday: "short"},
                            titleFormat: {
                                month: "long",
                            },
                        },

                    };
                }
                /**
                 * Desktop View
                 */
                else if (this.$mq.above(1025)) {
                    this.config.views = {
                        dayGridMonth: {
                            eventLimit: 3,
                            eventLimitText: numEvents => `+ ${numEvents} more`,
                            columnHeaderFormat: {weekday: "long"},
                            titleFormat: {
                                month: "long",
                            },
                        }
                    };
                }
            },
            calFilter(theEvent, elmTargetId) {
                let vals = [];
                $(`#${elmTargetId} .control`).each(
                    elm => ($(elm).hasClass("active")) ? vals.push($(elm).data("category")) : null
                );
                for (let i = 0; i < vals.length; i++) {
                    return (vals.indexOf(theEvent.classNames[i]) === -1);
                }
            },
            eventsReset() {
                this.filters.types = [];
                this.filters.date.first = moment(this.now).startOf("month").format("YYYY-MM-DD");
                this.filters.date.last = moment(this.now).endOf("month").format("YYYY-MM-DD");
                this.getEvents(this.filters)
            },
            monthlyMouseEnter(e) {
                const elm = e.el;
                const elmEvent = e.event;
                const popup = Vue.extend(EventPopup);
                let cats;

                if (elmEvent.extendedProps.hasOwnProperty("categoryName")) cats = elmEvent.extendedProps.categoryName;

                const instance = new popup({
                    propsData: {
                        title: elmEvent.title,
                        cats: cats
                    }
                });

                instance.$mount();

                const popupElm = $(instance.$el).appendTo("body");
                let offsetTop = $(elm).offset().top - ($(elm).height() + 110);
                let offsetLeft = $(elm).offset().left - ($(elm).width()/2);
                popupElm.css({top: offsetTop + "px", left: offsetLeft + "px"});

                $(elm).mouseover(() => {
                    $(elm).css("z-index", 1000);
                    popupElm.fadeIn(500).fadeTo("10", 1.9);
                }).mousemove(e => {
                    let $tooltip = $(".tooltip");
                    let offsetTop = e.pageY - ($tooltip.height() + 110);
                    let offsetLeft = e.pageX - ($tooltip.width()/2);
                    $tooltip.css({top: offsetTop + "px", left: offsetLeft + "px"});
                });

            },
            monthlyMouseLeave(eve) {
                const tooltip = document.querySelector(".tooltip");
                eve.el.style.zIndex = 8;
                tooltip.remove();
            },
            tooltipHide() {
                document.querySelector(".tooltip").hide();
            },
            toggleSelectedDayHeader(date, parent, navClicked = false) {
                if (parent !== null) {
                    /**
                     * Disable the clicking of the today's header
                     */
                    if (parent.hasClass("selected")) return false;

                    this.eventDate = moment(date).format("YYYY-MM-DD");

                    // navClicked ? this.eventSearchDate = this.eventDate : null;
                    navClicked ? this.activeDate = this.eventDate : null;

                    const navLinks = $(".fc-day-header")
                        .map((i, el) => $(el).find("a").removeClass("active-link selected"));

                    if (this.view === "month" && this.$mq.above(1025)) {
                        const tableHeader = $(parent).parents(".fc-day-top");
                        let dataDate = tableHeader[0].dataset.date;
                        if (dataDate === this.now) $(tableHeader)
                            .append("<span class=\"date-text today-text\">Today</span>");
                    }

                    if (this.view === "week") {
                        $(parent).addClass("active-link selected");
                    }
                }
            },
            monthlyModal(envId) {
                /**
                 * This is used to create a modal for Tablet and Mobile Single Events
                 */
                const calApi = this.$refs.calendar.getApi();
                if (calApi !== undefined) {
                    const eventsArray = calApi.getEventById(envId);
                    const eventModal = Vue.extend(SingleEventMonthModal);
                    const propData = {
                        events: eventsArray,
                        eventDate: moment(eventsArray.start).format("MMMM DD, YYYY"),
                        closeIcons: this.icons.close,
                    };

                    eventsArray.map(item => {
                        if (this.eventsRegistered.indexOf(Number(item.el.dataset.id)) !== -1) {
                            item.eventRange.def.extendedProps.categoryName.push({
                                id: 0,
                                name: 'Registered',
                                slug: 'registered',
                                img: this.icons.registered
                            });
                        }
                    });

                    const instance = new eventModal({propsData: propData});

                    instance.$mount();
                    const modal = document.getElementById("modal");
                    const modalChild = modal.querySelector("[class=\"modal-events\"]");
                    modalChild.appendChild(instance.$el);
                    modal.style.display = "block";
                }
            },
            eventSearchFilter(elm) {
                const calendarApi = this.$refs.calendar.getApi();
                const cat = elm.dataset.cat;
                const name = elm.dataset.name;

                if (elm.classList.contains("active") || elm.checked) {
                    if (name === "all-events" || name === "all-tablet-regions") this.filters.types = [];
                    else this.filters.types.push(cat);
                }
                else {
                    if (name !== "all-events" || name !== "all-tablet-regions") {
                        if (this.filters.types.indexOf(cat > -1))
                            this.filters.types.splice(this.filters.types.indexOf(cat), 1);
                    }
                }

                /**
                 * Handlers for month view filtering
                 */
                this.eventStart = calendarApi.view.eventStart || this.eventStart;
                this.getEvents(this.filters);

            },
        },
        watch: {
            loading(loadingChange) {
                const loading = $(".calendar-loading");
                if (loadingChange) loading.show();
                else loading.hide();
            }
        },
        components: {
            EventHeaderCategories,
            EventResultsTitle,
            EventTabletFilters,
            FullCalendar,
            NoResults
        },
        name: "CalendarBlock"
    };
</script>

<style lang="scss" scoped>
	#calendar-mynysba-container {
		position:   relative;
		min-height: 50vh;
	}
</style>
