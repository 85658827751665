<template>
    <div class="tag-posts">
        <Loading :loading="loading" />
        <TagPost v-if="posts.length"
                 v-for="post in posts"
                 :key="post.id"
                 :post="post" />
    </div>
</template>

<script type="application/javascript">
    import axios from "axios";
    import {EventBus} from "./../Util/event-bus.js";
    
    import Loading from "./Blocks/Loading.vue";
    import TagPost from "./Blocks/TagPost.vue";

    export default {
        components: {TagPost, Loading},
        data() {
            return {
                currentPage: 1,
                loading: true,
                page: 1,
                posts: [],
            }
        },
        created() {
            this.tagId = TAGS.tagId;
            this.getTagPosts();
            EventBus.$on("tag-page", data => this.gotoPage(data));
        },
        mounted() {
        },
        methods: {
            getTagPosts(page = 1) {
                let config = {
                    params: {
                        tag_id: TAGS.tagId || 0,
                        user_logged_in: TAGS.userLoggedIn,
                    }
                };
                const api = (TAGS.api) || "wp-json/nysba-posts/v1/tag-posts/";
    
                this.loading = true;
                this.currentPage = page;
    
                axios
                    .get(`${api + (page || 1)}`, config)
                    .then(({data, status}) => {
                        if (status === 200) {
                            this.posts = data.posts;
                            this.$store.commit('tagPostPagination', {maxPage: data.maxPages, currentPage: page});
                            return true;
                        }
                    })
                    .catch(err => console.log(err))
                    .then(() => this.loading = false)
                    .finally(() => this.postStickySidebar())
            },
            gotoPage(pageNum) {
                if ((event !== undefined) && (event.currentTarget.classList.contains("page-numbers"))
                    || (event.currentTarget.classList.contains("next") || event.currentTarget.classList.contains("prev"))
                ) {
                    $("html, body").animate({scrollTop: $(".tags-content__main").offset().top - 100}, 1000);
                }

                this.getTagPosts(pageNum, true);
            },
            postStickySidebar() {
                let sidebar;
                if (window.innerWidth > 1024 && window.innerWidth > 767) {
                    sidebar = new StickySidebar("#sidebar", {
                        containerSelector: "#main-content",
                        innerWrapperSelector: ".sidebar__inner",
                        topSpacing: 0,
                        bottomSpacing: 0
                    });
                    sidebar.updateSticky();
                }
            }
        },
        computed: {
            tagPosts() {
                return this.$store.state.tagPosts;
            }
        },
        name: "TagPosts"
    };
</script>

<style scoped lang="scss">
    .tag-posts {
        position: relative;
        min-height: 10vh;
    }
</style>
