<template>
	<div class="cart-item">
		<div class="product-type">Live Webcast</div>
		<div class="product-title">
			<ul class="title-list">
				<li>
					<a class="title-list-trigger" href="#">
						<img :src="icons.titlePlus"
						     class="plus"
						     alt="Toggle Open" />
						<img :src="icons.titleMinus"
						     class="minus off"
						     alt="Toggle Closed" />
					</a>
					<a class="title-list-parent" href="#">Business Law Basics: Fill in the Gaps 2019</a>
					<ul class="title-list-submenu">
						<li><a href="#">1. Anti-Money Laundering Bank Security Act Compliance 2018</a></li>
						<li>
							<a href="#">2. In hac habitasse platea dictumst. Vivamus adipiscing ferm In hac habitasse platea dictumst. Vivamus adipiscing ferm In hac habitasse platea dictumst. Vivamus adipiscing ferm In hac habitasse platea dictumst. Vivamus adipiscing ferm</a>
						</li>
						<li><a href="#">3. In hac habitasse platea dictumst. Vivamus adipiscing</a></li>
					</ul>
				</li>
			</ul>
		</div>
		<div class="row middle-xs">
			<div class="col-xs-6">
				<div class="product-quantity">
					<fieldset class="change-qty">
						<label id="tablet-cart-quantity-0" for="tablet-cart-quantity">Quantity</label>
						<div class="plus-minus-container">
							<button @click.prevent="(item.qty > 0) ? item.qty-- : null"
							        class="minus">
								<img :src="icons.qtyMinus"
								     alt="minus" />
							</button>
							<input type="number" value="1" id="tablet-cart-quantity" aria-labelledby="tablet-cart-quantity-0">
							<button @click.prevent="item.qty++"
							        class="plus">
								<img :src="icons.qtyMinus"
								     alt="minus" />
							</button>
						</div>
					</fieldset>
				</div>
			</div>
			<div class="col-xs-6">
				<div class="product-price">
					Price: <span data-price="324">$324</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        props: {
            item: Object,
            icons: Object,
            index: String,
        },
        name: "CartTabletItem"
    };
</script>
